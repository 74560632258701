import React, { Fragment, useContext, useEffect, useState } from 'react'
import { IoMdClose, IoMdStar } from "react-icons/io";
import { MdBlock, MdCheck, MdClose, MdOutlineEditCalendar, MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from "react-icons/md";
import { SlArrowLeft, SlArrowRight } from "react-icons/sl";
import NuCalendar from '../../components/nu_calendar';
import { FaTrash, FaHeart, FaRegHeart  } from "react-icons/fa";
import { IoIosSearch } from "react-icons/io";
import { ClinicSideMenu, ProfesionalSideMenu } from '../../components/nu_sidemenu';
import { MdOutlinePersonOff } from "react-icons/md";
import { base_url, Service } from '../../services/api';
import Renderif from '../../utils/renderif';
import { GlobalContext } from '../../context/global_context';
import { useNavigate, useParams } from 'react-router-dom';
import { NotificationContext } from '../../context/notification_context';
import { AuthContext } from '../../context/auth_context';
import NuLoading from '../../components/nu_loading';
import { LuBadgeCheck } from "react-icons/lu";
import { HiBadgeCheck } from "react-icons/hi";
import { RxAvatar } from "react-icons/rx";
import NuSelect from '../../components/nu_select';
import { alphaOrder, restrictionReasons, timeOfDay, UTCDateParser, _timeOfDay } from '../../utils';
import { BiFilter, BiSearch, BiTrash } from 'react-icons/bi';
import { BsPersonSlash, BsThreeDotsVertical } from 'react-icons/bs';
import { darkBlue } from '../../styles/colors';
import { IoMdInformationCircleOutline } from "react-icons/io";
import { IoCloseCircle } from 'react-icons/io5';
import { FaCircleArrowUp, FaCircleChevronDown, FaCircleChevronUp } from "react-icons/fa6";
import { CiMedicalCross } from 'react-icons/ci';
import { LiaClinicMedicalSolid } from "react-icons/lia";
import CheckBox from '../../components/nu_checkbox';

export default function SolicitudesClinicaView() {

    const {idShift} = useParams()
  

    const {user_data, user} = useContext(AuthContext)
    const {getUserType, center, centerType,assistanceUnit, getAssistanceUnit, getProfessionalField, loadCenters, getStatus, getShiftType} = useContext(GlobalContext)


    useContext(GlobalContext);
    const {notify} = useContext(NotificationContext)
    const navigate = useNavigate()
    const [state, setState] = useState({
        filters:'',
        page:1,
        count:7,
        professionals:[],
        professionalsCount:0, 
        bloqueos:[],
        loading:false,
        show_validate:false,
        profesiona_selected:null,
        centers:[],
        // center_selected:'',
        // assistenceunit_selected:'',
        // centertype_selected:'',
        search:'',
        solicitudes:[],
        solicitudes_open:[],
        solicitud_selected:[],
        show_approving_acogida:false,
        show_approving_sin_acogida:false,
        show_reason_deny:false,
        show_covertir_acogida:false,
        reason_deny:'',
        modalToBlock:false,
        reasons:[],
        centers:[],
        turnos:[],
        professional_requests:[],
        tab:0,
        professional_requests_open:[]

    })
    const [shiftCounts, setShiftCounts] = useState({});


    useEffect(()=>{

        init();
        return () => {}
    },[])

    const init = async () => {
      
        setState({...state, loading:true})

      
        let _solicitudes = await Service('GET', `solicitud/${user_data?.id}`)
        let _professional_requests = await Service('GET', `professional_request/${user_data?.id}`)
        let _center = await Service('GET', `center/${user_data?.id}`)
        let _turnos = await Service('GET', `shift/getAll?idOrganization=${user_data?.id}&type=0&publicateTo=true`)

        state.professional_requests = _professional_requests.data.list
        state.turnos = _turnos.data.list;
        state.centers = _center.data.list;
        // state.organization = _organization.data.organizationData;
        // let _centers = await loadCenters()
        // state.centers = _centers.filter((center) => center.idOrganization === _organization.data.organizationData.id)
     
        
        const result = Object.groupBy(_solicitudes.data.list, (item) => item.idAssistanceUnit);
  
       

        let request = []
        for (const [key, value] of Object.entries(result)) {
            const resultUser = Object.groupBy(value, (item) => item.idUser);
        
            for (const [_key, _value] of Object.entries(resultUser)) {
                const resultCenter = Object.groupBy(_value, (item) => item.idCenter);
                
                
                for (const [__key, __value] of Object.entries(resultCenter)) {
                    
                    
                    
                    request.push(__value)
                   
                }
               
            }
        }
        state.solicitudes = request;

        if(idShift !== undefined){
            state.solicitudes.map((solicitud)=>{
                solicitud.map((item)=>{
                    console.log(item)
                    if(item.idShift === parseInt(idShift)){
                        state.solicitudes_open.push(solicitud)
                    }
                })
                
            })
        }
     

        setState({...state, loading:false})



        // let _profesionals = await loadProfessionals({page:state.page, count:state.count, status:state.filters})
        // console.log(_profesionals)
        // state.professionals = _profesionals.data.list
        // state.professionalsCount = _profesionals.data.total
        // setState({...state, loading:false})
    }

    const loadProfessionals = async ({page, count, search = ''}) => {
        let query = `user/getMyProfessionals?page=${page}&pageCount=${count}`
        if(search !== ''){
            query = query+'&term='+search
        }
        let _profesionals = await Service('GET', query)

        
        let _bloqueos = await Service('GET', 'restriction/getAll');

        let my_centers = center.filter((item)=>item.idOrganization === user_data?.id);

        state.centers = my_centers

        let bloqueos = []

        for (let i = 0; i < my_centers.length; i++) {
   
            _bloqueos.data.list.map((bloqueo)=>{
                if(bloqueo.idCenter === my_centers[i].id){
                    bloqueos.push(bloqueo)
                }
            })
        }
        

     
        state.bloqueos = bloqueos;


        // console.log(_bloqueos);
        // console.log(_profesionals);
        return _profesionals;

    }

    const onBlockProfesional = async () => {
   
  
        
        if(!state.reasons.length) {
          notify({title:'¡Error!', message:'Por favor selecciona al menos un motivo.', type:'error'})
          return
        }
        setState({...state, loading:true})
        const reasonsBlock = state.reasons.sort().map((item) => restrictionReasons[item])

        for (let i = 0; i < state.centers.length; i++) {
                
            let body = {
                type:'0',
                idCenter:state.centers[i].id,
                idUser:state.solicitud_selected[0].idUser,
                reason: JSON.stringify(reasonsBlock),
            }
            
            let _block = await Service('POST', 'restriction/create', body);
            
      
            if(_block.status === 201){
              notify({title:'¡Listo!', message:`Profesional bloqueado exitosamente en el centro ${center.name}.`, type:'success'})
             
            }else{
              if(_block.response.status === 400){
                notify({title:'Profesional bloqueado!', message:`Ya has bloqueado este profesional en el centro ${center.name}.`, type:'error'})  
              }else{
                notify({title:'Error!', message:`Ocurrio un error al itentar bloquear profesional en el centro ${center.name}.`, type:'error'})
              }
            }
        }

   

        state.reasons = []
        state.modalToBlock = false;

        // let _solicitudes_user = state.solicitudes.filter((solicitud)=>solicitud.idUser === state.solicitud_selected[0].idUser)
        
        for (let i = 0; i < state.solicitudes.length; i++) {
        
            if(state.solicitudes[i][0].idUser === state.solicitud_selected[0].idUser){

                let _body = {
                    idCenter:state.solicitudes[i][0]?.idCenter,
                    idUser:state.solicitudes[i][0]?.idUser,
                    idAssistanceUnit:state.solicitudes[i][0]?.idAssistanceUnit,
                    reason: 'Bloqueo'
                }
             
                let _deny = await Service('POST', 'solicitud/deny', _body)
        

            }
         
        }
       init();
      
    }

    const onUnblockProfesional = async (idUser) => {
        let _bloqueo = state.bloqueos.filter((bloqueo) => bloqueo.idUser === idUser)[0]
        let _unblock = await Service('DELETE', 'restriction/delete/'+_bloqueo.id);
        if(_unblock.status === 200){
            notify({title:'¡Listo!', message:'Profesional desbloqueado', type:'success'})
            init();
        }else{
            notify({title:'Erorr!', message:'No se ha podiado desbloquear este profesional.', type:'error'})
        }
    }

    const onFavoriteProfessional = async (idUser) => {
        setState({...state, loading:true})
        let _favorite = await Service ('POST', `favorite/create/${idUser}`)
        
        if(_favorite.status === 200){
            notify({title:'¡Listo!', message:'Profesional marcado como favorito', type:'success'})
        
            init();

            
        }else{
            notify({title:'Erorr!', message:'No se ha podiado marcar como favorito a este profesional.', type:'error'})
            setState({...state, loading:false})
        }
    }

    
    const onUnfavoriteProfessional = async (idUser) => {
        setState({...state, loading:true})
        let _unfavorite = await Service ('DELETE', `favorite/delete/${idUser}`)
        if(_unfavorite.status === 200){
            notify({title:'¡Listo!', message:'Profesional marcado como no favorito', type:'success'})

          
            init();


        }else{
            notify({title:'Erorr!', message:'No se ha podiado marcar como no favorito a este profesional.', type:'error'})
            setState({...state, loading:false})
        }
    }

   

    const renderPagination = () => {

        let pages = Math.ceil(state.professionalsCount / state.count);
        let items = []
        for (let i = 1; i <= pages; i++) {
            items.push(i);
        }

        return (
            <div>
               <span className='bold'>{state.page}</span> de <span className='bold'>{items.length}</span>
            </div>
        )


        // let pages = Math.ceil(state.professionalsCount / state.count);
        // let items = []
        // for (let i = 1; i <= pages; i++) {
        //     items.push(i);
        // }

        // // items.reverse()

        // return items.map((item, i) => {
           
        //     return (
        //         <div key={i} style={{width:32}} className={`f-16 bold text-dark px-2 cursor-pointer mb-1 r-10 text-center ${state.page === item ? 'bg-light':'' }`} onClick={async ()=>{
                    
        //             setState({...state, loading:true})
        //             let _profesionals = await loadProfessionals({page:item, count:state.count, status:state.filters})

          
        
                    
        //             setState({...state, 
        //                 page:item,
        //                 loading:false, 
        //                 professionals:_profesionals.data.list, 
        //                 professionalsCount:_profesionals.data.total,
                
        //             })
        //         }}>{item}</div>
        //     )
        // })
        
        


     

    }

    const userBlocked = (user) => {

        let blocked = false;
        state.bloqueos.map((bloqueo)=>{
        
            if(bloqueo.idUser === user.id){
           
                blocked = true;
       
            }
        })
        
        return blocked;
    }
    const loadPage = async (page) => {
        setState({...state, loading:true})
        let _profesionals = await loadProfessionals({page:state.page + page, count:state.count,  search:state.search})
        state.page = state.page + page
        setState({...state, 
            // page:state.page + page,
            loading:false, 
            professionals:_profesionals.data.list, 
            professionalsCount:_profesionals.data.total,
    
        })
        //  save_state()
    }

    const onSearch = async () => {
       
        setState({...state, loading:true})
        let _profesionals = await loadProfessionals({page:1, count:state.count, search:state.search })
     
        state.professionals = _profesionals.data.list
        state.professionalsCount = _profesionals.data.total
        state.page = 1
        setState({...state, loading:false})
        // save_state()
        
    }

    const onApproveWithOutReception = async (values) => {
        // setState({...state, loading:true})

        let body = {}
        body = {
            idCenter:parseInt(state.solicitud_selected[0]?.idCenter),
            idUser:parseInt(state.solicitud_selected[0]?.idUser),

        }
        if(state.solicitud_selected[0]?.idAssistanceUnit !== null){
            body.idAssistanceUnit = parseInt(state.solicitud_selected[0]?.idAssistanceUnit)
        }
        let _completeReception = await Service('POST', 'completedReception/create',body)

        let _experience = {
            idProfessional: parseInt(state.solicitud_selected[0]?.idUser),
            idCenter:parseInt(state.solicitud_selected[0]?.idCenter),
            idAssistanceUnit:parseInt(state.solicitud_selected[0]?.idAssistanceUnit)
        }

        if(state.solicitud_selected[0]?.shift?.idProfessionalField !== null){
            _experience.idProfessionalField = state.solicitud_selected[0]?.shift?.idProfessionalField
        }else{
            _experience.idProfessionalField = 1
        }

        if(state.solicitud_selected[0]?.idAssistanceUnit !== null){
      
            let _createExperience = await Service('POST', 'experience',_experience)
        }


        let _approve = await Service('POST', 'solicitud/approve', body)
        
        if(_completeReception.status === 200 &&  _approve.status === 200){
            notify({title:'¡Listo!', message:'La solicitud ha sido aprobada.', type:'success'})
            state.show_approving_sin_acogida = false;
    
        }
        init();
  
    }

    const onDenyRequest = async (acogida = null) => {

        setState({...state, loading:true})

        let body = {}
        if(acogida === null){
            console.log("A")
            body = {
                idCenter:parseInt(state.solicitud_selected[0]?.idCenter),
                idUser:parseInt(state.solicitud_selected[0]?.idUser),
                idAssistanceUnit:parseInt(state.solicitud_selected[0]?.idAssistanceUnit),
                reason: state.reason_deny
            }
       
            let _turno_acogida = state.turnos.filter((turno) => JSON.parse(turno.publicateTo)[0] === state.solicitud_selected[0]?.idUser && turno.idAssistanceUnit === state.solicitud_selected[0]?.idAssistanceUnit );
            
            for (let i = 0; i < _turno_acogida.length; i++) {
                let _delete_acogida = await Service('DELETE', `shift/delete/${_turno_acogida[i].id}`)
            }

            let _deny = await Service('POST', 'solicitud/deny', body)
            if(_deny.status === 200){
                notify({title:'¡Listo!', message:'La solicitud ha sido denegada.', type:'success'})
            }
        }else{
            console.log("B")
            body = {
                id:state.solicitud_selected[0].id,
                status:3,
                reason_deny: state.reason_deny
            }
            if(state.solicitud_selected[0].idShift !== null){
                let _delete_acogida = await Service('DELETE', `shift/delete/${state.solicitud_selected[0].idShift}`)
            }
            let _deny = await Service('POST', 'professional_request/deny', body)
            if(_deny.status === 200){
                notify({title:'¡Listo!', message:'La solicitud ha sido denegada.', type:'success'})
            }
        }
        state.solicitud_selected = []
        state.reason_deny = ""
        state.show_reason_deny = false;
        state.modalToBlock = false;
        init();

    }

    const checkPublished = (_turno) => {
     
        let pubilshed = false;
        state.turnos.map((turno)=>{
            let publicateTo = JSON.parse(turno.publicateTo)

            if(publicateTo.includes(_turno[0].idUser) && turno.idAssistanceUnit === _turno[0].idAssistanceUnit && turno.idCenter === _turno[0].idCenter){
                pubilshed = true;
            }

        })

        return pubilshed;
    }

    const getClassType = (status) => {

        // if(status === '4' || status === '5'){
        //     return 'gray'
        // }

        if(status === "2"){
            return 'danger'
        }
        
        if(status === '1'){
            return 'darkgreen'
        }
        if(status === '0'){
            return 'orange'
        }

        if(status === '3'){
            return 'lightblue'
        }

      
    }

    useEffect(() => {
        const fetchShiftCountsForItem = async (item) => {
            try {
                let _count = await Service('GET', `shift/getAll/?idUser=${item.id}&idOrganization=${user_data.id}`)
            
                setShiftCounts(prevCounts => ({
                    ...prevCounts,
                    [item.id]: _count?.data?.list?.length || 0
                }))
            } catch (error) {
                console.error("Error fetching shift count for item: ", error)
                setShiftCounts(prevCounts => ({
                    ...prevCounts,
                    [item.id]: 0
                }))
            }
        }

        state.professionals?.forEach(item => {
            fetchShiftCountsForItem(item)
        })
    }, [state.professionals])
   


  return (
    <>
    <Renderif isTrue={state.loading}>
        <NuLoading></NuLoading>
    </Renderif>
    <div className='container-fluid overflow-hidden d-flex h-100'>


        <ClinicSideMenu page='solicitudes' />
   


        <div className='container-fluid overflow-auto hide-scroll col  mx-auto'>

            <div className='bg-white sticky-top pt-3 px-3'>
     

            <div className='nu-snack'>
                <div>
                        <div className='f-22 text-white bold'>Hola, {user?.name}</div>
                        <div className='d-flex align-items-center'>
                            <div className='nu-icon nu-icon--mid'>
                                <IoMdStar color='#FFB054' size={24}></IoMdStar>
                            </div>

                            {/* <div className='f-16 text-white'>5.0 (0 reseñas)</div> */}
                            <div className='f-16 text-white'>5.0 (0 reseñas)</div>

                        </div>
                    
                </div>
                <div className='col'></div>
                    <div className='avatar avatar--mid' style={{backgroundImage: `url(${user?.picture})`}}></div>
                    <div className='nu-snack-overlay'></div>
                </div>
                       
            </div>

            <div className='container-fluid container-xl col mx-auto p-3'>


                <div className='f-24  bold mt-2'>Solicitudes</div>

                <div className='d-flex mt-3'>

                                {/* <div className={`nu-tab nu-tab--${state.tab === 0 ? 'active':''} me-2`} onClick={()=>{setState({...state, tab:-1})}}>Turnos</div> */}

                                <div className={`nu-tab nu-tab--${state.tab === 0 ? 'active':''} me-2`} onClick={()=>{setState({...state, tab:0})}}>Turnos</div>
                                <div className={`nu-tab nu-tab--${state.tab === 1 ? 'active':''} me-2`} onClick={()=>{setState({...state, tab:1})}}>Acogidas</div>
                </div>


                

                <Renderif isTrue={state.tab === 0}>
               
                    <div className="d-flex mt-2 w-100 ">
                        <div className="bg-gray w-100 r-10 mt-2 overflow-auto hide-scroll">
                        
                        <table className='w-100'>
                            <thead className="p-3">
                            <th className="p-2 f-14 ">Profesional</th>
                            <th className="p-2 f-14 ">Turnos solicitados</th>
                            <th className="p-2 f-14 ">Centro</th>
                            <th className="p-2 f-14 ">Unidad asistencial</th>
                    
                            <th className="p-2 f-14 "></th>

                                                    
                            
                            </thead>
                        
                            <tbody className="bg-light">

                                {
                                    state.solicitudes.map((item, i)=>{

                                

                                        // let _turno = state.turnos.filter((turno) => JSON.parse(turno.publicateTo)[0] === item[0]?.shift?.idUser && turno.idAssistanceUnit === item[0]?.shift?.idAssistanceUnit  )[0]
                                        let _turno_acogida = state.turnos.filter((turno) => JSON.parse(turno.publicateTo)[0] === item[0].user.id && turno.idAssistanceUnit === item[0].idAssistanceUnit );
                                        let expired = _turno_acogida.some((turno) => turno.status === "2")

                                        return (
                                            <Fragment>
                                                
                                                <tr key={i} className="b-b b-t">
                                                    <td className='p-2 '>
                                                        <div className='d-flex  align-items-start'>
                                                            <div className='nu-icon nu-icon--mid'>
                                                                <RxAvatar color='#0D78BF' size={30}></RxAvatar>
                                                            </div>
                                                            

                                                            <div className='ps-2'>
                                                                <Renderif isTrue={item[0]?.user?.name === undefined}>
                                                                    <div className='f-14 bold'>Sin nombre</div>
                                                                </Renderif>
                                                                <div className='f-14 bold cursor-pointer text-darkblue' onClick={()=>{
                                                                    navigate('/clinic/profesionales/'+item[0]?.idUser)
                                                                }}>{item[0]?.user?.name}</div>
                                                                <div className='f-12'>{getUserType(item[0]?.user?.idUserType.toString())}</div>
                                                                
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className='p-2'  >
                                                        <div className='d-flex align-items-center'>
                                                            <div className='nu-icon nu-icon--mid'>
                                                            <div className='f-16 bold'>{item?.length}</div>
                                                            </div>
                                                            <div className='nu-icon nu-icon--mid cursor-pointer ms-2' onClick={()=>{
                                                                if(state.solicitudes_open.includes(item)){
                                                                    state.solicitudes_open = state.solicitudes_open.filter((el) => el !== item)
                                                                }else{
                                                                    state.solicitudes_open.push(item)
                                                                }
                                                                setState({...state})
                                                            }}>
                                                                <Renderif isTrue={!state.solicitudes_open.includes(item) }>
                                                                    <FaCircleChevronDown size={25} color={'#CCC'}></FaCircleChevronDown>
                                                                </Renderif>
                                                                <Renderif isTrue={state.solicitudes_open.includes(item) }>
                                                                    <FaCircleChevronUp size={25} color={'#16B2DE'}></FaCircleChevronUp>
                                                                </Renderif>
                                                            
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className='p-2' >
                                                        <div className='f-14 bold'>{item[0]?.center?.name}</div>
                                                    </td>
                                                    <td className='p-2' >
                                                        <div className='f-14 bold'>{item[0]?.assistanceUnit?.description}</div>
                                                    </td>
                                                    <td className='p-2'>
                                                        <Renderif isTrue={checkPublished(item) && !expired}>
                                                            <div className='text-orange f-12'>Pendiente de asignar Acogida<br></br> por parte del profesional.</div>
                                                        </Renderif>
                                                        <Renderif isTrue={expired}>
                                                            <div className='text-danger f-12'>Uno o más turnos se ecuentran expirados.</div>
                                                        </Renderif>
                                                    </td>
                                                    <td className='p-2'>
                                                        <div className='d-flex '>

                                                            <div className='col'></div>

                                                            {/* <div className={`nu_btn nu_btn--primary me-2`} onClick={()=>{

                                                                setState({...state, solicitud_selected:item, approving:true})
                                                            }}>

                                                                <div className='nu-icon nu-icon--mid me-1'>
                                                                    <HiBadgeCheck size={18}></HiBadgeCheck>
                                                                </div>

                                                                <div className='nu_btn-text '>Aprobar solicitud</div>
                                                            
                                                            </div> */}

                                                            <div>
                                                            <NuDropDown 
                                                            label={checkPublished(item) ? 'Acogida publicada' : 'Aprobar solicitud'}
                                                            disabled={checkPublished(item) || expired }
                                                            onSelect={(value)=>{
                                                                
                                                                switch (value) {
                                                                    case 0:
                                                                        setState({...state, solicitud_selected:item, show_approving_acogida:true})
                                                                        break;
                                                                
                                                                    case 1:
                                                                        setState({...state, solicitud_selected:item, show_approving_sin_acogida:true})
                                                                        break;
                                                                    
                                                                    default:
                                                                        break;
                                                                }
                                                            }}></NuDropDown>
                                                            </div>

                                                            <div className={`nu_btn nu_btn--danger`} onClick={()=>{
                                                                console.log(state.tab)
                                                                if(state.tab === 0){
                                                                    setState({...state, solicitud_selected:item, show_reason_deny:true}) 
                                                                }else{
                                                                    setState({...state, solicitud_selected:[item], show_reason_deny:true})
                                                                }
                                                                // setState({...state, solicitud_selected:[item], show_reason_deny:true})
                                                            }}>

                                                                <div className='nu-icon nu-icon--mid me-1'>
                                                                
                                                                    <MdBlock size={18}></MdBlock>
                                                                </div>

                                                                <div className='nu_btn-text '>Denegar</div>
                                                            </div>

                                                            {/* <div className={`nu_btn nu_btn--${checkPublished(item) ? 'disabled':'danger'}`} onClick={()=>{
                                                                setState({...state, modalToBlock:true, solicitud_selected:item})
                                                            }}>

                                                            <div className='nu-icon nu-icon--mid me-1'>
                                                                <BsPersonSlash size={18}></BsPersonSlash>
                                                            </div>
                                                                <div className='nu_btn-text '>Bloquear profesional</div>
                                                            </div> */}

                                                        </div>
                                                    

                                                    </td>
                                                    
                                                </tr>
                                                
                                                {/* <Renderif isTrue={2==2}> */}
                                                <Renderif isTrue={state.solicitudes_open.includes(item)}>
                                                    {
                                                        _turno_acogida.map((_turno, i)=>{

                                                        
                                                        
                                                            return (
                                                                
                                                                    <tr className='bg-white' >
                                                                        <td colSpan={1}></td>
                                                                        <td colSpan={5} className=''>
                                                                            <a href={`${base_url}clinic?shift=${_turno.id}`}>
                                                                            <div className={`cursor-pointer bg-skyblue bg-skyblue--overlay  shadow-sm py-2 px-1 my-1 me-2 r-8 d-flex flex-row align-items-center nu-turno-item--${getClassType(_turno.status)}`} onClick={()=>{
                                                                                // window.open(`${base_url}clinic?shift=${_turno.id}`,'_blank')
                                                                            }}>
                                                                                
                                                                                <div className='d-flex align-items-center col '>
                                                                                        <div className='nu-icon nu-icon--mid'>
                                                                                            <img width="63%" src='/assets/calendar_solid.svg'></img>
                                                                                        </div>
                                                                                    
                                            
                                                                                        <div className='f-12 bold ellipsis'>{UTCDateParser(new Date(_turno?.date).toUTCString(), true)} {_turno.status} </div>
                                                                                        
                                                                                    
                                                                                </div>

                                                                                <div className='d-flex align-items-center col'>
                                                                                    <div className='nu-icon nu-icon--mid'>
                                                                                        <img width="70%" src='/assets/clock_solid.svg'></img>
                                                                                    </div>
                                                                                    <div className='f-12 bold ellipsis'>{_turno?.initialHour} - {_turno?.finalHour}</div>
                                                                                </div>

                                                                                <div className='d-flex align-items-center col-2'>
                                                                                    <div className='nu-icon nu-icon--mid'>
                                                                                        <MdOutlineEditCalendar color='#0D78BF'></MdOutlineEditCalendar>
                                                                                    </div>
                                                                                    <div className='f-12 bold ellipsis'>{getShiftType(_turno?.type)}</div>
                                                                                </div>

                                        
                                                                                <div className='d-flex flex-column col'>
                                                                                    <div className='d-flex align-items-center'>
                                                                                            <div className='nu-icon nu-icon--mid'>
                                                                                                <CiMedicalCross color='#0D78BF'></CiMedicalCross>
                                                                                            </div>
                                                                                            <div className='f-12 bold text-gray ellipsis'>Unidad asistencial</div>
                                                                                    </div>

                                                                                    <div className='d-flex align-items-center ps-4'>
                                                                                            <div className='f-12 bold ellipsis'>{getAssistanceUnit(_turno?.idAssistanceUnit)}</div>
                                                                                    </div>
                                                                                </div>

                                                                                <div className='d-flex flex-column col'>
                                                                                    <div className='d-flex align-items-center'>
                                                                                            <div className='nu-icon nu-icon--mid'>
                                                                                                <CiMedicalCross color='#0D78BF'></CiMedicalCross>
                                                                                            </div>
                                                                                            <div className='f-12 bold text-gray ellipsis'>Ámbito profesional</div>
                                                                                    </div>

                                                                                    <div className='d-flex align-items-center ps-4'>
                                                                                            <div className='f-12 bold ellipsis'>{getProfessionalField(_turno?.idProfessionalField)}</div>
                                                                                    </div>
                                                                                </div>

                                                                            
                                                                                
                                                                                {/* <div className={`nu-tag nu-tag--${_turno?.status === '0' ? 'orange' : ''}`}>{getStatus(_turno?.status)}</div> */}
                                                                                

                                                                            </div>
                                                                            </a>

                                                                        </td>
                                                                    </tr>
                                                                
                                                            )
                                                        })
                                                    }
                                                    {item?.map((item, i)=>{


                                                        if(item.shift.status === '0') return;

                                                        return (
                                                            <tr className='bg-white' key={i}>
                                                                <td colSpan={1}></td>
                                                                <td colSpan={5} className=''>
                                                                <a href={`${base_url}clinic?shift=${item?.shift.id}`}>
                                                                    <div className={`cursor-pointer bg-skyblue bg-skyblue--overlay shadow-sm py-2 px-1 my-1 me-2 r-8 d-flex flex-row align-items-center nu-turno-item--${getClassType(item?.shift.status)}`} onClick={()=>{
                                                                                // window.open(`${base_url}clinic?shift=${item?.shift.id}`,'_blank')
                                                                            }}>

                                                                        <div className='d-flex align-items-center col'>
                                                                                <div className='nu-icon nu-icon--mid'>
                                                                                    <img width="63%" src='/assets/calendar_solid.svg'></img>
                                                                                </div>
                                                                            
                                                                                
                                                                                <div className='f-12 bold ellipsis'>{UTCDateParser(new Date(item?.shift?.date).toUTCString(), true)}  {item?.shift?.status}</div>
                                                                                
                                                                            
                                                                        </div>

                                                                        <div className='d-flex align-items-center col'>
                                                                            <div className='nu-icon nu-icon--mid'>
                                                                                <img width="70%" src='/assets/clock_solid.svg'></img>
                                                                            </div>
                                                                            <div className='f-12 bold ellipsis'>{item?.shift?.initialHour} - {item?.shift?.finalHour}</div>
                                                                        </div>

                                                                        <div className='d-flex align-items-center col-2'>
                                                                            <div className='nu-icon nu-icon--mid'>
                                                                                <MdOutlineEditCalendar color='#0D78BF'></MdOutlineEditCalendar>
                                                                            </div>
                                                                            <div className='f-12 bold ellipsis'>{getShiftType(item?.shift?.type)}</div>
                                                                        </div>

                                                                        

                                                                        <div className='d-flex flex-column col'>
                                                                            
                                                                            <div className='d-flex align-items-center'>
                                                                                <div className='nu-icon nu-icon--mid'>
                                                                                    <CiMedicalCross color='#0D78BF'></CiMedicalCross>
                                                                                </div>
                                                                                <div className='f-12 bold text-gray ellipsis'>Unidad asistencial</div>
                                                                            </div>

                                                                            <div className='d-flex align-items-center ps-4'>
                                                                                <div className='f-12 bold ellipsis'>{getAssistanceUnit(item?.shift?.idAssistanceUnit)}</div>
                                                                            </div>
                                                                            
                                                                        </div>
                                                                            <div className='d-flex flex-column col'>

                                                                        
                                                                            <div className='d-flex align-items-center'>
                                                                                <div className='nu-icon nu-icon--mid'>
                                                                                    <CiMedicalCross color='#0D78BF'></CiMedicalCross>
                                                                                </div>
                                                                                <div className='f-12 bold text-gray ellipsis'>Ámbito profesional</div>
                                                                            </div>

                                                                            <div className='d-flex align-items-center ps-4'>
                                                                                <div className='f-12 bold ellipsis'>{getProfessionalField(item?.shift?.idProfessionalField)}</div>
                                                                            </div>

                                                                            

                                                                        </div>

                                                                    
                                                                        

                                                                    </div>
                                                                    </a>
                                                                </td>
                                                            </tr>

                                                        )
                                                    })}
                                                    
                                                </Renderif>

                                            </Fragment>
                                        )
                                    })
                                }
                                <tr className='my-5 bg-white'>
                                    <td colSpan={6}>
                                        <div className='my-5'></div>
                                    </td>
                                </tr>
                        
                            </tbody>
                        </table>
                        </div>
                    </div>
                
                    
              
                </Renderif>


                <Renderif isTrue={state.tab === 1}>
               
                    <div className="d-flex mt-2 w-100 ">
                        <div className="bg-gray w-100 r-10 mt-2 overflow-auto hide-scroll">
                        
                        <table className='w-100'>
                            <thead className="p-3">
                                <tr>

                                    <td className="p-2 f-14 bold ">Profesional</td>
                                    <td className="p-2 f-14 bold">Solicitud</td>
                                    <td className="p-2 f-14 bold">Centro</td>
                                    <td className="p-2 f-14 bold">Unidad asistencial</td>
                                    <td className="p-2 f-14 bold">Ámbito profesional</td>
                                 
                            
                                    <td className="p-2 f-14 "></td>

                                </tr>

                      

                                                    
                            
                            </thead>
                        
                            <tbody className="bg-light">

                                {
                                    state.professional_requests?.map((item, i)=>{
                                        console.log(item)
                                        // if(item?.shift?.idUser !== null) return;

                                        if(item.shift !== null){
                                            if(item.shift.idUser !== null){
                                                return;
                                            }
                                        }

                                        return (
                                            <Fragment>
                                                
                                                <tr key={i} className="b-b b-t">
                                                    <td className='p-2 '>
                                                        <div className='d-flex  align-items-start'>
                                                            <div className='nu-icon nu-icon--mid'>
                                                                <RxAvatar color='#0D78BF' size={30}></RxAvatar>
                                                            </div>
                                                            

                                                            <div className='ps-2'>
                                                                <Renderif isTrue={item?.user?.name === undefined}>
                                                                    <div className='f-14 bold'>Sin nombre</div>
                                                                </Renderif>
                                                                <div className='f-14 bold cursor-pointer text-darkblue' onClick={()=>{
                                                                    navigate('/clinic/profesionales/'+item?.user.id)
                                                                }}>{item?.user?.name}</div>
                                                                <div className='f-12'>{getUserType(item?.user?.idUserType.toString())}</div>
                                                                
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className='p-2'  >
                                                        <div className='d-flex align-items-center'>
                                                            <div className='f-12 bold'>Acogida</div>

                                                          

                                                            <div className='nu-icon nu-icon--mid cursor-pointer ms-2' onClick={()=>{
                                                                if(state.professional_requests_open.includes(item)){
                                                                    state.professional_requests_open = state.professional_requests_open.filter((el) => el !== item)
                                                                }else{
                                                                    state.professional_requests_open.push(item)
                                                                }
                                                                setState({...state})
                                                            }}>
                                                                <Renderif isTrue={!state.professional_requests_open.includes(item) }>
                                                                    <FaCircleChevronDown size={25} color={'#CCC'}></FaCircleChevronDown>
                                                                </Renderif>
                                                                <Renderif isTrue={state.professional_requests_open.includes(item) }>
                                                                    <FaCircleChevronUp size={25} color={'#16B2DE'}></FaCircleChevronUp>
                                                                </Renderif>
                                                            
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className='p-2' >
                                                        <div className='f-12 bold'>{item?.center?.name}</div>
                                                    </td>
                                                    <td className='p-2' >
                                                        <div className='f-12 bold'>{item?.assistanceUnit?.description}</div>
                                                    </td>
                                                    <td className='p-2'>
                                                        <div className='f-12 bold'>{item?.professionalField?.description}</div>
                                                    </td>
                                                    
                                                   
                                                    <td className='p-2'>
                                                        <div className='d-flex '>

                                                            <div className='col'></div>

                                                            <div className={`nu_btn nu_btn--${item.status === 1 ?'primary': 'disabled'} me-2`} onClick={()=>{
                                                               
                                                             
                                                                navigate('/clinic/publicar/', { state: {
                                                                    profesional:{
                                                                        id:parseInt(item.idUser), ...item.idUser}, 
                                                                        turno:{
                                                                            idCenter:item.idCenter,
                                                                            idProfessionalField:item.idProfessionalField,
                                                                            profession: item.user.idUserType === 1 ? '0' : '1',
                                                                            idCenterType: item.center.idCenterType,
                                                                            idAssistanceUnit:item.idAssistanceUnit,


                                                                            // status: "0",
                                                                            onlyFavorites:false,
                                                                            // idUser: null,                                 
                                                                            idOrganization: user_data.id,
                                                                         
                                                                        
                                                                         
                                                                            
                                                                            // type: _turno?.type,
                                                                            
                                                                            date: item.preferred_date !== null ? Date.parse(item.preferred_date) : Date.parse(new Date()), 
                                                                            weekendShift: true,
                                                                            timeOfDay:  _timeOfDay(item.preferred_shift_time),
                                                                            
                                                                            // initialHour: _turno?.initialHour,
                                                                            // finalHour: _turno?.finalHour,
                                                                            assistanceUnitEducation:'0',
                                                                            professionalFieldEducation: '0',
                                                                     
                                                                        
                                                                            idSpecialization: 1,
                                                                            needsReception: '0',
                                                                            appraisal: '0',
                                                                            experience:'0',
                                                                    
                                                                            professionalFieldExperience: '0',
                                                                            assistanceUnitExperience: '0',
                                                                            education: '0',
                                                                            description: "",
                                                                            // publicateTo: JSON.stringify([_turno.idUser])
                                                                        },
                                                                        origin:'nueva_acogida',
                                                                        idRequest:item.id,
                                                                        user_preference: {
                                                                            preferred_date:item.preferred_date,
                                                                            preferred_shift_time :item.preferred_shift_time,
                                                                            comments:item.comments

                                                                        }
                                                                }})

                                                            }}>

                                                                <div className='nu-icon nu-icon--mid me-1'>
                                                                    <HiBadgeCheck size={18}></HiBadgeCheck>
                                                                </div>

                                                                <div className='nu_btn-text '>Publicar acogida</div>
                                                            
                                                            </div>
{/* 
                                                            <div>
                                                            <NuDropDown 
                                                            label={'Aprobar solicitud'}
                                                            disabled={false }
                                                            onSelect={(value)=>{
                                                                
                                                                switch (value) {
                                                                    case 0:
                                                                        setState({...state, solicitud_selected:item, show_approving_acogida:true})
                                                                        break;
                                                                
                                                                    case 1:
                                                                        setState({...state, solicitud_selected:item, show_approving_sin_acogida:true})
                                                                        break;
                                                                    
                                                                    default:
                                                                        break;
                                                                }
                                                            }}></NuDropDown>
                                                            </div> */}

                                                            <div className={`nu_btn nu_btn--danger`} onClick={()=>{

                                                                setState({...state, solicitud_selected:[item], show_reason_deny:true})
                                                            }}>

                                                                <div className='nu-icon nu-icon--mid me-1'>
                                                                
                                                                    <MdBlock size={18}></MdBlock>
                                                                </div>

                                                                <div className='nu_btn-text '>Denegar</div>
                                                            </div>

                                                            {/* <div className={`nu_btn nu_btn--${checkPublished(item) ? 'disabled':'danger'}`} onClick={()=>{
                                                                setState({...state, modalToBlock:true, solicitud_selected:item})
                                                            }}>

                                                            <div className='nu-icon nu-icon--mid me-1'>
                                                                <BsPersonSlash size={18}></BsPersonSlash>
                                                            </div>
                                                                <div className='nu_btn-text '>Bloquear profesional</div>
                                                            </div> */}

                                                        </div>
                                                    

                                                    </td>
                                                    
                                                </tr>
                                                {/* <Renderif isTrue={2===2}> */}
                                                <Renderif isTrue={state.professional_requests_open.includes(item)}>
                                                
                                                <tr className='bg-white'>
                                                    <td colSpan={1}></td>
                                                    <td colSpan={7}>
                                                        <div className='d-flex bg-skyblue p-2 r-10 shadow-sm mt-1'>
                                                            <div className='col'>
                                                                <div className='f-12 bold'>Fecha preferente:</div>
                                                                <div className='f-12'>{item.preferred_date}</div>
                                                            </div>

                                                            <div className='col'>
                                                                <div className='f-12 bold'>Horario de turno preferente:</div>
                                                                <div className='f-12'>{item.preferred_shift_time}</div>
                                                            </div>
                                                            <div className='col'>
                                                                <div className='f-12 bold'>Comentarios:</div>
                                                                <div className='f-12'>{item.comments}</div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <Renderif isTrue={item.shift !== null}>
                                                    <tr className='bg-white' >
                                                        <td colSpan={1}></td>
                                                        <td colSpan={7} className=''>
                                                        <a href={`${base_url}clinic?shift=${item?.shift?.id}`}>
                                                            <div className={`cursor-pointer bg-skyblue bg-skyblue--overlay  shadow-sm py-2 px-1 my-1 r-8 d-flex flex-row align-items-center nu-turno-item--${getClassType(item?.shift?.status)}`} onClick={()=>{
                                                                // window.open(`${base_url}clinic?shift=${item?.shift?.id}`,'_blank')
                                                            }}>
                                                                
                                                                <div className='d-flex align-items-center col '>
                                                                        <div className='nu-icon nu-icon--mid'>
                                                                            <img width="63%" src='/assets/calendar_solid.svg'></img>
                                                                        </div>
                                                                    
                            
                                                                        <div className='f-12 bold ellipsis'>{UTCDateParser(new Date(item?.shift?.date).toUTCString(), true)}</div>
                                                                        
                                                                    
                                                                </div>

                                                                <div className='d-flex align-items-center col'>
                                                                    <div className='nu-icon nu-icon--mid'>
                                                                        <img width="70%" src='/assets/clock_solid.svg'></img>
                                                                    </div>
                                                                    <div className='f-12 bold ellipsis'>{item?.shift?.initialHour} - {item?.shift?.finalHour}</div>
                                                                </div>

                                                                <div className='d-flex align-items-center col-2'>
                                                                    <div className='nu-icon nu-icon--mid'>
                                                                        <MdOutlineEditCalendar color='#0D78BF'></MdOutlineEditCalendar>
                                                                    </div>
                                                                    <div className='f-12 bold ellipsis'>{getShiftType(item?.shift?.type)}</div>
                                                                </div>

                        
                                                                <div className='d-flex flex-column col'>
                                                                    <div className='d-flex align-items-center'>
                                                                            <div className='nu-icon nu-icon--mid'>
                                                                                <CiMedicalCross color='#0D78BF'></CiMedicalCross>
                                                                            </div>
                                                                            <div className='f-12 bold text-gray ellipsis'>Unidad asistencial</div>
                                                                    </div>

                                                                    <div className='d-flex align-items-center ps-4'>
                                                                            <div className='f-12 bold ellipsis'>{getAssistanceUnit(item?.shift?.idAssistanceUnit)}</div>
                                                                    </div>
                                                                </div>

                                                                <div className='d-flex flex-column col'>
                                                                    <div className='d-flex align-items-center'>
                                                                            <div className='nu-icon nu-icon--mid'>
                                                                                <CiMedicalCross color='#0D78BF'></CiMedicalCross>
                                                                            </div>
                                                                            <div className='f-12 bold text-gray ellipsis'>Ámbito profesional</div>
                                                                    </div>

                                                                    <div className='d-flex align-items-center ps-4'>
                                                                            <div className='f-12 bold ellipsis'>{getProfessionalField(item?.shift?.idProfessionalField)}</div>
                                                                    </div>
                                                                </div>

                                                            
                                                                
                                                                {/* <div className={`nu-tag nu-tag--${_turno?.status === '0' ? 'orange' : ''}`}>{getStatus(_turno?.status)}</div> */}
                                                                

                                                            </div>
                                                            </a>
                                                        </td>
                                                    </tr>
                                                </Renderif>
                                                   
                                                    
                                                </Renderif>
                                                
                                              

                                            </Fragment>
                                        )
                                    })
                                }
                                <tr className='my-5 bg-white'>
                                    <td colSpan={8}>
                                        <div className='my-5'></div>
                                    </td>
                                </tr>
                        
                            </tbody>
                        </table>
                        </div>
                    </div>
                
                    
                
                </Renderif>
                <Renderif
              isTrue={state.solicitudes.length === 0 && state.loading === false}
            >
              <div id="" className="w-100 bg-gray-00 r-10 py-5 px-5 d-flex">
                <div className="col px-3">
                  <div className="bold f-14 text-white bg-darkblue p-2 text-center r-10 mt-5 px-5 position-relative">
                    <div className="bubble-arrow"></div>
                    No tienes solicitudes pendientes por aprobar, te
                    informaremos cuando un profesional solicite uno de tus
                    turnos publicados o bien, quiera realizar una Acogida en tu
                    Centro.
                  </div>

                  {/* <div className='d-flex justify-content-center'>
                            <div className='bold f-14 text-gray p-2 text-center r-10 mt-4 px-5'>Seguimos trabajando para que pronto puedas ver más.</div>
                        </div> */}
                </div>

                <div className="col-4 pt-2">
                  <img alt="" width={200} src="/assets/nurse_empty.png"></img>
                </div>
              </div>
            </Renderif>
               

             
              
             
            </div>
           

          

       
      
        </div>

        <SideDetailReception 
            active={state.show_validate} 
            profesional={state.profesiona_selected} 
            centers={state.centers}
            onClose={()=>{
                setState({...state, show_validate:false})
            }} 
            onValidate={(values)=>{
                    // onCompleteReception(values)
        }}></SideDetailReception>


        <Renderif isTrue={state.show_approving_acogida}>

            <div className='nu-modal'>

                <div className='nu-modal-body nu-modal-body--fit hide-scroll '>
                    <div className='d-flex'>
                        <div className='col'></div>
                        <div className='nu-icon nu-icon--big cursor-pointer' onClick={()=>{
                            setState({...state, show_approving_acogida:false})
                        }}>
                            <IoCloseCircle color={darkBlue} size={30}></IoCloseCircle>
                        </div>
                    </div>

                    <div className='col d-flex flex-column  justify-content-center align-items-center'>
                        
                        <img width="70px" src='/assets/tick-circle.png'></img>
                        <div className='f-24 bold text-center'>Publicar turno de Acogida</div>
                        <div className='f-14  text-center mb-2'>Para que podamos asignar el turno de Cobertura solicitado primero publícale <br></br> un turno de Acogida a este profesional.</div>
                        <div className='f-14  text-center mb-2'>Cuando el profesional se lo asigne, automáticamente se asignarán los siguientes turnos:</div>
                        
                        {
                            state.solicitud_selected?.map((item, i)=>{
                                return (
                                    <div className=' bg-skyblue shadow-sm py-2 px-1 my-1  r-8 d-flex flex-wrap  flex-row align-items-center justify-content-center  w-100'>

                                        <div className='d-flex align-items-center  pe-3 col-3 '>
                                            <div className='nu-icon nu-icon--mid'>
                                            <LiaClinicMedicalSolid color='#0D78BF'></LiaClinicMedicalSolid>
                                            </div>
                                            <div className='f-12 bold ellipsis'>{item?.center?.name}</div>
                                        </div>

                                        <div className='d-flex align-items-center pe-3 col-3 '>
                                                <div className='nu-icon nu-icon--mid'>
                                                    <img width="63%" src='/assets/calendar_solid.svg'></img>
                                                </div>
                                            
                                                
                                                <div className='f-12 bold ellipsis'>{UTCDateParser(new Date(item?.shift?.date).toUTCString(), true)}</div>
                                            
                                        </div>

                                        <div className='d-flex align-items-center  pe-3'>
                                            <div className='nu-icon nu-icon--mid'>
                                                <img width="70%" src='/assets/clock_solid.svg'></img>
                                            </div>
                                            <div className='f-12 bold ellipsis'>{item?.shift?.initialHour} - {item?.shift?.finalHour}</div>
                                        </div>

                                        <div className='d-flex align-items-center  pe-3'>
                                            <div className='nu-icon nu-icon--mid'>
                                                <MdOutlineEditCalendar color='#0D78BF'></MdOutlineEditCalendar>
                                            </div>
                                            <div className='f-12 bold ellipsis'>Cobertura</div>
                                        </div>

                                        <div className='d-flex align-items-center  pe-3'>
                                            <div className='nu-icon nu-icon--mid'>
                                                <CiMedicalCross color='#0D78BF'></CiMedicalCross>
                                            </div>
                                            <div className='f-12 bold ellipsis'>{getProfessionalField(item?.shift?.idProfessionalField)}</div>
                                        </div>

                                    </div>
                                )
                            })
                        }
                        
                        <div className='w-100 d-flex flex-row  align-items-center justify-content-center mt-3'>

                            {/* <div className='nu_btn me-2 px-3 px-md-0' onClick={()=>{
                                setState({...state, show_approving_acogida:false})
                            }}>
                                <div className='nu_btn-text f-16'>Cancelar</div>
                            </div> */}


                            <div className='nu_btn nu_btn--overlay px-3 px-md-3' onClick={()=>{
                                navigate('/clinic/publicar/', { state: {
                                    profesional:{id:parseInt(state.solicitud_selected[0].idUser), ...state.solicitud_selected[0].user}, 
                                    turno:state.solicitud_selected[0].shift,
                                    origin:'nueva_acogida'
                                }})
                                // setState({...state, show_approving_acogida:false})
                            }}>
                                <div className='nu_btn-text f-16'>Publicar turno de Acogida</div>
                                <div className='nu_btn-overlay'></div>
                            </div>

                            <div className='mx-2'></div>

                            <div className='nu_btn nu_btn--overlay px-3 px-md-3' onClick={async ()=>{
                                

                                setState({...state, show_covertir_acogida:true, show_approving_acogida:false})
                              
                                // let _turno = state.solicitud_selected[0]?.shift;

                                // let body = {
                                //     status: "0",
                                //     onlyFavorites:false,
                                //     idUser: null,                                 
                                //     idOrganization: user_data.id,
                                //     idCenter: _turno?.idCenter,
                                //     idCenterType: _turno?.idCenterType,
                                //     profession: _turno?.profesion,
                                   
                                //     type: _turno?.type,
                                 
                                //     date: _turno?.date, 
                                //     weekendShift: _turno?.weekendShift,
                                //     timeOfDay:  _turno?.timeOfDay,
                                   
                                //     initialHour: _turno?.initialHour,
                                //     finalHour: _turno?.finalHour,
                                //     assistanceUnitEducation:_turno?.assistanceUnitEducation,
                                //     professionalFieldEducation: _turno?.professionalFieldEducation,
                                //     idAssistanceUnit: _turno?.assistanceUnitSelected,
                                //     idProfessionalField: _turno?.professionalFieldSelected,
                                //     idSpecialization: _turno?.specializationSelected,
                                //     needsReception: '0',
                                //     appraisal: _turno?.valoracion,
                                //     experience:_turno?.experience,
                                //     remuneration: parseFloat(_turno?.remuneration),
                                //     professionalFieldExperience: _turno?.professionalFieldExperience,
                                //     assistanceUnitExperience: _turno?.assistanceUnitExperience,
                                //     education: _turno?.education,
                                //     description: _turno?.shiftDescription,
                                //     publicateTo: JSON.stringify([_turno.idUser])
                                // }
                                // let _createTurno = await Service('POST', 'shift/create', body);
                                // let _unassignTurno = await Service('PUT', `shift/free/${_turno.id}`);

                                // console.log(_unassignTurno)

                                // navigate('/clinic/publicar/', { state: {
                                //     profesional:{id:parseInt(state.solicitud_selected[0].idUser), ...state.solicitud_selected[0].user}, 
                                //     turno:state.solicitud_selected[0].shift,
                                //     origin:'nueva_acogida'
                                // }})
                                // setState({...state, show_approving_acogida:false})
                            }}>
                                <div className='nu_btn-text f-16'>Convertir el primer turno en Acogida</div>
                                <div className='nu_btn-overlay'></div>
                            </div>
                          


                        </div>
                      
                    </div>

                </div>
            </div>

        </Renderif>
        <Renderif isTrue={state.show_covertir_acogida}>

            <div className='nu-modal'>

                <div className='nu-modal-body nu-modal-body--fit hide-scroll '>
                    <div className='d-flex'>
                        <div className='col'></div>
                        <div className='nu-icon nu-icon--big cursor-pointer' onClick={()=>{
                            setState({...state, show_covertir_acogida:false})
                        }}>
                            <IoCloseCircle color={darkBlue} size={30}></IoCloseCircle>
                        </div>
                    </div>

                    <div className='col d-flex flex-column  justify-content-center align-items-center'>
                        
                        <img width="70px" src='/assets/tick-circle.png'></img>
                        <div className='f-24 bold text-center'>Convertirás el siguiente turno en Acogida</div>
                        
                        
                 
                               
                                    <div className=' bg-skyblue shadow-sm py-2 px-1 my-1  r-8 d-flex flex-wrap  flex-row align-items-center justify-content-center  w-100'>

                                        <div className='d-flex align-items-center  pe-3 col-3 '>
                                            <div className='nu-icon nu-icon--mid'>
                                            <LiaClinicMedicalSolid color='#0D78BF'></LiaClinicMedicalSolid>
                                            </div>
                                            <div className='f-12 bold ellipsis'>{ state.solicitud_selected[0]?.center?.name}</div>
                                        </div>

                                        <div className='d-flex align-items-center pe-3 col-3 '>
                                                <div className='nu-icon nu-icon--mid'>
                                                    <img width="63%" src='/assets/calendar_solid.svg'></img>
                                                </div>
                                            
                                                
                                                <div className='f-12 bold ellipsis'>{UTCDateParser(new Date(state.solicitud_selected[0]?.shift?.date).toUTCString(), true)}</div>
                                            
                                        </div>

                                        <div className='d-flex align-items-center  pe-3'>
                                            <div className='nu-icon nu-icon--mid'>
                                                <img width="70%" src='/assets/clock_solid.svg'></img>
                                            </div>
                                            <div className='f-12 bold ellipsis'>{state.solicitud_selected[0]?.shift?.initialHour} - {state.solicitud_selected[0]?.shift?.finalHour}</div>
                                        </div>

                                        <div className='d-flex align-items-center  pe-3'>
                                            <div className='nu-icon nu-icon--mid'>
                                                <MdOutlineEditCalendar color='#0D78BF'></MdOutlineEditCalendar>
                                            </div>
                                            <div className='f-12 bold ellipsis'>Cobertura</div>
                                        </div>

                                        <div className='d-flex align-items-center  pe-3'>
                                            <div className='nu-icon nu-icon--mid'>
                                                <CiMedicalCross color='#0D78BF'></CiMedicalCross>
                                            </div>
                                            <div className='f-12 bold ellipsis'>{getProfessionalField(state.solicitud_selected[0]?.shift?.idProfessionalField)}</div>
                                        </div>

                                    </div>
                      
                        
                        <div className='w-100 d-flex flex-row  align-items-center justify-content-center mt-3'>

                            {/* <div className='nu_btn me-2 px-3 px-md-0' onClick={()=>{
                                setState({...state, show_approving_acogida:false})
                            }}>
                                <div className='nu_btn-text f-16'>Cancelar</div>
                            </div> */}


                            {/* <div className='nu_btn nu_btn--overlay px-3 px-md-3' onClick={()=>{
                                navigate('/clinic/publicar/', { state: {
                                    profesional:{id:parseInt(state.solicitud_selected[0].idUser), ...state.solicitud_selected[0].user}, 
                                    turno:state.solicitud_selected[0].shift,
                                    origin:'nueva_acogida'
                                }})
                                // setState({...state, show_approving_acogida:false})
                            }}>
                                <div className='nu_btn-text f-16'>Publicar turno de Acogida</div>
                                <div className='nu_btn-overlay'></div>
                            </div> */}

                            {/* <div className='mx-2'></div> */}

                            <div className='nu_btn nu_btn--overlay px-3 px-md-3' onClick={async ()=>{
                            
                                setState({...state, loading:true})
                            
                                let _turno = state.solicitud_selected[0]?.shift;
                                
                               

                                let body = {
                                    status: "0",
                                    onlyFavorites:false,
                                    onlyMyTeam:false,
                                    idUser: null,                                 
                                    idOrganization: user_data.id,
                                    idCenter: _turno?.idCenter,
                                    idCenterType: _turno?.idCenterType,
                                    profession: _turno?.profession,
                                
                                    type: "0",
                                
                                    date: _turno?.date, 
                                    weekendShift: _turno?.weekendShift,
                                    timeOfDay:  _turno?.timeOfDay,
                                
                                    initialHour: _turno?.initialHour,
                                    finalHour: _turno?.finalHour,
                                    assistanceUnitEducation:_turno?.assistanceUnitEducation,
                                    professionalFieldEducation: _turno?.professionalFieldEducation,
                                    idAssistanceUnit: _turno?.idAssistanceUnit,
                                    idProfessionalField: _turno?.idProfessionalField,
                                    idSpecialization: _turno?.idSpecialization,
                                    needsReception: '0',
                                    appraisal: _turno?.appraisal,
                                    experience:_turno?.experience,
                                    remuneration: parseFloat(_turno?.remuneration),
                                    professionalFieldExperience: _turno?.professionalFieldExperience,
                                    assistanceUnitExperience: _turno?.assistanceUnitExperience,
                                    education: _turno?.education,
                                    description: _turno?.shiftDescription,
                                    publicateTo: JSON.stringify([_turno.idUser])
                                }
                                let _createTurno = await Service('POST', 'shift/create', body);
                                let _unassignTurno = await Service('PUT', `shift/free/${_turno.id}`);

                         

                             

                                if(_unassignTurno.status === 200){
                                    notify({title:'Convertir Acogida', message:'Acogida publicada exitosamente.', type:'success'}) 
                                }else{
                                    notify({title:'Convertir Acogida', message:'No ha sido posible convertir turno de acogida.', type:'error'}) 
                                }
                                // setState({...state, loading:false, show_covertir_acogida:false})
                                state.loading = false;
                                state.show_covertir_acogida = false;
                                init()

                              
                            }}>
                                <div className='nu_btn-text f-16'>Convertir Turno</div>
                                <div className='nu_btn-overlay'></div>
                            </div>
                        


                        </div>
                    
                    </div>

                </div>
            </div>

        </Renderif>
         <Renderif isTrue={state.show_approving_sin_acogida}>

            <div className='nu-modal'>

                <div className='nu-modal-body nu-modal-body--fit hide-scroll '>
                    <div className='d-flex'>
                        <div className='col'></div>
                        <div className='nu-icon nu-icon--big cursor-pointer' onClick={()=>{
                            setState({...state, show_approving_sin_acogida:false})
                        }}>
                            <IoCloseCircle color={darkBlue} size={30}></IoCloseCircle>
                        </div>
                    </div>

                    <div className='col d-flex flex-column  justify-content-center align-items-center'>
                        
                        <img width="70px" src='/assets/tick-circle.png'></img>
                        <div className='f-24 bold text-center'>Aprobar solicitud sin Acogida previa</div>
                        
                        <div className='f-14  text-center mb-2'><span className='bold'>Completar Acogida en:</span>{state.solicitud_selected[0]?.assistanceUnit?.description}</div>
                        <div className='f-14  text-center mb-2'><span className='bold'>Centro:</span> {state.solicitud_selected[0]?.center?.name}</div>
                        
                        <div className='f-14 text-center'>Entendemos que este profesional no requiere de un turno de Acogida en tu Centro. <br></br>
Se asignarán automáticamente los siguientes turnos:</div>
        
                        
                        {
                            state.solicitud_selected?.map((item, i)=>{
                                return (
                                    <div className=' bg-skyblue shadow-sm py-2 px-1 my-1  r-8 d-flex flex-wrap  flex-row align-items-center justify-content-center  w-100'>

                                        <div className='d-flex align-items-center  pe-3 col-3 '>
                                            <div className='nu-icon nu-icon--mid'>
                                            <LiaClinicMedicalSolid color='#0D78BF'></LiaClinicMedicalSolid>
                                            </div>
                                            <div className='f-12 bold ellipsis'>{item?.center?.name}</div>
                                        </div>

                                        <div className='d-flex align-items-center pe-3 col-3 '>
                                                <div className='nu-icon nu-icon--mid'>
                                                    <img width="63%" src='/assets/calendar_solid.svg'></img>
                                                </div>
                                            
                                                
                                                <div className='f-12 bold ellipsis'>{UTCDateParser(new Date(item?.shift?.date).toUTCString(), true)}</div>
                                            
                                        </div>

                                        <div className='d-flex align-items-center  pe-3'>
                                            <div className='nu-icon nu-icon--mid'>
                                                <img width="70%" src='/assets/clock_solid.svg'></img>
                                            </div>
                                            <div className='f-12 bold ellipsis'>{item?.shift?.initialHour} - {item?.shift?.finalHour}</div>
                                        </div>

                                        <div className='d-flex align-items-center  pe-3'>
                                            <div className='nu-icon nu-icon--mid'>
                                                <MdOutlineEditCalendar color='#0D78BF'></MdOutlineEditCalendar>
                                            </div>
                                            <div className='f-12 bold ellipsis'>Cobertura</div>
                                        </div>

                                        <div className='d-flex align-items-center  pe-3'>
                                            <div className='nu-icon nu-icon--mid'>
                                                <CiMedicalCross color='#0D78BF'></CiMedicalCross>
                                            </div>
                                            <div className='f-12 bold ellipsis'>{getProfessionalField(item?.shift?.idProfessionalField)}</div>
                                        </div>

                                    </div>
                                )
                            })
                        }
                        
                        <div className='w-100 d-flex flex-row  align-items-center justify-content-center mt-3'>

                            <div className='nu_btn me-2 px-3 px-md-0' onClick={()=>{
                                setState({...state, show_approving_sin_acogida:false})
                            }}>
                                <div className='nu_btn-text f-16'>Cancelar</div>
                            </div>


                            <div className='nu_btn nu_btn--primary px-3 px-md-3' onClick={()=>{
                                onApproveWithOutReception()

                                
                            }}>
                                <div className='nu_btn-text f-16'>Si, asignar turnos</div>
                            </div>
                          


                        </div>
                      
                    </div>

                </div>
            </div>

        </Renderif>
        <Renderif isTrue={state.show_reason_deny}>

            <div className='nu-modal'>

                <div className='nu-modal-body'>
                    <div className='d-flex'>
                        <div className='col'></div>
                        <div className='nu-icon nu-icon--big cursor-pointer' onClick={()=>{
                            setState({...state, show_reason_deny:false})
                        }}>
                            <IoCloseCircle color={darkBlue} size={30}></IoCloseCircle>
                        </div>
                    </div>
                    <div className='col d-flex flex-column justify-content-center align-items-center'>
                        <img width="70px" src='/assets/tick-circle-warning.png'></img>
                        <div className='f-22 bold text-center'>Indica los motivos de <br></br>denegación de la Acogida</div>
                        {/* <div className='f-14 bold text-center mt-3'>Recuerda que si lo que no te encaja es el perfil del profesional, deberías bloquearlo <br></br>para que no pueda solicitar acogidas en el centro.</div> */}

                        <textarea rows={5} value={state.reason_deny} className='nu-input f-14 mt-3' placeholder='Indica los motivos' onChange={(evt)=>{
                        setState({...state, reason_deny:evt.currentTarget.value})
                    }}></textarea>

                         <div className='w-100 d-flex flex-row  align-items-center justify-content-center mt-3'>

                            <div className='nu_btn me-2 px-3 px-md-0' onClick={()=>{
                                setState({...state, show_reason_deny:false})
                            }}>
                                <div className='nu-icon nu-icon--mid me-1'>
                                
                                    <MdClose size={18}></MdClose>
                                </div>
                                <div className='nu_btn-text'>Cancelar</div>
                            </div>


                     


                            <div className={`nu_btn nu_btn--primary`} onClick={()=>{
                                // setState({...state, show_reason_deny:true})
                                if(state.tab === 0){
                                    onDenyRequest()
                                }else{
                                    onDenyRequest('Acogida')
                                }
                            }}>

                                <div className='nu-icon nu-icon--mid me-1'>
                                
                                    <MdBlock size={18}></MdBlock>
                                </div>

                                <div className='nu_btn-text '>Denegar</div>
                            </div>
                          


                        </div>
                    </div>

                </div>
            </div>

        </Renderif>
        <Renderif isTrue={state.modalToBlock}>
          <div className='nu-modal'>
              <div className='nu-modal-body' onClick={(evt)=>{evt.stopPropagation()}}>
                  <div className='d-flex'>
                      <div className='col'></div>
                      <div className='nu-icon nu-icon--big cursor-pointer' onClick={()=>{
                          setState({...state, modalToBlock:false})
                      }}>
                          <IoCloseCircle color={darkBlue} size={30}></IoCloseCircle>
                      </div>
                  </div>
                  <div className='col d-flex flex-column justify-content-center align-items-center'>
                      <img width="100px" src='/assets/tick-circle-warning.png'></img>
                      <div className='f-26 bold text-center'>Marca los motivos por los cuales este<br></br>profesional dejará de ver tus turnos publicados: </div>
                  </div>

                  <div className='py-1'></div>

                  {restrictionReasons.map((item, i)=>{
                      return (
                          <div key={i} className='px-3'>
                              <CheckBox text={item.label} value={item.type} onChange={(evt)=>{
                                if(evt.value){
                                  setState({...state, reasons: [...state.reasons, item.type]})
                                } else {
                                  state.reasons = state.reasons.filter((reason) => reason!==item.type)
                                }
                              }}/>
                          </div>
                      )
                  })}

                  <div className='pt-2 f-14 text-center text-red'>Debes elegir al menos 1 motivo para poder bloquear</div>

                  <div className='d-flex align-items-center border-bottom py-3'>
                      <div className='col d-flex justify-content-center'>
                          <div className='nu_btn nu_btn--gray' style={{ paddingInline: '2rem', paddingBlock: '0.6rem' }} onClick={(evt) => {
                              evt.stopPropagation()
                              setState({...state, modalToBlock:false})
                          }}>
                              <div className='nu_btn-text f-16'>Cancelar</div>
                          </div>
                      </div>
                      <div className='col d-flex justify-content-center'>
                          <div className='nu_btn nu_btn--overlay' style={{ paddingInline: '3rem', paddingBlock: '0.6rem' }} onClick={(evt) => {
                              evt.stopPropagation()
                              onBlockProfesional()
                            //   onDenyRequest()
                          }}>
                              <div className='nu_btn-text f-16'>Aceptar</div>
                              <div className='nu_btn-overlay'></div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
        </Renderif>

      

   
   
  
     
    </div>
    </>
  )
}


function SideDetailReception({active, profesional, centers = [], onValidate = () => {}, onClose=() => {}}) {
    const {getUserType, center, centerType,assistanceUnit, getAssistanceUnit} = useContext(GlobalContext)
    const {notify} = useContext(NotificationContext)
    const [state, setState] = useState({
        
        centers:[],
        center_selected:'',
        assistenceunit_selected:'',
        centertype_selected:'',
        loading:false,
        receptions:[],
        hoverInfo: false,
        modalToAccept: false,
        modalConfirm: false,
    })

    useEffect(() => {
        
       
     
       receptions();
    
       return () => {}

    },[profesional, active])

 
    const receptions = async (confirmed = false) => {

            setState({})

            if(active === true && centers.length !== 0) {
                setState({...state, loading:true})
                state.receptions = []
                let acogidas = []

                for (let i = 0; i < centers.length; i++) {
                    let _receptions = await Service('GET', 'completedReception/getAll?idCenter='+centers[i].id)
                    acogidas.push({
                        center:centers[i],
                        reception:_receptions.data.list.filter((reception)=>reception.idUser === profesional.id)
                    })
                    
                }
                
        
               
                if (!confirmed) {
                    setState({...state, receptions:acogidas,center_selected:'', centertype_selected:'', assistenceunit_selected:'', loading:false, modalToAccept:false, modalConfirm:false})
                } else {
                    setState({...state, receptions:acogidas,center_selected:'', centertype_selected:'', assistenceunit_selected:'', loading:false, modalToAccept:false, modalConfirm:true})
                }
      
              
         
                
            }
    }
    const onCompleteReception = async (values) =>{
      
        

        if(state.center_selected === ''){
            notify({title:'Validar Acogida', message:'Selecciona un centro.', type:'error'}) 
            return;
        }
        if(state.centertype_selected === ''){
            notify({title:'Validar Acogida', message:'Selecciona un tipo centro.', type:'error'}) 
            return
        }

        if(state.assistenceunit_selected === '2' && state.assistenceunit_selected === ""){
            notify({title:'Validar Acogida', message:'Selecciona una unidad asistencial.', type:'error'})
            return
        }

        setState({...state, modalToAccept:true})

        // setState({...state, loading:true})

        // let body = {}
        // body = {
        //     idCenter:parseInt(state.center_selected),
        //     idUser:parseInt(profesional.id)
        // }
        // if(values.centertype_selected === '2'){
        //     body.idAssistanceUnit = parseInt(state.assistenceunit_selected)
        // }
        // let _completeReception = await Service('POST', 'completedReception/create',body)
        
        // if(_completeReception.status === 200){
        //     notify({title:'¡Listo!', message:'Se completado turno de acogida.', type:'success'})
        //     receptions();
        //     // setState({...state, loading:false})
        //     return;
        // }
                 
        // notify({title:'Error!', message:'Ocurrio un erorr al completar turno de acogida.', type:'error'})
        // setState({...state, loading:false})
    }

    const confirmReception = async (values) => {
        setState({...state, loading:true})

        let body = {}
        body = {
            idCenter:parseInt(state.center_selected),
            idUser:parseInt(profesional.id)
        }
        if(values.centertype_selected === '2'){
            body.idAssistanceUnit = parseInt(state.assistenceunit_selected)
        }
        let _completeReception = await Service('POST', 'completedReception/create',body)
        let _experience = {
            idProfessional: parseInt(profesional.id),
            idCenter:parseInt(state.center_selected),
            idAssistanceUnit:parseInt(state.assistenceunit_selected)
        }
        if(values.centertype_selected === '2'){
            let _createExperience = await Service('POST', 'experience',_experience)
        }
        
        if(_completeReception.status === 200){
            // notify({title:'¡Listo!', message:'Se completado turno de acogida.', type:'success'})
            receptions(true);
            setState({...state, modalToAccept:false, modalConfirm:true})
            return;
        }
        setState({...state, loading:false})
    }

    const handleMouseEnter = () => {
        setState({...state, hoverInfo:true})
    }

    const handleClose = () => {
        setState({...state, hoverInfo:false})
    }

    const hoverContentStyle = {
        position: 'absolute',
        top: '6.5rem',
        right: '1rem',
        backgroundColor: 'white',
        borderRadius: '10px',
        boxShadow: '0 6px 12px rgba(0, 0, 0, 0.2)',
        paddingTop: '20px',
        paddingBottom: '2px',
        paddingLeft: '25px',
        paddingRigth: '25px',
        zIndex: 1000,
        width: '22.5rem',
    };
      
    const closeButtonStyle = {
        position: 'absolute',
        top: '5px',
        right: '2px',
    };

    const paragraphStyle = {
        fontSize: '14px',
      };


  return (
    <div className={`nu-turno-detail hide-scroll ${active ? 'nu-turno-detail--active':''}`} onClick={()=>{
        // setState({...state, show_turno_detail:false })
        onClose()
    }}>
            <div className='nu-turno-content hide-scroll h-100' onClick={(evt)=>{evt.stopPropagation()}}>

                <div className='d-flex'>
                    <div className='col'></div>
                    
                    <div className='nu-icon nu-icon--mid r-100 bg-darkblue m-1 cursor-pointer' onClick={()=>{
                        // setState({...state, show_turno_detail:false})
                        onClose()
                    }}>
                        <IoMdClose color='white' size={16}></IoMdClose>
                    </div>
                </div>

                <div className=''>
                        <div className='d-flex  align-items-start b-b pb-3'>
                            {/* <div className='avatar me-2'></div> */}
                            <div className='nu-icon nu-icon--mid'>
                                <RxAvatar color='#0D78BF' size={30}></RxAvatar>
                            </div>
                            <div className=''>
                                <Renderif isTrue={profesional?.name === undefined}>
                                    <div className='f-16 bold'>Sin nombre</div>
                                </Renderif>
                                <div className='f-16 bold ps-2'>{profesional?.name}</div>

                                <div className='d-flex align-items-center ps-2'>
                       
                                        <div className='f-14 line-0'>{getUserType(profesional?.idUserType.toString())}</div>
                                        <div className='d-flex align-items-center ps-1'>
                                            <div className='nu-icon nu-icon--mid'>
                                                <IoMdStar color='#FFB054' size={20}></IoMdStar>
                                            </div>
                                            <div className='f-14 bolder line-0'>{profesional?.stars === null ? 'Sin Valoraciones' : parseFloat(profesional?.stars).toFixed(1)}</div>
                                            <div className='nu-icon nu-icon--mid' onMouseEnter={handleMouseEnter}>
                                                <IoMdInformationCircleOutline color='gray' size={18}></IoMdInformationCircleOutline>
                                            </div>
                                            {state.hoverInfo && (
                                                <div className='hover-content' style={hoverContentStyle}>
                                                    <div style={closeButtonStyle} className='nu-icon r-100 bg-darkblue m-1 cursor-pointer' onClick={handleClose}>
                                                        <IoMdClose color='white' size={16}></IoMdClose>
                                                    </div>
                                                    {/* <button onClick={handleClose} style={closeButtonStyle}>X</button> */}
                                                    <p className='pt-2' style={paragraphStyle}>Para valorar un profesional debe hacerse desde la valoración del turno.</p>
                                                </div>
                                            )}
                                        </div>
                                    
                                </div>
                            </div>
                        </div>
                </div>

                <div className='mt-3'>
                    <div className='d-flex align-items-center'>
                        <div className='f-18 bold text-darkblue'>Acogidas Completadas</div>
                        <div className='col'></div>
                        <Renderif isTrue={state.loading}>
                            <div className="spinner-border spinner--small text-darkblue"></div>
                        </Renderif>
                    </div>

                    {
                        state.receptions?.map((reception, i) => {
                            
                            return (
                                <div key={i} className="d-flex b-b pb-3 mt-2">
                                    <div className='col'>
                                        <div className='f-12 bold'>{reception.center.name}</div>
                                    </div>
                                    <div className='col'>
                                        <Renderif isTrue={reception.reception.length === 0}>
                                            <div className='f-12 bold text-gray'>Sin Acogidas</div>
                                        </Renderif>
                                        {
                                            reception.reception.map((item, j)=>{
                                                if (item.idAssistanceUnit === null) return
                                              
                                                return (
                                                    <div className='d-flex align-items-center'>
                                                         <div className='nu-icon nu-icon--mid me-1'>
                                                            <HiBadgeCheck color='#16B2DE' size={18}></HiBadgeCheck>
                                                        </div>
                                                        <div className='f-12 bold text-gray' style={{flexGrow: 1}} key={j}> {getAssistanceUnit(item.idAssistanceUnit)}</div>
                                                        <div className='nu-icon nu-icon--mid me-1'>
                                                            <FaTrash color='gray' size={14}></FaTrash>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>

                <div className='mt-2'>

                    <div className='f-18 bold text-darkblue'>Validar nueva Acogida</div>
                    <div>
                            <div className='mt-2'>
                                <NuSelect value={state.center_selected} onChange={(evt)=>{
                                        setState({...state, center_selected:evt.currentTarget.value})
                                    }}>
                                    <option value="">Seleccionar centro</option>
                                    {
                                        centers.map((center, i)=>{
                                        
                                            return (
                                                <option value={center.id}>{center.id}{center.name}</option>
                                            )
                                        })
                                    }
                                
                                </NuSelect>
                            </div>

                            <div className='mt-2'>
                                <NuSelect value={state.centertype_selected} onChange={(evt)=>{
                                        setState({...state, centertype_selected:evt.currentTarget.value})
                                    }}>
                                    <option value="">Seleccionar tipo de centro</option>
                                    {alphaOrder(centerType, 'description').map((item,i)=>{
                                            if(item.id==1) return;
                                    
                                            return (
                                                <option key={i} value={item.id}>{item.description}</option> 
                                            )
                                        
                                    })}
                                </NuSelect>
                            </div>

                            <Renderif isTrue={state.centertype_selected === '2'}>
                               <div className='mt-2'>
                                    <NuSelect value={state.assistenceunit_selected} onChange={(evt)=>{
                                            setState({...state, assistenceunit_selected:evt.currentTarget.value})
                                        }}>
                                        <option value="">Seleccionar Unidad Asistencial</option>
                                        { alphaOrder(assistanceUnit, 'description').map((item,i)=>{
                                            if(item.id === 1) {return}
                                            if(item.id === 14) {return}
                                            if(item.id === 21) {return}
                                            
                                            return (
                                                <option key={i} value={item.id}>{item.description}</option> 
                                            )
                                      
                                    })}
                                    </NuSelect>
                               </div>
                            </Renderif>



                            <div className='d-flex mt-3'>

                                <div className='col'></div>

                                <div className='nu_btn nu_btn--orange' onClick={()=>{
                                    // setState({...state, show_validate:true, profesiona_selected:item})
                                    // onCompleteReception()
                                    onCompleteReception({
                                        center_selected:state.center_selected,
                                        assistenceunit_selected:state.assistenceunit_selected,
                                        centertype_selected:state.centertype_selected,
                                    })

                                    }}>

                                    <div className='nu-icon nu-icon--mid me-1'>
                                        <HiBadgeCheck size={18}></HiBadgeCheck>
                                    </div>

                                    <div className='nu_btn-text '>Validar Acogida</div>
                                </div>
                            </div>

                    </div>
                </div>


            </div>

            <div>

            </div>
            <Renderif isTrue={state.modalToAccept}>
                <div className='nu-modal'>
                    <div>Sebas</div>
                    <div className='nu-modal-body' onClick={(evt)=>{evt.stopPropagation()}}>
                        <div className='d-flex'>
                            <div className='col'></div>
                            <div className='nu-icon nu-icon--big cursor-pointer' onClick={()=>{
                                setState({...state, modalToAccept:false})
                            }}>
                                <IoCloseCircle color={darkBlue} size={30}></IoCloseCircle>
                            </div>
                        </div>
                        <div className='col d-flex flex-column justify-content-center align-items-center'>
                            <img width="120px" src='/assets/tick-circle.png'></img>
                            <div className='f-30 bold text-center'>Acogida validada para<br></br>usuario {profesional?.name} </div>
                            <div className='pt-2 f-14 text-center'>Al validar una acogida para este profesional, podrá visualizar turnos<br></br>de cobertura en el centro y/o unidad que hayas seleccionado.</div>
                        </div>
                        <div className='d-flex align-items-center border-bottom py-3'>
                            <div className='col d-flex justify-content-center'>
                                <div className='nu_btn nu_btn--gray' style={{ paddingInline: '3rem', paddingBlock: '0.6rem' }} onClick={(evt) => {
                                    evt.stopPropagation()
                                    setState({...state, modalToAccept:false})
                                }}>
                                    <div className='nu_btn-text f-16'>Cancelar</div>
                                </div>
                            </div>
                            <div className='col d-flex justify-content-center'>
                                <div className='nu_btn nu_btn--orange' style={{ paddingInline: '3rem', paddingBlock: '0.6rem' }} onClick={(evt) => {
                                    evt.stopPropagation()
                                    confirmReception({
                                        center_selected:state.center_selected,
                                        assistenceunit_selected:state.assistenceunit_selected,
                                        centertype_selected:state.centertype_selected,
                                    })
                                }}>
                                    <div className='nu_btn-text f-16'>Sí, validar Acogida</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Renderif>

            <Renderif isTrue={state.modalConfirm}>
                <div className='nu-modal'>
                    <div className='nu-modal-body'>
                        <div className='d-flex'>
                            <div className='col'></div>
                            <div className='nu-icon nu-icon--big cursor-pointer' onClick={(evt)=>{
                                evt.stopPropagation()
                                setState({...state, modalToAccept: false, modalConfirm: false})
                            }}>
                                <IoCloseCircle color={darkBlue} size={30}></IoCloseCircle>
                            </div>
                        </div>
                        <div className='col d-flex flex-column justify-content-center align-items-center'>
                            <img width="120px" src='/assets/tick-circle.png'></img>
                            <div className='f-30 bold text-center'>Acogida validada para<br></br>usuario {profesional?.name} </div>
                        </div>
                    </div>
                </div>
            </Renderif>
    </div>
  )
}


function NuDropDown({item, blocked ,onSelect = () => {}, disabled = false, label=''}) {
    
    const [state, setState] = useState({
        open:false
    })

    const _onSelect = (key) => {
        setState({...state, open:false})
        onSelect(key)
    }

    return (
        <>
        <Renderif isTrue={state.open}>
            <div className='dropdown-overlay' onClick={(evt)=>{
                
                setState({...state, open:false})
            }}></div>
        </Renderif>

        <div className="nudropdow">
            {/* <div className='nu-icon nu-icon--big cursor-pointer' onClick={()=>{
                setState({...state, open:!state.open})
            }}>
                <BsThreeDotsVertical size={25} color={darkBlue}></BsThreeDotsVertical>
            </div> */}
             <div ref={el => {
        // el can be null - see https://reactjs.org/docs/refs-and-the-dom.html#caveats-with-callback-refs
        if (!el) return;

       
      }} className={`nu_btn nu_btn--${disabled ? 'disabled':'primary'} me-2`} onClick={()=>{

            setState({...state, open:!state.open})
            }}>

                <div className='nu-icon nu-icon--mid me-1'>
                    <HiBadgeCheck size={18}></HiBadgeCheck>
                </div>

                <div className='nu_btn-text '>{label}</div>
                
            </div>
            <Renderif isTrue={state.open}>
                <div className='dropdow-items dropdow-items--bottomcenter'>
                    <div className='dropdow-item' onClick={()=>{_onSelect(0)}}>
                        {/* <div className='nu-icon nu-icon--mid'>
                            <IoIosSearch size={18}></IoIosSearch>
                        </div> */}

                        <div className='nu_btn-text text-center'>Con Acogida</div>
                    </div>
                    <div className='dropdow-item' onClick={()=>{_onSelect(1)}}>
                        {/* <div className='nu-icon nu-icon--mid'>
                            <IoIosSearch size={18}></IoIosSearch>
                        </div> */}

                        <div className='nu_btn-text text-center '>Sin Acogida</div>
                    </div>
                  

                  
                </div>
            </Renderif>
        </div>
        </>
    )
 
}

