import React, { useContext, useEffect, useState } from 'react'

import { IoIosSearch, IoIosAddCircle, IoMdClose, IoMdStar, IoIosSend, IoMdCheckboxOutline} from "react-icons/io";
import { MdOutlinePersonOff, MdSearch } from "react-icons/md";
import { IoCloseCircle, IoCloseCircleOutline } from "react-icons/io5";
import { RiCloseCircleLine } from "react-icons/ri";
import { FiSend } from "react-icons/fi";
import CheckBox from './nu_checkbox';
import Renderif from '../utils/renderif';
import { FaEdit, FaHeart, FaRegHeart, FaRegStar, FaTrash } from "react-icons/fa";
import { dMY, experiencia, formacion, needsReception, profesionalExperiencia, timeOfDay, UTCDateParser } from '../utils';
import { GlobalContext } from '../context/global_context';
import { base_url, Service } from '../services/api';
import NuRating from './nu_rating';
import { AuthContext } from '../context/auth_context';
import { NotificationContext } from '../context/notification_context';
import NuLoading from './nu_loading';
import { useNavigate } from 'react-router-dom';
import { AdminContext } from '../context/admin_context';
import { CiEdit } from 'react-icons/ci';
import { darkBlue } from '../styles/colors';
import { GoDuplicate } from 'react-icons/go';
import { BsTrash } from 'react-icons/bs';
import { HiLightBulb } from "react-icons/hi";

export function NuTurnoItem({onClick, status, item, rated = false}) {
   
    const [state, setState] = useState({
        status,
        rated
    })

    const {
        renderTitle, 
        getCenter, 
        getAssistanceUnit,
        getProfessionalField,
        getSpecialization,
        getCenterType
    } = useContext(GlobalContext)
    const navigate = useNavigate();


    
    const getClassType = (status, type) => {

        if(status === '4' || status === '5'){
            return 'gray'
        }

        if(checkCompleted()){
            return 'darkblue'
        }
        
        if(status === '1' || status === '3'){
            return 'darkgreen'
        }
        if(type === '0'){
            return 'orange'
        }
        if(type === '1'){
            return 'lightblue'
        }

      
    }

    const checkCompleted = () => {
    
        let _today = new Date();
        var end_turno = parseInt(item?.initialHour.split(':')[0])
        var today = new Date(Date.UTC(_today.getFullYear(), _today.getMonth(), _today.getDate(), 0,0,0)).getTime()
        let _date = new Date(item?.date).getTime()

        if(today > _date){
            return true
        }

        if(today < _date){
            return false;
        }
        if(today === _date){
            if(_today.getHours() > end_turno){
                return true
            }
            return false;
        }
    }


  return (
     <div className={`nu-turno-item nu-turno-item--${getClassType(item.status, item.type)}`}>
        {/* <div>{item.idUser}</div> */}
        {/* <div className='f-20 bold'>{renderTitle(item?.idCenter, item.type)}</div> */}

            <div>
                <div className='f-20 bold'>{renderTitle(item?.idCenter, item?.type)}</div>
                
                <Renderif isTrue={item.idAssistanceUnit !== null && item.idCenterType === 2}>
                    <div className='f-14 mt-1'><span className='bold'>Unidad asistencial</span>: {getAssistanceUnit(item.idAssistanceUnit)}</div>
                </Renderif>
                <Renderif isTrue={item.idCenterType !== 2}>
                    <div className='f-14 ps-1'><span className='bold'>Tipo de centro</span>: {getCenterType(item.idCenterType)}</div>
                </Renderif>
            </div>

        <div className='d-flex flex-wrap align-items-end  mt-3'>

            <div className='col-12 col-md d-flex flex-wrap'>

                <div className='d-flex align-items-center pe-3'>
                        <div className='nu-icon nu-icon--mid'>
                            <img width="70%" src='/assets/calendar_solid.svg'></img>
                        </div>
                    
                        <div className='f-12 bold ellipsis'>{UTCDateParser(new Date(item.date).toUTCString(), true)}</div>
                </div>

                <div className='d-flex align-items-center  pe-3'>
                        <div className='nu-icon nu-icon--mid'>
                            <img width="70%" src='/assets/calendar_clock_solid.svg'></img>
                        </div>
                        <div className='f-12 bold ellipsis'>{timeOfDay(item.timeOfDay)}</div>
                </div>

                <div className='d-flex align-items-center pe-3'>
                        <div className='nu-icon nu-icon--mid'>
                            <img width="70%" src='/assets/clock_solid.svg'></img>
                        </div>
                        <div className='f-12 bold ellipsis'>{item.initialHour} hrs.</div>
                </div>

                <div className='d-flex align-items-center  pe-3'>
                        <div className='nu-icon nu-icon--mid'>
                            <img width="70%" src='/assets/euro_symbol_solid.svg'></img>
                        </div>
                        <div className='f-12 bold ellipsis'>{item.remuneration}</div>
                </div>

            </div>

            <div className='col'></div>
            
            <div className='d-flex mt-3 mt-md-0'>
        
                 <Renderif isTrue={item.status === '0' && !checkCompleted()}>
                    <div className='nu_btn nu_btn--primary ps-3' onClick={()=>{
                            
                            onClick();
                        }}>

                        <div className='nu-icon nu-icon--mid'>
                            <IoIosSearch size={20}></IoIosSearch>
                        </div>

                        <div className='nu_btn-text ps-2'>Ver turno</div>
                    </div>
                </Renderif>
               

                <Renderif isTrue={item.status === '5'}>
                    <div className='nu_btn ps-3' onClick={()=>{
                           
                           
                            onClick();
                        }}>

                        <div className='nu-icon nu-icon--mid'>
                            <IoIosSearch size={20}></IoIosSearch>
                        </div>

                        <div className='nu_btn-text ps-2'>Denegado</div>
                    </div>
                </Renderif>

                <Renderif isTrue={item.status === '4'}>
                    <div className='nu_btn ps-3' onClick={()=>{
                           
                           
                            onClick();
                        }}>

                        <div className='nu-icon nu-icon--mid'>
                            <IoIosSearch size={20}></IoIosSearch>
                        </div>

                        <div className='nu_btn-text ps-2'>No presentado</div>
                    </div>
                </Renderif>

                <Renderif isTrue={item.status === '3'}>

                    <div className={`nu_btn nu_btn--darkgreen ps-3`} onClick={()=>{
                        onClick();
                    }}>

                        <div className='nu-icon nu-icon--mid'>
                            <IoIosSearch size={20}></IoIosSearch>
                        </div>

                        <div className='nu_btn-text ps-2'>{'Solicitado'}</div>
                    </div>

                </Renderif>

                <Renderif isTrue={item.status === '1' && !checkCompleted()}>

                    <div className={`nu_btn nu_btn--darkgreen ps-3`} onClick={()=>{
                        onClick();
                    }}>

                        <div className='nu-icon nu-icon--mid'>
                            <IoIosSearch size={20}></IoIosSearch>
                        </div>

                        <div className='nu_btn-text ps-2'>{item.status === '3' ? 'Solicitado' : 'Asignado'}</div>
                    </div>

                </Renderif>

                <Renderif isTrue={checkCompleted() && (item.status === '1' || item.status === '2') }>

                    <div className='nu_btn nu_btn--primary ps-3' onClick={()=>{
                            onClick();
                    }}>

                        <div className='nu-icon nu-icon--mid'>
                            <IoIosSearch size={20}></IoIosSearch>
                        </div>

                        <div className='nu_btn-text ps-2'>Completado</div>
                    </div>


                </Renderif>
 
            </div>
             


        </div>
    </div>
  )
}

export function NuTurnoItemClinic({onClick, status, open = false, rated = false, onDelete = ()=>{}, onDuplicate=()=>{}, item, checkAttendedRequest = ()=>{}}) {

    // console.log(item);

  
    const navigate = useNavigate();
    
    const [state, setState] = useState({
        open,
        status,
  
        user:null, 
        loading:false,
        rated:null,
        rate:1, 
        rateComment:'',
        completedSelected:true,
        completed:null,
        suggestions:[],
        modalToNoPresent: false,
        commentForNoPresent: '',
        profesionals:null,
 
    })
  

    const {user} = useContext(AuthContext)
    const {notify} = useContext(NotificationContext)

    const {
        renderTitle, 
        getCenter, 
        getAssistanceUnit,
        getProfessionalField,
        getSpecialization,
        getCenterType,
        getProfesion
    } = useContext(GlobalContext)
    
    useEffect(()=>{
        const init = async() => {
            // setState({...state, open:false})

            // if(item && checkCompleted()){
            //     let  _rated = await Service('GET', `rating/checkIfExist?type=1&idCenter=${item?.idCenter}&idUser=${user?.id}&idShift=${item?.id}`)
            
            //     setState({...state, rated:_rated.data.exist})
            //  }

            if(item.publicateTo !== null){
                let _user = await Service('GET', 'shift/getById/'+item.id)
                let _profesionals = null
                if(_user?.data?.shift?.publicateTo !== null){

                    if (JSON.parse(_user?.data?.shift?.publicateTo).length) {
                        _profesionals = await Service('GET', 'shift/users/'+_user?.data?.shift?.id) 
                        _profesionals = _profesionals?.data?.list
                        state.profesionals = _profesionals
                    }
                    setState({...state, user:_user?.data?.user, open:false, profesionals: _profesionals})

                }
               
            }else{
                setState({...state, user:null, open:false})
            }
           
            if(item.idUser !== null){
                let _user = await Service('GET', 'shift/getById/'+item.id)
                
                setState({...state, user:_user?.data?.user, open:false})
            }else{
                setState({...state, user:null, open:false})
            }
           
        }
        init();
        return () => {}
    },[item])

    const onRateTurno = async() => {

    
    
        setState({...state, loading:true})

        let body = {
            type: '0',
            //Propiedad type es un enum, solo acepta los valores "0" y "1"
            //"0" - Valoración del centro al nurse/tcae/student
            //"1" - Valoración del nurse/tcae/student al centro
            idCenter: item.idCenter,
            idUser: item.idUser,
            idShift: item.id,
            stars: state.rate, //Min 0, Max 5
            comment: state.rateComment,
            
        }

   
        let _rate = await Service('POST', 'rating/create', body)
        if(_rate.status === 201){
            notify({title:'¡Listo!', message:'Valoración enviada.', type:'success'})
            setState({...state, loading:false, rated:true})
        }else{
         
            notify({title:'Error!', message:_rate.response.data.error, type:'error'})
            setState({...state, loading:false})
        }  
        
      
   

    }
    

    const onCompleteTurno = async() => {

            let body = {
                type: '0',
                idCenter: item.idCenter,
                idUser: item.idUser,
                idShift: item.id,
                stars: '5',
                comment: state.rateComment,
                
            }
            let _rate = await Service('POST', 'rating/create', body)

            let body_recepction = {
                idCenter:item.idCenter,
                idUser:item.idUser,
            }
            if(item.idAssistanceUnit !== null){
                body_recepction.idAssistanceUnit = item.idAssistanceUnit
            }
            let _completeReception = await Service('POST', 'completedReception/create',body_recepction)

            let _experience = {
                idProfessional: item.idUser,
                idCenter:item.idCenter,
                idAssistanceUnit:item.idAssistanceUnit,
                idProfessionalField: item.idProfessionalField
            }
            
            if(item.idAssistanceUnit !== null){
                let _createExperience = await Service('POST', 'experience',_experience)
            }

            
           
            if(_completeReception.status === 200){
                notify({title:'¡Listo!', message:'Se completado turno de acogida.', type:'success'})
                setState({...state, loading:false, completed:true})
                return;
            }
                     
            notify({title:'Error!', message:'Ocurrio un erorr al completar turno de acogida.', type:'error'})
            setState({...state, loading:false})

   

    }

    const getClassType = (status, type) => {

        if(status === '4'){
            return 'gray'
        }

        if(checkCompleted()){
            return 'darkblue'
        }
        
        if(status === '1'){
            return 'darkgreen'
        }
        if(status === '3'){
            if(checkAttendedRequest(
                item.idAssistanceUnit,
                item.center.id,
                item.center.idOrganization,
                item.publicateTo,
                item.idUser
            )){
                return 'gray'
            } else {
                return 'darkgreen'
            }
        }
        if(type === '0'){
            return 'orange'
        }
        if(type === '1'){
            return 'lightblue'
        }

      
    }

    const checkCompleted = () => {
    
        let _today = new Date();
        var end_turno = parseInt(item?.initialHour.split(':')[0])
        var today = new Date(Date.UTC(_today.getFullYear(), _today.getMonth(), _today.getDate(), 0,0,0)).getTime()
        let _date = new Date(item?.date).getTime()

        if(today > _date){
            return true
        }

        if(today < _date){
            return false;
        }
        if(today === _date){
            if(_today.getHours() > end_turno){
                return true
            }
            return false;
        }
    }

    const onBlockProfesional = async () => {
        setState({...state, loading:true})
        let body = {
            type:'0',
            idCenter:item.idCenter,
            idUser:item.idUser
        }
        let _block = await Service('POST', 'restriction/create', body);
    
        if(_block.status === 201){
            notify({title:'¡Listo!', message:'Profesional bloqueado exitosamente', type:'success'})
        }else{
            if(_block.response.status === 400){
                notify({title:'Profesional bloqueado!', message:'Ya has bloqueado este profesional.', type:'error'})  
            }else{
                notify({title:'Error!', message:'Ocurrio un error al itentar bloquear profesional.', type:'error'})
            }
        }
    
        setState({...state, loading:false})
        
    }
    
    const onUnattendedShift = async (idShift) => {
        setState({...state, loading:true})
        let body = {
            idUser: null,
            idCenter: null,
            idOrganization: null,
            idShift: idShift,
            user_comment: null,
            clinic_comment: state.commentForNoPresent
        }
        // console.log("body: ", body);
        
        let _unattended = await Service('PUT', `shift/unattendance/${idShift}`, {});
        // console.log(_unattended)
        if (_unattended.status === 200) {
            let _incident = await Service('POST', 'incident/create', body);
            // console.log(_incident)
            if (_incident.status === 200) {
                notify({title:'¡Listo!', message:'Turno marcado con profesional no presentado', type:'success'})
                item.status = '4'
            } else {
                notify({title:'Error!', message:'Ocurrio un error al marcar este turno con profesional no presentado.', type:'error'})
            }
        } else {
            notify({title:'Error!', message:'Ocurrio un error al marcar este turno con profesional no presentado.', type:'error'})
        }
        setState({...state, loading:false, commentForNoPresent: "", modalToNoPresent: false})
    }
    


    return (
        <>
         {/* <Renderif isTrue={state.loading}>
            <NuLoading></NuLoading>
        </Renderif> */}
       <div className={`
       
        nu-turno-item
        nu-turno-item--${getClassType(item?.status, item?.type)} 
     
       
       `}>
            <div className='d-flex'>
              
          

            <div>
                <div className='f-20 bold'>{renderTitle(item?.idCenter, item?.type)}</div>
                
                <Renderif isTrue={item.idAssistanceUnit !== null && item.idCenterType === 2}>
                    <div className='f-14 mt-1'><span className='bold'>Unidad asistencial</span>: {getAssistanceUnit(item.idAssistanceUnit)}</div>
                </Renderif>
                <Renderif isTrue={item.idCenterType !== 2}>
                    <div className='f-14 ps-1'><span className='bold'>Tipo de centro</span>: {getCenterType(item.idCenterType)}</div>
                </Renderif>
            </div>

            <div className='col'></div>

                <Renderif isTrue={item?.status === '4'}>
                    <div className='f-14 bold pe-3' style={{ color: 'gray' }}>Profesional no presentado</div>
                </Renderif>

                <Renderif isTrue={checkCompleted() && item.idUser !== null && item?.status !== '4'}>
                    <div className='f-14 bold text-darkblue pe-3'>Completado</div>
                </Renderif>

                <Renderif isTrue={checkCompleted() && item.idUser === null}>
                    <div className='f-14 bold text-darkblue pe-3'>Expirado</div>
                </Renderif>
                
                <Renderif isTrue={item?.status === '3'}>
                    {checkAttendedRequest(item.idAssistanceUnit, item.center.id, item.center.idOrganization, item.publicateTo, item.idUser) ? (
                        <div className="f-14 bold text-gray pe-3">Solicitud atendida</div>
                    ) : (
                        <div className="f-14 bold text-orange pe-3">Pendiente de atender solicitud</div>
                    )}
                </Renderif>

                <Renderif isTrue={!checkCompleted() && item?.status === '0'}>
                    <div className='d-flex align-items-center pe-2'>
                        <div className='f-14 bold text-gray pe-3'>Pendiente de asignar</div>
                        <div className='nu-icon nu-icon--mid cursor-pointer' onClick={()=>{
                            // navigate('/clinic/publicar/', { state: item } )
                            onDelete()
                        }}>
                            <FaTrash size={16} color='gray'></FaTrash>
                        </div>
                    </div>
                </Renderif>
                
                <Renderif isTrue={!checkCompleted() && item.status === '1'}>
                    <div className='f-14 bold text-darkgreen pe-3'>Asignado</div>
                </Renderif>
            </div>

            <div className='d-flex align-items-end  mt-3'>
                <div className='d-flex flex-wrap'>
                    <div className='d-flex align-items-center pe-3'>
                            <div className='nu-icon nu-icon--mid'>
                                <img width="70%" src='/assets/calendar_solid.svg'></img>
                            </div>
                        
                            
                            <div className='f-12 bold ellipsis'>{UTCDateParser(new Date(item.date).toUTCString(), true)}</div>
                        
                    </div>
        
                    <div className='d-flex align-items-center  pe-3'>
                            <div className='nu-icon nu-icon--mid'>
                                <img width="70%" src='/assets/calendar_clock_solid.svg'></img>
                            </div>
                            <div className='f-12 bold ellipsis'>{timeOfDay(item.timeOfDay)}</div>
                    </div>
                    <div className='d-flex align-items-center pe-3'>
                            <div className='nu-icon nu-icon--mid'>
                                <img width="70%" src='/assets/clock_solid.svg'></img>
                            </div>
                            <div className='f-12 bold ellipsis'>{item.initialHour} hrs.</div>
                    </div>
                    <div className='d-flex align-items-center  pe-3'>
                            <div className='nu-icon nu-icon--mid'>
                                <img width="70%" src='/assets/euro_symbol_solid.svg'></img>
                            </div>
                            <div className='f-12 bold ellipsis'>{item.remuneration}</div>
                    </div>
                </div>
             </div>

            <div className='d-flex align-items-end  mt-3 mt-xl-0'>
                
                {/* <Renderif isTrue={item.onlyFavorites}>

                    <div className='ps-1'>
                        <div className='nu-icon'>
                            <FaHeart color='DFDFDF'></FaHeart>
                        </div>
                    </div>

                </Renderif> */}

                <div style={{ marginRight: '1.5em', display: 'flex', alignItems: 'end' }}>
                    <div className='f-12 bold ellipsis'>Publicado para:</div>
                    <div style={{ display: 'flex', alignItems: 'end' }} className='col-12 col-lg-4 d-flex flex-wrap pt-1 ps-1'>
                        {item?.onlyFavorites ? (
                            <span className='f-12 bold ps-2'>Favoritos</span>
                        ) : (!item.onlyFavorites && !item.onlyMyTeam && JSON.parse(item?.publicateTo || "[]").length > 0) ? (
                            <span className='f-12 bold ps-2'>Profesionales</span>
                        ) : (item.onlyMyTeam && !item.onlyFavorites) ? (
                            <span className='f-12 bold ps-2'>Mi equipo</span>
                        ) : (
                            <span className='f-12 bold ps-2'>Todos</span>
                        )}
                    </div>
                </div>
               
                {state.user && (
                    <div>
                        <div className='f-12 bold ellipsis'>Profesional:
                            <span className='f-12 bold text-darkblue cursor-pointer' onClick={()=>{
                           
                                navigate(`/clinic/profesionales/${item?.idUser}`)
                            }}>{' ' + state.user?.name}</span>
                        </div>
                    </div>
                )}

                <div className='col'></div>

                <div className='d-flex flex-wrap'>
                {/* Botón Revisar */}
                    <div className='me-2'>
                        {/* <div className={`nu_btn nu_btn--${item?.status === '3' ? 'darkgreen' : 'primary'}`} id="btnRevisar" onClick={async ()=>{ */}
                        <div className={`nu_btn nu_btn--primary`} id="btnRevisar" onClick={async ()=>{

                      
                            console.log(item)
                    

                            if(state.open){
                                state.open = false;
                                setState({...state})   
                                return;
                            }

                            state.open = true;
                            if(state.user === null){
                                state.loading = true
                            }
                        

                

                    
                            setState({...state, loading:true});
                            if(item.idUser !== null){
                                let  _rated = await Service('GET', `rating/checkIfExist?type=0&idCenter=${item?.idCenter}&idUser=${item?.idUser}&idShift=${item?.id}`)
                                console.log(_rated)
                                state.rated = _rated.data.exist;
                              
                            
                            }


                            let _suggestions = await Service('GET', `suggestion/getAll?idShift=${item.id}`);
                    
                            state.suggestions = _suggestions?.data?.list;
                    


                            if(item?.type === '0'){
                            
                                let _completeReceptions = await Service('GET', `completedReception/getAll`)
                                let reception
                                if(item.idCenterType === 2){
                                    reception = _completeReceptions.data.list.filter((reception) => reception.idUser === item.idUser && reception.idCenter === item.idCenter && reception.idAssistanceUnit === item.idAssistanceUnit);
                                }else{
                                    reception = _completeReceptions.data.list.filter((reception) => reception.idUser === item.idUser && reception.idCenter === item.idCenter); 
                                }

        
                                if(reception.length > 0){
                                    state.completed = true;
                                    
                                }else{
                                    state.completed = false;
                                 
                                }
                            }
                        
                            let _user = await Service('GET', 'shift/getById/'+item.id)
                            state.user = _user.data.user
                        

                            setState({...state, loading:false})


                            }}>
                            <div className='nu-icon nu-icon--mid'>
                                <IoIosSearch size={18}></IoIosSearch>
                            </div>
                            {/* <div className='nu_btn-text'>{item?.status === '3' ? 'Solicitado' : 'Revisar'}</div> */}
                            <div className='nu_btn-text'>{'Revisar'}</div>
                        </div>
                    </div>
                    
                    {/* Botón Duplicar */}
                    <div className='me-2'>
                        <div className='nu_btn nu_btn--primary' onClick={() => {
                            onDuplicate(item)
                        }}>
                            <div className='nu-icon nu-icon--mid'>
                                <GoDuplicate size={18}></GoDuplicate>
                            </div>
                            <div className='nu_btn-text'>Duplicar</div>
                        </div>
                    </div>

                    <Renderif isTrue={item.idUser === null}>
                        <div className='me-2'>
                            <div className='nu_btn nu_btn--primary align-items-center' onClick={()=>{
                                // setState({...state, open:!state.open})
                                navigate('/clinic/publicar/', { state: {turno:item} } )
                                // onDelete()
                                }}>
                                <div className='nu-icon nu-icon--mid'>
                                <FaEdit size={16}></FaEdit>
                                </div>
                                <div className='nu_btn-text'>Editar</div>
                            </div>
                        </div>
                    </Renderif>

                    <Renderif isTrue={item.status === '3' && !checkCompleted()}>
                        <div className='me-2'>
                            <div className='nu_btn nu_btn--orange align-items-center' onClick={()=>{
                                //   window.open(`${base_url}clinic/solicitudes/${item.id}`,'_blank')
                                //   navigate('')
                                navigate('/clinic/solicitudes/'+item.id)

                            }}>
                                <div className='nu-icon nu-icon--mid'>
                                    <IoMdCheckboxOutline size={16}></IoMdCheckboxOutline>
                                </div>
                                <div className='nu_btn-text'>Ver solicitud</div>
                            </div>
                        </div>
                    </Renderif>
               
               </div>

             </div>

            
            <Renderif isTrue={state.open}>
                <div className='d-flex flex-wrap mt-5'>
                    <div className='col-12 col-md-5 px-md-2'>
                        <div className='f-20 bold'>Descripción</div>
                        <div className='mt-3'>
                        
                            {/* <div className='f-14 mt-1'><span className='bold'>Centro</span>: {getCenter(item.idCenter)?.name}</div> */}
                            <div className='f-14 mt-1'><span className='bold'>Tipo de centro</span>: {getCenterType(item.idCenterType)}</div>
                            <div className='f-14 mt-1'><span className='bold'>Día</span>: {UTCDateParser(new Date(item.date).toUTCString(), true)}</div>
                            <div className='f-14 mt-1'><span className='bold'>Horario de turno</span>: {timeOfDay(item.timeOfDay)}</div>
                            <div className='f-14 mt-1'><span className='bold'>Inicio de turno</span>: {item.initialHour}  hrs.</div>
                            <div className='f-14 mt-1'><span className='bold'>Fin de turno</span>: {item.finalHour}  hrs.</div>

                        </div>
                    </div>

                    <div className='col-12 col-md-7 mt-3 mt-md-0'>
                        <div className='f-20 bold'>Criterios de selección</div>
                        <div className='mt-3'>
                            <div className='f-14 mt-1'><span className='bold'>Profesión</span>: {getProfesion(item.profession)}</div>
                            {/* <Renderif isTrue={item.needsReception !== ""}>
                                <div className='f-14 mt-1'><span className='bold'>Necesita el profesional haber completado la acogida</span>: {needsReception(item.needsReception)}</div>
                            </Renderif> */}
                            <div className='f-14 mt-1'><span className='bold'>{item.needsReception === '1' || item.needsReception === '2' ? 'Con acogida previa' : 'Sin acogida previa'}</span></div>
                            <Renderif isTrue={item.idAssistanceUnit !== null}>
                                <div className='f-14 mt-1'><span className='bold'>Unidad asistencial</span>: {getAssistanceUnit(item.idAssistanceUnit)}</div>
                            </Renderif>
                            <div className='f-14 mt-1'><span className='bold'>Ámbito profesional</span>: {getProfessionalField(item.idProfessionalField)}</div>
                            <Renderif isTrue={item.idSpecialization !== null && item.profession === '0'}>
                                <div className='f-14 mt-1'><span className='bold'>Especialidades de Enfermería (EIR)</span>: {getSpecialization(item.idSpecialization)}</div>
                            </Renderif>
                            <div className='f-14 mt-1'><span className='bold'>Años de experiencia en la profesión</span>: {profesionalExperiencia(item.experience)}</div>
                            <Renderif isTrue={item.idCenterType === 2}>
                                <div className='f-14 mt-1'><span className='bold'>Experiencia en la Unidad asistencial</span>: {experiencia(item.assistanceUnitExperience)}</div>
                            </Renderif>
                            <Renderif isTrue={item.idProfessionalField !== 1}>
                                <div className='f-14 mt-1'><span className='bold'>Experiencia en el ámbito profesional</span>: {experiencia(item.professionalFieldExperience)}</div>
                            </Renderif>
                            <div className='f-14 mt-1'><span className='bold'>Remuneración por turno</span>: € {item.remuneration}</div>
                            <Renderif isTrue={item.idCenterType === 2}>
                                <div className='f-14 mt-1'><span className='bold'>Formación en la unidad asistencial</span>: {formacion(item.assistanceUnitEducation === null ? '1' : item.assistanceUnitEducation === false ? '1' : '0')}</div>
                            </Renderif>
                            <Renderif isTrue={item.idProfessionalField !== 1}>
                                <div className='f-14 mt-1'><span className='bold'>Formación en el ámbito profesional</span>: {formacion(item.professionalFieldEducation === null ? '1' : item.professionalFieldEducation === false ? '1' : '0')}</div>
                            </Renderif>
                        </div>
                    </div>

                    <Renderif isTrue={item.onlyFavorites || (!item?.onlyMyTeam && !item?.onlyFavorites && item?.publicateTo?.length)}>
                        <div className='col-12 col-md-12 px-md-2 pb-2'>
                            <div className='f-16 bold'>Publicado para:
                                {state.profesionals?.map((profesional, index) => (
                                    <span
                                        className='f-14 bold text-darkblue cursor-pointer'
                                        key={index}
                                        onClick={() => {
                                            navigate(`/clinic/profesional/${profesional.idUser}`);
                                        }}
                                    >
                                        &nbsp;{profesional.name}&nbsp;
                                    </span>
                                ))}
                            </div>
                        </div>
                    </Renderif>
                    <div className='col-12 col-md-5 px-md-2'>
                        <div className='f-20 bold'>Descripción del turno</div>
                        <div className='mt-3'>
                        <Renderif isTrue={item.description}>
                            <div className='f-14'>{item.description}</div>
                        </Renderif>
                        <Renderif isTrue={!item.description}>
                            <div className='f-14'>Este turno no tiene descripción.</div>
                        </Renderif>
                        </div>
                    </div>
                </div>

                <hr className='mt-5'></hr>

               
                {/* <Renderif isTrue={2==2}> */}
                <Renderif isTrue={checkCompleted() && parseInt(item?.status) > 0}>
                    <Renderif isTrue={state.loading}>
                        <div className='d-flex justify-content-center align-items-center w-100'>
                            <div className="spinner-border text-secondary"></div>
                        </div>
                    </Renderif>
                    <Renderif isTrue={!state.loading}>
                        <div className="d-flex align-items-center bg-light r-10 p-3 mt-2">
                            <div className="d-none d-lg-flex flex-column align-items-center me-3">
                                <div className="avatar avatar--mid"></div>
                            </div>

                            <div className="col">
                                <div className="d-flex flex-wrap">
                                    <div className="col-6 col-lg-2 align-content-center">
                                        <div className='f-18 bold ps-2 text-darkblue cursor-pointer' onClick={()=>{
                                            navigate(`/clinic/profesionales/${item?.idUser}`)
                                        }}>{state.user?.name}</div>
                                    </div>
                                    <div className="col-6 col-lg-2 align-content-center">
                                        <div className='d-flex align-items-center'>
                                            <div className='nu-icon nu-icon--mid'>
                                                <IoMdStar color='#FFB054' size={24}></IoMdStar>
                                            </div>
                                            <div className='f-14 bold'>
                                                <Renderif isTrue={state.user?.stars === null}>
                                                    Sin Valoraciones
                                                </Renderif>
                                                <Renderif isTrue={state.user?.stars !== null}>
                                                    {parseFloat(state.user?.stars).toFixed(1)}
                                                </Renderif>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-2 col-lg-2"></div>
                                    <div className="col-6 col-lg-4">
                                        <div className='d-flex align-items-center border-bottom py-3'>
                                            {/* <div className='me-2'>
                                                <div className='nu_btn nu_btn--overlay' style={{ paddingInline: '3rem', paddingBlock: '0.6rem' }} onClick={(evt) => {
                                                    evt.stopPropagation()
                                                    // onBlockProfesional()
                                                }}>
                                                    <div className='nu_btn-text'>Valorar profesional</div>
                                                    <div className='nu_btn-overlay'></div>
                                                </div>
                                            </div> */}
                                            <Renderif isTrue={item.type === "0" && state.completed !== true && item.status !== '4'}>
                                                <div className='me-2'>
                                                    <div className='nu_btn nu_btn--gray' style={{ paddingInline: '1rem', paddingBlock: '0.6rem' }} onClick={(evt) => {
                                                        evt.stopPropagation()
                                                        if (item?.status === "4") {
                                                            notify({title:'¡Advertencia!', message:'Este turno ya fue marcado con profesional no presentado.', type:'error'})
                                                        } else {
                                                            setState({...state, modalToNoPresent:true})
                                                        }
                                                    }}>
                                                        <div className='nu_btn-text'>Profesional no presentado</div>
                                                    </div>
                                                </div>
                                            </Renderif>
                                            <Renderif isTrue={item.type === "1" && state.rated !== true && item.status !== '4'}>
                                                <div className='me-2'>
                                                    <div className='nu_btn nu_btn--gray' style={{ paddingInline: '1rem', paddingBlock: '0.6rem' }} onClick={(evt) => {
                                                        evt.stopPropagation()
                                                        if (item?.status === "4") {
                                                            notify({title:'¡Advertencia!', message:'Este turno ya fue marcado con profesional no presentado.', type:'error'})
                                                        } else {
                                                            setState({...state, modalToNoPresent:true})
                                                        }
                                                    }}>
                                                        <div className='nu_btn-text'>Profesional no presentado</div>
                                                    </div>
                                                </div>
                                            </Renderif>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='py-2'></div>
                        <div className='px-3'>
                            <>{item.sta}</>
                            <Renderif isTrue={item.status === "2"}>

                                <Renderif isTrue={item.type === "0"}>
                                    <div className='f-18 bold'>Evaluar turno de Acogida en Centro Sanitario</div>
                                    <Renderif isTrue={state.rated === true  }>
                                        <div className='f-16 bold text-darkblue'>Valoración enviada</div>
                                    </Renderif>

                                    <Renderif isTrue={state.rated !== true }>
                                        <CheckBox checked={state.completedSelected} onChange={(evt)=>{
                                                setState({...state, completedSelected:evt.value})
                                        }} text="Completada"></CheckBox>

                                        {/* <CheckBox checked={state.completedSelected === false} onChange={(evt)=>{
                                                setState({...state, completedSelected:!evt.value})
                                        }} text="Seguir mostrando turnos de acogida"></CheckBox> */}

                                        <textarea rows={5} className='nu-input mt-3 f-14' onChange={(evt)=>{
                                                            setState({...state, rateComment:evt.currentTarget.value})
                                            }}></textarea>
                                        <div className={`nu_btn nu_btn--primary mt-1`} onClick={(evt) => {
                                                
                                                onCompleteTurno()
                                            }}>
                                                <div className='nu_btn-text'>Enviar</div>
                                        </div>
                                    </Renderif>

                                </Renderif>

                                
                                <Renderif isTrue={item.type === "1"}>
                                    <div className='f-18 bold'>Evaluar turno de Cobertura en Centro Sanitario</div>

                                    <Renderif isTrue={state.rated === true }>
                                        <div className='f-16 bold text-darkblue'>Valoración enviada</div>
                                    </Renderif>

                                    <Renderif isTrue={state.rated !== true}>
                                        <div className='mt-3'>
                                            <NuRating value={0} onChange={(value)=> {
                                                setState({...state, rate:value})
                                            }}/>
                                            <textarea rows={5} className='nu-input mt-3 f-14' onChange={(evt)=>{
                                                            setState({...state, rateComment:evt.currentTarget.value})
                                            }}></textarea>
                                            <div className={`nu_btn nu_btn--primary mt-1`} onClick={(evt) => {
                                            
                                                onRateTurno()
                                            }}>
                                                <div className='nu_btn-text'>Enviar</div>
                                            </div>
                                        </div>
                                    </Renderif>                                
                                </Renderif>

                            </Renderif>
                        </div>
                        {/* <div className='d-flex flex-wrap mt-2'>
                            <div className='col-12 col-md-6 px-md-2'>
                                <div className='f-20 bold'>Turno asignado por</div>
                                <div className='mt-3'>
                                
                                    <div className='d-flex fit-content bg-white r-10 p-3'>
                                        <div className='avatar'></div>
                                        <div className='ps-2'>

                                            <div className='f-18 bold ps-2'>{state.user?.name}</div>
                                            <div className='d-flex align-items-center'>

                                                <div className='nu-icon nu-icon--mid'>
                                                    <IoMdStar color='#FFB054' size={24}></IoMdStar>
                                                </div>

                                                <div className='f-14 bold'>
                                                    
                                                    <Renderif isTrue={state.user?.stars === null}>
                                                        Sin Valoraciones
                                                    </Renderif>
                                                    <Renderif isTrue={state.user?.stars !== null}>
                                                        {parseFloat(state.user?.stars).toFixed(1)}
                                                    </Renderif>
                                                </div>
                                            
                                            </div>


                                            <div className='nu_btn nu_btn--primary mt-4 w-100' onClick={()=>{
                                                navigate(`/clinic/documentacion/${item.idUser}`)
                                            }}>
                                                <div className='nu-icon nu-icon--mid me-1'>
                                                    <MdSearch size={24}></MdSearch>
                                                </div>
                                                <div className='nu_btn-text'>Ver documentación</div>
                                            </div>
                                            <div className='nu_btn nu_btn--danger mt-2 w-100' onClick={()=>{
                                                onBlockProfesional()
                                            }}>
                                                <div className='nu-icon nu-icon--mid me-1'>
                                                    <RiCloseCircleLine size={20}></RiCloseCircleLine>
                                                </div>
                                                <div className='nu_btn-text'>Bloquear profesional</div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='col-12 col-md-6 my-3 my-md-0'>

                                <Renderif isTrue={item.type === "0"}>
                                    <div className='f-18 bold'>Evaluar turno de Acogida en Centro Sanitario</div>
                                    <Renderif isTrue={state.completed === true }>
                                        <div className='f-16 bold text-darkblue'>Valoración enviada</div>
                                    </Renderif>

                                    <Renderif isTrue={state.completed !== true }>
                                        <CheckBox checked={state.completedSelected} onChange={(evt)=>{
                                                setState({...state, completedSelected:evt.value})
                                        }} text="Completada"></CheckBox>
                                        <CheckBox checked={state.completedSelected === false} onChange={(evt)=>{
                                                setState({...state, completedSelected:!evt.value})
                                        }} text="Seguir mostrando turnos de acogida"></CheckBox>
                                        <textarea rows={5} className='nu-input mt-3 f-14' onChange={(evt)=>{
                                                            setState({...state, rateComment:evt.currentTarget.value})
                                            }}></textarea>
                                        <div className={`nu_btn nu_btn--primary mt-1`} onClick={(evt) => {
                                                
                                                onCompleteTurno()
                                            }}>
                                                <div className='nu_btn-text'>Enviar</div>
                                        </div>
                                    </Renderif>

                                </Renderif>

                                
                                <Renderif isTrue={item.type === "1"}>
                                    <div className='f-18 bold'>Evaluar turno de Cobertura en Centro Sanitario</div>

                                    <Renderif isTrue={state.rated === true }>
                                        <div className='f-16 bold text-darkblue'>Valoración enviada</div>
                                    </Renderif>

                                    <Renderif isTrue={state.rated !== true}>
                                        <div className='mt-3'>
                                            <NuRating value={0} onChange={(value)=> {
                                                setState({...state, rate:value})
                                            }}/>
                                            <textarea rows={5} className='nu-input mt-3 f-14' onChange={(evt)=>{
                                                            setState({...state, rateComment:evt.currentTarget.value})
                                            }}></textarea>
                                            <div className={`nu_btn nu_btn--primary mt-1`} onClick={(evt) => {
                                          
                                                onRateTurno()
                                            }}>
                                                <div className='nu_btn-text'>Enviar</div>
                                            </div>
                                        </div>
                                    </Renderif>                                
                                </Renderif>
                                
                            </div>
                        </div> */}
                    </Renderif>
                </Renderif>
          
                <Renderif isTrue={item.status === '1' && !checkCompleted()}>
                    <div className='d-flex mt-2'>
                        <div className='col-5 ps-2'>
                            <div className='f-20 bold'>Turno asignado por</div>
                        </div>
                        <div className='col-7'>
                        
                            <div className='d-flex bg-white r-10 p-3 '>
                                <Renderif isTrue={state.loading}>
                                    <div className='d-flex justify-content-center align-items-center w-100'>
                                        <div className="spinner-border text-secondary"></div>
                                    </div>
                                </Renderif>

                                <Renderif isTrue={!state.loading}>
                                
                                    <div className='avatar'></div>
                                    
                                    <div className='ps-2'>

                                        <div className='f-18 bold ps-2'>{state.user?.name}</div>
                                        

                                        <div className='d-flex align-items-center '>

                                            <div className='nu-icon nu-icon--mid'>
                                                <IoMdStar color='#FFB054' size={24}></IoMdStar>
                                            </div>
                                            <div className='f-14 bold'>
                                                <Renderif isTrue={state.user?.stars === null}>
                                                    Sin Valoraciones
                                                </Renderif>
                                                <Renderif isTrue={state.user?.stars !== null}>
                                                    {parseFloat(state.user?.stars).toFixed(1)}
                                                </Renderif>
                                                
                                            </div>

                                            <div className='col'></div>

                                            
                                        
                                        </div>

                                        
                                        

                                    </div>

                                    <div className='col'></div>
                                        <div className='nu_btn nu_btn--primary mt-4' onClick={()=>{
                                             navigate(`/clinic/profesionales/${item?.idUser}`)
                                        }}>
                                        <div className='nu-icon nu-icon--mid me-1'>
                                            <MdSearch size={24}></MdSearch>
                                        </div>
                                        <div className='nu_btn-text'>Ver perfil</div>
                                    </div>
                                </Renderif>
                            </div>
                          
                        </div>

                       
                    </div>
                </Renderif>

           
                <div className='py-2'></div>
                <div className='px-1 mb-3'>
                    <div className='bold f-20'>Sugerencias</div>
                </div>

                <div className='d-flex'>
                    
                    <div className='pe-2'>
                        <div className='nu-icon nu-icon--big'>
                            <HiLightBulb size={28} color='#FF812A'></HiLightBulb>
                        </div>
                    </div>
                    
                    <div className='col'>
                        <Renderif isTrue={state.suggestions?.length === 0}>
                        <div className='mb-2 p-2 bg-gray r-10 f-14'>Sin sugerencias</div>
                        </Renderif>
                        
                        {state.suggestions?.map((suggestion) => {
                            return (
                                <div className='mb-2 p-2 bg-gray r-10 f-14'>{suggestion.comment}</div>
                            )
                        })}
                    </div>
                </div>
            

                

               


            </Renderif>

            <Renderif isTrue={state.modalToNoPresent}>
                <div className='nu-modal'>
                    <div className='nu-modal-body' onClick={(evt)=>{evt.stopPropagation()}}>
                        <div className='d-flex'>
                            <div className='col'></div>
                            <div className='nu-icon nu-icon--big cursor-pointer' onClick={()=>{
                                setState({...state, modalToNoPresent:false})
                            }}>
                                <IoCloseCircle color={darkBlue} size={30}></IoCloseCircle>
                            </div>
                        </div>
                        <div className='col d-flex flex-column justify-content-center align-items-center'>
                            <img width="100px" src='/assets/tick-circle-warning.png'></img>
                            <div className='f-26 bold text-center'>Profesional no presentado</div>
                            <div className='f-14 text-center'>Indicaste que el profesional no se presentó en este turno, coméntanos más.</div>
                            <div className='f-14 text-center'>Este turno no se incluirá en tu facturación.</div>
                        </div>

                        <div className='py-1'></div>

                        <textarea rows={5} value={state.commentForNoPresent} className='nu-input mt-2 f-14' placeholder='Añade un comentario...' onChange={(evt)=>{
                            setState({...state, commentForNoPresent:evt.currentTarget.value})
                        }}></textarea>

                        <div className='d-flex align-items-center border-bottom py-3'>
                            <div className='col d-flex justify-content-center'>
                                <div className='nu_btn nu_btn--gray' style={{ paddingInline: '2rem', paddingBlock: '0.6rem' }} onClick={(evt) => {
                                    evt.stopPropagation()
                                    setState({...state, modalToNoPresent:false})
                                }}>
                                    <div className='nu_btn-text f-16'>Cancelar</div>
                                </div>
                            </div>
                            <div className='col d-flex justify-content-center'>
                                <div className='nu_btn nu_btn--overlay' style={{ paddingInline: '3rem', paddingBlock: '0.6rem' }} onClick={(evt) => {
                                    evt.stopPropagation()
                                    onUnattendedShift(item.id)
                                }}>
                                    <div className='nu_btn-text f-16'>Aceptar</div>
                                    <div className='nu_btn-overlay'></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Renderif>
            

      </div>
      </>
    )
}
  
export function NuTurnoItemAdmin({onClick, status, open = false, rated = false, onDelete = ()=>{}, onDuplicate=()=>{}, onUnassign = ()=>{}, item, checkAttendedRequest = ()=>{}}) {
    


    const navigate = useNavigate();
    
    const [state, setState] = useState({
        open:false,
        status,
        user:null, 
        loading:false,
        rated:false,
        rate:1, 
        rateComment:'',
        suggestion:'',
        suggestions:[],
        profesionals:null,
        modalToNoPresent:false
    })

    const {user} = useContext(AuthContext)
    const {notify} = useContext(NotificationContext)

    const {
        renderTitle, 
        getCenter, 
        getAssistanceUnit,
        getProfessionalField,
        getSpecialization,
        getCenterType,
        getProfesion
    } = useContext(AdminContext)
    
    useEffect(()=>{
    
        const init = async() => {

            if(item.publicateTo !== null){
                let _user = await Service('GET', 'shift/getById/'+item.id)
                let _profesionals = null
                if (_user?.data?.shift?.publicateTo) {
                    _profesionals = await Service('GET', 'shift/users/'+_user?.data?.shift?.id) 
                    _profesionals = _profesionals?.data?.list
                    state.profesionals = _profesionals
                }
                setState({...state, user:_user?.data?.user, open:false, profesionals: _profesionals})
            }else{
                setState({...state, user:null, open:false})
            }


            if(item.idUser !== null){
                let _user = await Service('GET', 'shift/getById/'+item.id)
                let _profesionals = null
                if (_user?.data?.shift?.publicateTo) {
                    _profesionals = await Service('GET', 'shift/users/'+_user?.data?.shift?.id) 
                    _profesionals = _profesionals?.data?.list
                    state.profesionals = _profesionals
                }
                setState({...state, user:_user?.data?.user, open:false, profesionals: _profesionals})
            }else{
                setState({...state, user:null, open:false})
            }
           
        }
        init();
        return () => {}
    },[item])


    const getClassType = (status, type) => {

        if(status === '4'){
            return 'gray'
        }

        if(checkCompleted()){
            return 'darkblue'
        }
        if(status === '1'){
            return 'darkgreen'
        }
        if(status === '3'){
            if(checkAttendedRequest(
                item.idAssistanceUnit,
                item.center.id,
                item.center.idOrganization,
                item.publicateTo,
                item.idUser
            )){
                return 'gray'
            } else {
                return 'darkgreen'
            }
        }
        if(type === '0'){
            return 'orange'
        }
        if(type === '1'){
            return 'lightblue'
        } 
    }

    const checkCompleted = () => {
    
        let _today = new Date();
        var end_turno = parseInt(item?.initialHour.split(':')[0])
        var today = new Date(Date.UTC(_today.getFullYear(), _today.getMonth(), _today.getDate(), 0,0,0)).getTime()
        let _date = new Date(item?.date).getTime()

        if(today > _date){
            return true
        }

        if(today < _date){
            return false;
        }
        if(today === _date){
            if(_today.getHours() > end_turno){
                return true
            }
            return false;
        }
    }

    const onSendSuggestion = async () => {
        setState({...state, loading:true})
        let body = {
            idShift:item.id,
            comment:state.suggestion
        }
        let _suggestion = await Service('POST', 'suggestion/create', body)


        if(_suggestion.status === 201){
            notify({title:'¡Listo!', message:'Sugerencia enviada.', type:'success'})
            state.suggestions.push({
                comment:state.suggestion
            })
           
        }else{
            notify({title:'Error!', message:_suggestion?.response?.data?.error, type:'error'})
        }  
        setState({...state, suggestion:'', loading:false})
    }

    const onBlockProfesional = async () => {
        setState({...state, loading:true})
        let body = {
            type:'0',
            idCenter:item.idCenter,
            idUser:item.idUser
        }
        let _block = await Service('POST', 'restriction/create', body);
    
        if(_block.status === 201){
            notify({title:'¡Listo!', message:'Profesional bloqueado exitosamente', type:'success'})
        }else{
            if(_block.response.status === 400){
                notify({title:'Profesional bloqueado!', message:'Ya has bloqueado este profesional.', type:'error'})  
            }else{
                notify({title:'Error!', message:_block?.response?.data?.error, type:'error'})
            }
        }
    
        setState({...state, loading:false})
        
    }
    const onRateTurno = async() => {

    
    
        setState({...state, loading:true})

        let body = {
            type: '0',
            //Propiedad type es un enum, solo acepta los valores "0" y "1"
            //"0" - Valoración del centro al nurse/tcae/student
            //"1" - Valoración del nurse/tcae/student al centro
            idCenter: item.idCenter,
            idUser: item.idUser,
            idShift: item.id,
            stars: state.rate, //Min 0, Max 5
            comment: state.rateComment,
            
        }

   
        let _rate = await Service('POST', 'rating/create', body)
        if(_rate.status === 201){
            notify({title:'¡Listo!', message:'Valoración enviada.', type:'success'})
            setState({...state, loading:false, rated:true})
        }else{
         
            notify({title:'Error!', message:_rate.response.data.error, type:'error'})
            setState({...state, loading:false})
        }  
        
      
   

    }
    const onCompleteTurno = async() => {

        let body = {
            type: '0',
            idCenter: item.idCenter,
            idUser: item.idUser,
            idShift: item.id,
            stars: '5',
            comment: state.rateComment,
            
        }
        let _rate = await Service('POST', 'rating/create', body)
        console.log(_rate)

        let body_recepction = {
            idCenter:item.idCenter,
            idUser:item.idUser,
        }
        if(item.idAssistanceUnit !== null){
            body_recepction.idAssistanceUnit = item.idAssistanceUnit
        }
        let _completeReception = await Service('POST', 'completedReception/create',body_recepction)

        let _experience = {
            idProfessional: item.idUser,
            idCenter:item.idCenter,
            idAssistanceUnit:item.idAssistanceUnit,
            idProfessionalField: item.idProfessionalField
        }
        
        if(item.idAssistanceUnit !== null){
            let _createExperience = await Service('POST', 'experience',_experience)
        }

        
       
        if(_completeReception.status === 200){
            notify({title:'¡Listo!', message:'Se completado turno de acogida.', type:'success'})
            setState({...state, loading:false, completed:true})
            return;
        }
                 
        notify({title:'Error!', message:'Ocurrio un erorr al completar turno de acogida.', type:'error'})
        setState({...state, loading:false})



    }

    const onUnattendedShift = async (idShift) => {
        setState({...state, loading:true})
        let body = {
            idUser: null,
            idCenter: null,
            idOrganization: null,
            idShift: idShift,
            user_comment: null,
            clinic_comment: state.commentForNoPresent
        }
        // console.log("body: ", body);
        
        let _unattended = await Service('PUT', `shift/unattendance/${idShift}`, {});
        // console.log(_unattended)
        if (_unattended.status === 200) {
            let _incident = await Service('POST', 'incident/create', body);
            // console.log(_incident)
            if (_incident.status === 200) {
                notify({title:'¡Listo!', message:'Turno marcado con profesional no presentado', type:'success'})
                item.status = '4'
            } else {
                notify({title:'Error!', message:'Ocurrio un error al marcar este turno con profesional no presentado.', type:'error'})
            }
        } else {
            notify({title:'Error!', message:'Ocurrio un error al marcar este turno con profesional no presentado.', type:'error'})
        }
        setState({...state, loading:false, commentForNoPresent: "", modalToNoPresent: false})
    }
   

    return (
        <>
         {/* <Renderif isTrue={state.loading}>
            <NuLoading></NuLoading>
        </Renderif> */}
       <div className={`
       
        nu-turno-item
        nu-turno-item--${getClassType(item?.status, item?.type)} 
     
       
       `}>
            <div className='d-flex'>
              
            <div>
                <div className='f-20 bold'>{renderTitle(item?.idCenter, item?.type)}</div>
                
                <Renderif isTrue={item.idAssistanceUnit !== null && item.idCenterType === 2}>
                    <div className='f-14 mt-1'><span className='bold'>Unidad asistencial</span>: {getAssistanceUnit(item.idAssistanceUnit)}</div>
                </Renderif>
                <Renderif isTrue={item.idCenterType !== 2}>
                    <div className='f-14 ps-1'><span className='bold'>Tipo de centro</span>: {getCenterType(item.idCenterType)}</div>
                </Renderif>
            </div>
            <div className='col'></div>
                <Renderif isTrue={item?.status === '4'}>
                    <div className='f-14 bold pe-3' style={{ color: 'gray' }}>Profesional no presentado</div>
                </Renderif>
                <Renderif isTrue={checkCompleted() && item.idUser !== null && item?.status !== '4'}>
                    <div className='f-14 bold text-darkblue pe-3'>Completado</div>
                </Renderif>

                <Renderif isTrue={checkCompleted() && item.idUser === null}>
                    <div className='f-14 bold text-darkblue pe-3'>Expirado</div>
                </Renderif>

                <Renderif isTrue={item?.status === '3'}>
                    {checkAttendedRequest(item.idAssistanceUnit, item.center.id, item.center.idOrganization, item.publicateTo, item.idUser) ? (
                        <div className="f-14 bold text-gray pe-3">Solicitud atendida</div>
                    ) : (
                        <div className="f-14 bold text-orange pe-3">Pendiente de atender solicitud</div>
                    )}
                </Renderif>

                <Renderif isTrue={!checkCompleted() && item?.status === '0'}>
                    <div className='d-flex align-items-center pe-2'>
                        <div className='f-14 bold text-gray pe-3'>Pendiente de asignar</div>
                        <div className='nu-icon nu-icon--mid cursor-pointer' onClick={()=>{
                            // navigate('/clinic/publicar/', { state: item } )
                            onDelete()
                        }}>
                            <FaTrash size={16} color='gray'></FaTrash>
                        </div>
                    </div>
                </Renderif>
                 
                <Renderif isTrue={!checkCompleted() && item.status === '1'}>
                    <div className='f-14 bold text-darkgreen pe-3'>Asignado</div>
                </Renderif>
            </div>

            <div className='d-flex flex-column mt-3'>


                <div className='d-flex flex-row flex-wrap'>
                    <div className='d-flex align-items-center pe-3'>
                            <div className='nu-icon nu-icon--mid'>
                                <img width="70%" src='/assets/calendar_solid.svg'></img>
                            </div>
                    
                            <div className='f-12 bold ellipsis'>{UTCDateParser(new Date(item.date).toUTCString(), true)}</div>
                    </div>

                    <div className='d-flex align-items-center  pe-3'>
                            <div className='nu-icon nu-icon--mid'>
                                <img width="70%" src='/assets/calendar_clock_solid.svg'></img>
                            </div>
                            <div className='f-12 bold ellipsis'>{timeOfDay(item.timeOfDay)}</div>
                    </div>

                    <div className='d-flex align-items-center pe-3'>
                            <div className='nu-icon nu-icon--mid'>
                                <img width="70%" src='/assets/clock_solid.svg'></img>
                            </div>
                            <div className='f-12 bold ellipsis'>{item.initialHour} hrs.</div>
                    </div>

                    <div className='d-flex align-items-center  pe-3'>
                            <div className='nu-icon nu-icon--mid'>
                                <img width="70%" src='/assets/euro_symbol_solid.svg'></img>
                            </div>
                            <div className='f-12 bold ellipsis'>{item.remuneration}</div>
                    </div>
                </div>
                {/* <div className='col'></div> */}
                {/* Botón Revisar */}

                <div className='d-flex mt-2'>

                    <div style={{ marginRight: '1.5em', display: 'flex', alignItems: 'center' }}>
                        <div className='f-12 bold ellipsis'>Publicado para:</div>
                        <div className='col-12 col-lg-4 d-flex flex-wrap pt-1 ps-1'>
                            {item?.onlyFavorites ? (
                                <span className='f-12 bold pb-1 ps-2'>Favoritos</span>
                            ) : (!item.onlyFavorites && !item.onlyMyTeam && JSON.parse(item?.publicateTo || "[]").length > 0) ? (
                                <span className='f-12 bold pb-1 ps-2'>Profesionales</span>
                            ) : (item.onlyMyTeam && !item.onlyFavorites) ? (
                                <span className='f-12 bold pb-1 ps-2'>Mi equipo</span>
                            ) : (
                                <span className='f-12 bold pb-1 ps-2'>Todos</span>
                            )}
                        </div>
                    </div>
                    {state.user && (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div className='f-12 bold ellipsis'>Profesional:
                                <span className='f-12 bold text-darkblue cursor-pointer' onClick={()=>{
                                    navigate(`/admin/profesional/${item.idUser}`)
                                }}>&nbsp;{' ' + state.user?.name}</span>
                            </div>
                        </div>
                    )}

                    <div className='col'></div>
                   

                    <div className='me-2'>
                        <div className='nu_btn nu_btn--primary' id="btnRevisar" onClick={async ()=>{

                                          
                            console.log(item)
                    

                            if(state.open){
                                state.open = false;
                                setState({...state})   
                                return;
                            }

                            state.open = true;
                            if(state.user === null){
                                state.loading = true
                            }
                        

                    
                            setState({...state, loading:true});
                            if(item.idUser !== null){
                                let  _rated = await Service('GET', `rating/checkIfExist?type=0&idCenter=${item?.idCenter}&idUser=${item?.idUser}&idShift=${item?.id}`)
                                console.log(_rated)
                                state.rated = _rated.data.exist;
                              
                            
                            }


                            let _suggestions = await Service('GET', `suggestion/getAll?idShift=${item.id}`);
                    
                            state.suggestions = _suggestions.data.list;
                    


                            if(item?.type === '0'){
                            
                                let _completeReceptions = await Service('GET', `completedReception/getAll`)
                                let reception
                                if(item.idCenterType === 2){
                                    reception = _completeReceptions.data.list.filter((reception) => reception.idUser === item.idUser && reception.idCenter === item.idCenter && reception.idAssistanceUnit === item.idAssistanceUnit);
                                }else{
                                    reception = _completeReceptions.data.list.filter((reception) => reception.idUser === item.idUser && reception.idCenter === item.idCenter); 
                                }

        
                                if(reception.length > 0){
                                    state.completed = true;
                                    
                                }else{
                                    state.completed = false;
                                    
                                }
                            }
                        
                            let _user = await Service('GET', 'shift/getById/'+item.id)
                            state.user = _user.data.user
                        

                            setState({...state, loading:false})
                        
                            }}>
                            <div className='nu-icon nu-icon--mid'>
                                <IoIosSearch size={18}></IoIosSearch>
                            </div>
                            <div className='nu_btn-text'>Revisar</div>
                        </div>
                    </div>
                    
                    {/* Botón Duplicar */}
                    <div className='me-2'>
                        <div className='nu_btn nu_btn--primary' onClick={() => {
                            onDuplicate(item)
                        }}>
                            <div className='nu-icon nu-icon--mid'>
                                <GoDuplicate size={18}></GoDuplicate>
                            </div>
                            <div className='nu_btn-text'>Duplicar</div>
                        </div>
                    </div>


                    <Renderif isTrue={item.idUser === null}>
                        <div className='me-2'>
                            <div className='nu_btn nu_btn--primary align-items-center' onClick={()=>{
                                // setState({...state, open:!state.open})
                                navigate('/admin/publicar/', { state: {turno:item} } )
                                // onDelete()
                                }}>
                                <div className='nu-icon nu-icon--mid'>
                                <FaEdit size={16}></FaEdit>
                                </div>
                                <div className='nu_btn-text'>Editar</div>
                            </div>
                        </div>
                    </Renderif>
                    <Renderif isTrue={item.status === '3' && !checkCompleted()}>
                        <div className='me-2'>
                            <div className='nu_btn nu_btn--orange align-items-center' onClick={()=>{
                                //   window.open(`${base_url}clinic/solicitudes/${item.id}`,'_blank')
                                //   navigate('')
                                navigate('/admin/solicitudes/'+item.id)

                            }}>
                                <div className='nu-icon nu-icon--mid'>
                                    <IoMdCheckboxOutline size={16}></IoMdCheckboxOutline>
                                </div>
                                <div className='nu_btn-text'>Ver solicitud</div>
                            </div>
                        </div>
                    </Renderif>
               </div>

             </div>

            
            <Renderif isTrue={state.open}>
                <div className='d-flex flex-wrap mt-5'>
                    <div className='col-12 col-md-5 px-md-2'>
                        <div className='f-20 bold'>Descripción</div>
                        <div className='mt-3'>
                        
                            <div className='f-14 mt-1'><span className='bold'>Tipo de centro</span>: {getCenterType(item.idCenterType)}</div>
                            <div className='f-14 mt-1'><span className='bold'>Día</span>: {UTCDateParser(new Date(item.date).toUTCString(), true)}</div>
                            <div className='f-14 mt-1'><span className='bold'>Horario de turno</span>: {timeOfDay(item.timeOfDay)}</div>
                            <div className='f-14 mt-1'><span className='bold'>Inicio de turno</span>: {item.initialHour}  hrs.</div>
                            <div className='f-14 mt-1'><span className='bold'>Fin de turno</span>: {item.finalHour}  hrs.</div>
                        </div>
                    </div>

                    <div className='col-12 col-md-7 mt-3 mt-md-0'>
                        <div className='f-20 bold'>Criterios de selección</div>
                        <div className='mt-3'>

                            <div className='f-14 mt-1'><span className='bold'>Profesión</span>: {getProfesion(item.profession)}</div>
                            {/* <Renderif isTrue={item.needsReception !== ""}>
                                <div className='f-14 mt-1'><span className='bold'>Necesita el profesional haber completado la acogida</span>: {needsReception(item.needsReception)}</div>
                            </Renderif> */}
                            <div className='f-14 mt-1'><span className='bold'>{item.needsReception === '1' || item.needsReception === '2' ? 'Con acogida previa' : 'Sin acogida previa'}</span></div>
                            <Renderif isTrue={item.idAssistanceUnit !== null}>
                                <div className='f-14 mt-1'><span className='bold'>Unidad asistencial</span>: {getAssistanceUnit(item.idAssistanceUnit)}</div>
                            </Renderif>

                            <div className='f-14 mt-1'><span className='bold'>Ámbito profesional</span>: {getProfessionalField(item.idProfessionalField)}</div>
                            <Renderif isTrue={item.idSpecialization !== null && item.profession === '0'}>
                                <div className='f-14 mt-1'><span className='bold'>Especialidades de Enfermería (EIR)</span>: {getSpecialization(item.idSpecialization)}</div>
                            </Renderif>
                            <div className='f-14 mt-1'><span className='bold'>Años de experiencia en la profesión</span>: {profesionalExperiencia(item.experience)}</div>

                            <Renderif isTrue={item.idCenterType === 2}>
                                <div className='f-14 mt-1'><span className='bold'>Experiencia en la Unidad asistencial</span>: {experiencia(item.assistanceUnitExperience)}</div>
                            </Renderif>
                            
                            <Renderif isTrue={item.idProfessionalField !== 1}>
                                <div className='f-14 mt-1'><span className='bold'>Experiencia en el ámbito profesional</span>: {experiencia(item.professionalFieldExperience)}</div>
                            </Renderif>

                            <div className='f-14 mt-1'><span className='bold'>Remuneración por turno</span>: € {item.remuneration}</div>

                            <Renderif isTrue={item.idCenterType === 2}>
                                <div className='f-14 mt-1'><span className='bold'>Formación en la unidad asistencial</span>: {formacion(item.assistanceUnitEducation === null ? '1' : item.assistanceUnitEducation === false ? '1' : '0')}</div>
                            </Renderif>
                            <Renderif isTrue={item.idProfessionalField !== 1}>
                                <div className='f-14 mt-1'><span className='bold'>Formación en el ámbito profesional</span>: {formacion(item.professionalFieldEducation === null ? '1' : item.professionalFieldEducation === false ? '1' : '0')}</div>
                            </Renderif>

                            
                        </div>
                    </div>

                    <Renderif isTrue={item.onlyFavorites || (!item?.onlyMyTeam && !item?.onlyFavorites && item?.publicateTo?.length)}>
                        <div className='col-12 col-md-12 px-md-2 pb-2'>
                            <div className='f-16 bold'>Publicado para:
                                {state.profesionals?.map((profesional, index) => (
                                    <span
                                        className='f-14 bold text-darkblue cursor-pointer'
                                        key={index}
                                        onClick={() => {
                                            navigate(`/admin/profesional/${profesional.idUser}`);
                                        }}
                                    >
                                        &nbsp;{profesional.name}&nbsp;
                                    </span>
                                ))}
                            </div>
                        </div>
                    </Renderif>
                    <div className='col-12 col-md-5 px-md-2'>
                        <div className='f-20 bold'>Descripción del turno</div>
                        <div className='mt-3'>
                        <Renderif isTrue={item.description}>
                            <div className='f-14'>{item.description}</div>
                        </Renderif>
                        <Renderif isTrue={!item.description}>
                            <div className='f-14'>Este turno no tiene descripción.</div>
                        </Renderif>
                        </div>
                    </div>
                </div>

                

                <Renderif isTrue={checkCompleted() && parseInt(item?.status) > 0}>

                    <hr className='mt-3'></hr>
                    <Renderif isTrue={state.loading}>
                        <div className='d-flex justify-content-center align-items-center w-100'>
                            <div className="spinner-border text-secondary"></div>
                        </div>
                    </Renderif>
                    <Renderif isTrue={!state.loading}>
                        <div className='d-flex mt-2'>
                            <div className='col px-2'>
                                <div className='f-20 bold'>Turno asignado por</div>
                                <div className='mt-3'>
                                
                                    <div className='d-flex fit-content bg-white r-10 p-3'>
                                        <div className='avatar'></div>
                                        <div className='ps-2'>

                                            <div className='f-18 bold ps-2'>{state.user?.name}</div>
                                            <div className='d-flex align-items-center'>

                                                <div className='nu-icon nu-icon--mid'>
                                                    <IoMdStar color='#FFB054' size={24}></IoMdStar>
                                                </div>

                                                <div className='f-14 bold'>
                                                    
                                                    <Renderif isTrue={state.user?.stars === null}>
                                                        Sin Valoraciones
                                                    </Renderif>
                                                    <Renderif isTrue={state.user?.stars !== null}>
                                                        {parseFloat(state.user?.stars).toFixed(1)}
                                                    </Renderif>
                                                </div>
                                            
                                            </div>


                                            <div className='nu_btn nu_btn--primary mt-4 w-100' onClick={()=>{
                                                navigate(`/admin/profesional/${item.idUser}`)
                                            }}>
                                                <div className='nu-icon nu-icon--mid me-1'>
                                                    <MdSearch size={24}></MdSearch>
                                                </div>
                                                <div className='nu_btn-text'>Ver perfil</div>
                                            </div>
                                            <div className='nu_btn nu_btn--danger mt-2 w-100' onClick={()=>{
                                                onBlockProfesional()
                                            }}>
                                                <div className='nu-icon nu-icon--mid me-1'>
                                                    <RiCloseCircleLine size={20}></RiCloseCircleLine>
                                                </div>
                                                <div className='nu_btn-text'>Bloquear profesional</div>
                                            </div>

                                            <Renderif isTrue={item.type === "0" && state.completed !== true && item.status !== '4'}>
                                                <div className='mt-2'>
                                                    <div className='nu_btn nu_btn--gray' style={{ paddingInline: '1rem', paddingBlock: '0.6rem' }} onClick={(evt) => {
                                                        evt.stopPropagation()
                                                        if (item?.status === "4") {
                                                            notify({title:'¡Advertencia!', message:'Este turno ya fue marcado con profesional no presentado.', type:'error'})
                                                        } else {
                                                            setState({...state, modalToNoPresent:true})
                                                        }
                                                    }}>
                                                        <div className='nu_btn-text'>Profesional no presentado</div>
                                                    </div>
                                                </div>
                                            </Renderif>

                                            <Renderif isTrue={item.type === "1" && state.rated !== true && item.status !== '4'}>
                                                <div className='mt-2'>
                                                    <div className='nu_btn nu_btn--gray' style={{ paddingInline: '1rem', paddingBlock: '0.6rem' }} onClick={(evt) => {
                                                        evt.stopPropagation()
                                                        if (item?.status === "4") {
                                                            notify({title:'¡Advertencia!', message:'Este turno ya fue marcado con profesional no presentado.', type:'error'})
                                                        } else {
                                                            setState({...state, modalToNoPresent:true})
                                                        }
                                                    }}>
                                                        <div className='nu_btn-text'>Profesional no presentado</div>
                                                    </div>
                                                </div>
                                            </Renderif>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='col '>

                                <Renderif isTrue={item.type === "0" && item.status !== '4'}>
                                    <div className='f-18 bold'>Evaluar turno de Acogida en Centro Sanitario</div>
                                    <Renderif isTrue={state.rated === true }>
                                        <div className='f-16 bold text-darkblue'>Valoración enviada</div>
                                    </Renderif>

                                    <Renderif isTrue={state.rated !== true }>
                                        <CheckBox checked={state.completedSelected} onChange={(evt)=>{
                                                setState({...state, completedSelected:evt.value})
                                        }} text="Completada"></CheckBox>
                                        {/* <CheckBox checked={state.completedSelected === false} onChange={(evt)=>{
                                                setState({...state, completedSelected:!evt.value})
                                        }} text="Seguir mostrando turnos de acogida"></CheckBox> */}
                                        <textarea rows={5} className='nu-input mt-3 f-14' onChange={(evt)=>{
                                                            setState({...state, rateComment:evt.currentTarget.value})
                                            }}></textarea>
                                        <div className={`nu_btn nu_btn--primary mt-1`} onClick={(evt) => {
                                                
                                                onCompleteTurno()
                                            }}>
                                                <div className='nu_btn-text'>Enviar</div>
                                        </div>
                                    </Renderif>

                                </Renderif>

                                
                                <Renderif isTrue={item.type === "1" && item.status !== '4'}>
                                    <div className='f-18 bold'>Evaluar turno de Cobertura en Centro Sanitario</div>

                                    <Renderif isTrue={state.rated === true }>
                                        <div className='f-16 bold text-darkblue'>Valoración enviada</div>
                                    </Renderif>

                                    <Renderif isTrue={state.rated !== true}>
                                        <div className='mt-3'>
                                            <NuRating value={0} onChange={(value)=> {
                                                setState({...state, rate:value})
                                            }}/>
                                            <textarea rows={5} className='nu-input mt-3 f-14' onChange={(evt)=>{
                                                            setState({...state, rateComment:evt.currentTarget.value})
                                            }}></textarea>
                                            <div className={`nu_btn nu_btn--primary mt-1`} onClick={(evt) => {
                                          
                                                onRateTurno()
                                            }}>
                                                <div className='nu_btn-text'>Enviar</div>
                                            </div>
                                        </div>
                                    </Renderif>                                
                                </Renderif>
                                
                            </div>


                        </div>
                    </Renderif>
                </Renderif>
          
                <Renderif isTrue={item.status === '1' && !checkCompleted()}>
                    <div className='d-flex flex-wrap mt-2'>
                        <div className='col-12 col-md-5 ps-md-2'>
                            <div className='f-20 bold'>Turno asignado por</div>
                        </div>
                        <div className='col-12 col-md-7'>
                        
                            <div className='d-flex bg-white r-10 p-3 '>
                                <Renderif isTrue={state.loading}>
                                    <div className='d-flex justify-content-center align-items-center w-100'>
                                        <div className="spinner-border text-secondary"></div>
                                    </div>
                                </Renderif>

                                <Renderif isTrue={!state.loading}>

                                    <div className='d-flex flex-wrap align-items-start'>
                                    
                                        <div className='col-12 col-md d-flex pe-2'>
                                            <div className='avatar'></div>
                                     
                                    
                                            <div className='ps-1'>

                                                <div className='f-18 bold ps-2'>{state.user?.name}</div>
                                                

                                                <div className='d-flex align-items-center '>

                                                    <div className='nu-icon nu-icon--mid'>
                                                        <IoMdStar color='#FFB054' size={24}></IoMdStar>
                                                    </div>
                                                    <div className='f-14 bold'>
                                                        <Renderif isTrue={state.user?.stars === null}>
                                                            Sin Valoraciones
                                                        </Renderif>
                                                        <Renderif isTrue={state.user?.stars !== null}>
                                                            {parseFloat(state.user?.stars).toFixed(1)}
                                                        </Renderif>
                                                        
                                                    </div>

                                                    <div className='col'></div>

                                                    
                                                
                                                </div>

                                                
                                                

                                            </div>
                                        </div>

                                            
                                        <div className='d-flex flex-column'>
                                            <div className='nu_btn nu_btn--primary mt-3 mt-md-0' onClick={()=>{
                                                    navigate(`/admin/profesional/${item.idUser}`)
                                                }}>
                                                <div className='nu-icon nu-icon--mid me-1'>
                                                    <MdSearch size={24}></MdSearch>
                                                </div>
                                                <div className='nu_btn-text'>Ver perfil</div>
                                            </div>

                                            <div className='mt-2'>
                                                <div className='nu_btn nu_btn--primary w-100' onClick={() => {
                                                    onUnassign(item)
                                                }}>
                                                    <div className='nu-icon nu-icon--mid'>
                                                        <MdOutlinePersonOff size={18}></MdOutlinePersonOff>
                                                    </div>
                                                    <div className='nu_btn-text'>Desasignar</div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </Renderif>
                            </div>
                          
                        </div>

                       
                    </div>
                </Renderif>

                

                <Renderif isTrue={item.status === '0' && item.idUser === null && !checkCompleted()}>
                    <hr className='mt-5'></hr>

                    <Renderif isTrue={state.loading}>
                        <div className='d-flex justify-content-center align-items-center w-100'>
                            <div className="spinner-border text-secondary"></div>
                        </div>
                    </Renderif>

                    <Renderif isTrue={!state.loading}>
                        <div className='d-flex flex-wrap bordr'>
                            <div className='col-12 col-md-5'></div>
                            <div className='col-12 col-md-7'>
                            <div className='mt-0'>
                                <div className='bold f-20'>Sugerencia</div>
                                <textarea rows={3} value={state.suggestion} className='nu-input mt-3 f-14' onChange={(evt)=>{
                                                setState({...state, suggestion:evt.currentTarget.value})
                                }}></textarea>
                                <div className={`nu_btn nu_btn--primary mt-1`} onClick={(evt) => {
                                    // console.log(item)
                                    onSendSuggestion()
                                }}>
                                    <div className='nu_btn-text'>Enviar</div>
                                </div>
                            </div>

                            </div>
                        </div>
                    </Renderif>

                </Renderif>
       

                <hr className='mt-3'></hr>

                <div className='py-2'></div>
                <div className='px-1 mb-3'>
                    <div className='bold f-20'>Sugerencias</div>
                </div>

                <div className='d-flex'>
                    
                    <div className='pe-2'>
                        <div className='nu-icon nu-icon--big'>
                            <HiLightBulb size={28} color='#FF812A'></HiLightBulb>
                        </div>
                    </div>
                    
                    <div className='col'>
                        <Renderif isTrue={state.suggestions?.length === 0}>
                        <div className='mb-2 p-2 bg-gray r-10 f-14'>Sin sugerencias</div>
                        </Renderif>
                        
                        {state.suggestions?.map((suggestion) => {
                            return (
                                <div className='mb-2 p-2 bg-gray r-10 f-14'>{suggestion.comment}</div>
                            )
                        })}
                    </div>
                </div>


            </Renderif>

      </div>
      <Renderif isTrue={state.modalToNoPresent}>
                <div className='nu-modal'>
                    <div className='nu-modal-body' onClick={(evt)=>{evt.stopPropagation()}}>
                        <div className='d-flex'>
                            <div className='col'></div>
                            <div className='nu-icon nu-icon--big cursor-pointer' onClick={()=>{
                                setState({...state, modalToNoPresent:false})
                            }}>
                                <IoCloseCircle color={darkBlue} size={30}></IoCloseCircle>
                            </div>
                        </div>
                        <div className='col d-flex flex-column justify-content-center align-items-center'>
                            <img width="100px" src='/assets/tick-circle-warning.png'></img>
                            <div className='f-26 bold text-center'>Profesional no presentado</div>
                            <div className='f-14 text-center'>Indicaste que el profesional no se presentó en este turno, coméntanos más.</div>
                            <div className='f-14 text-center'>Este turno no se incluirá en tu facturación.</div>
                        </div>

                        <div className='py-1'></div>

                        <textarea rows={5} value={state.commentForNoPresent} className='nu-input mt-2 f-14' placeholder='Añade un comentario...' onChange={(evt)=>{
                            setState({...state, commentForNoPresent:evt.currentTarget.value})
                        }}></textarea>

                        <div className='d-flex align-items-center border-bottom py-3'>
                            <div className='col d-flex justify-content-center'>
                                <div className='nu_btn nu_btn--gray' style={{ paddingInline: '2rem', paddingBlock: '0.6rem' }} onClick={(evt) => {
                                    evt.stopPropagation()
                                    setState({...state, modalToNoPresent:false})
                                }}>
                                    <div className='nu_btn-text f-16'>Cancelar</div>
                                </div>
                            </div>
                            <div className='col d-flex justify-content-center'>
                                <div className='nu_btn nu_btn--overlay' style={{ paddingInline: '3rem', paddingBlock: '0.6rem' }} onClick={(evt) => {
                                    evt.stopPropagation()
                                    onUnattendedShift(item.id)
                                }}>
                                    <div className='nu_btn-text f-16'>Aceptar</div>
                                    <div className='nu_btn-overlay'></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Renderif>
      </>
    )
}
  