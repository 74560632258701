import React, { useContext, useEffect, useState } from 'react'
import { IoMdClose, IoMdStar } from "react-icons/io";
import { MdCheck, MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from "react-icons/md";
import { SlArrowLeft, SlArrowRight } from "react-icons/sl";
import NuCalendar from '../../components/nu_calendar';
import { FaStar } from "react-icons/fa";
import { IoIosSearch } from "react-icons/io";
import { MdOutlinePersonOutline, MdOutlinePersonOff } from "react-icons/md";
import { ClinicSideMenu, ProfesionalSideMenu } from '../../components/nu_sidemenu';
import NuSelect from '../../components/nu_select';
import { BsSearch, BsThreeDotsVertical } from 'react-icons/bs';
import { Service } from '../../services/api';
import { AuthContext } from '../../context/auth_context';
import { useNavigate } from 'react-router-dom';
export default function ResumenClinicalView() {

    const {user_data, user} = useContext(AuthContext);
  
    const navigate = useNavigate()
    const [state, setState] = useState({
        show_menu:false,

        assigned:0,
        unassigned:0,
        rated:0,
        bloqueos:0,
        tcaes:0,
        nurses:0,
        cobertura:0,
        acogida:0,
        turnos:0
       
    })

    useEffect(()=>{
        const init = async() => {
            let _turnos = await Service('GET', 'shift/getAll?idOrganization='+user_data?.id)
            let _turnos_rated = await Service('GET', 'shift/getAll?idOrganization='+user_data?.id+'&rated=0')
            let _bloqueos = await Service('GET', 'restriction/getAll?idOrganization='+user_data?.id)
        

            let _tcae_count = await Service('GET', `user/getProfessionals?page=1&pageCount=1&userType=3`) 
            let _nurse_count = await Service('GET', `user/getProfessionals?page=1&pageCount=1&userType=1`) 

            

    
     
                
            setState({
                ...state,
                turnos:_turnos.data.list.length,
                assigned:_turnos.data.list.filter((item) => item.idUser !== null && !checkCompleted(item)).length,
                unassigned:_turnos.data.list.filter((item) => item.idUser === null && !checkCompleted(item)).length,
                acogida:_turnos_rated.data.list.filter((item) => item.type === '0').length,
                cobertura:_turnos_rated.data.list.filter((item) => item.type === '1').length,
                bloqueos:_bloqueos.data.list.length,
                nurses: _nurse_count.data.total,
                tcaes: _tcae_count.data.total
                
      
            })
        }
        init()
        
        setState({...state})
        return () => {}
    },[])

    const checkCompleted = (item) => {
    
        let _today = new Date();
        var end_turno = parseInt(item?.initialHour.split(':')[0])
        var today = new Date(Date.UTC(_today.getFullYear(), _today.getMonth(), _today.getDate(), 0,0,0)).getTime()
        let _date = new Date(item?.date).getTime()

        if(today > _date){
            return true
        }
        if(today < _date){
            return false;
        }
        if(today === _date){
            if(_today.getHours() > end_turno){
                return true
            }
            return false;
        }
    }

  return (
    <div className='container-fluid overflow-hidden d-flex h-100'>


        <ClinicSideMenu onClose={()=>{setState({...state, show_menu:false})}} show={state.show_menu} page='resumen' />
   


        <div className='container-fluid overflow-auto hide-scroll col  mx-auto'>

            <div className='bg-white sticky-top pt-1 pt-md-3 px-1 px-md-3'>
     

                <div className='nu-snack'>
                <div>
                        <div className='f-22 text-white bold'>Hola, {user?.name}</div>
                        <div className='d-flex align-items-center'>
                            <div className='nu-icon nu-icon--mid'>
                                <IoMdStar color='#FFB054' size={24}></IoMdStar>
                            </div>

                            {/* <div className='f-16 text-white'>5.0 (0 reseñas)</div> */}
                            <div className='f-16 text-white'>5.0 (0 reseñas)</div>

                        </div>
                    
                </div>
                <div className='col'></div>
                    <div className='avatar avatar--mid' style={{backgroundImage: `url(${user?.picture})`}}></div>
                    <div className='nu-snack-overlay'></div>
                    <div className='ms-2 d-block d-sm-none'>
                        <div className='nu-icon nu-icon--mid cursor-pointer' onClick={()=>{
                            setState({...state, show_menu:true})
                        }}>
                            <BsThreeDotsVertical size={50} color='white'></BsThreeDotsVertical>
                        </div>
                    </div>
                </div>
                       
            </div>

            <div className='container-fluid container-xl mx-auto px-3 px-md-5 py-3'>

                <div className='f-24 bold'>Resumen de actividad</div>


    

                <div className='d-flex flex-wrap mt-md-4'>
                    
                    <div className='col-12 col-md-6'>

                        <div className='d-flex justify-content-center align-items-center bg-skyblue shadow-sm r-10 py-4 mt-3 mt-md-0 me-md-4'>
                            <div className='f-16 bold'>Asignados</div>

                            <div className="pie no-round" style={{"--p":`${state.assigned * 100 / (state.assigned + state.unassigned)}`,"--c":'#00943A'}}>{state.assigned}</div>


                            {/* <div className='nu-sumary nu-sumary--green'>
                                <div className='f-24 bold'>{state.assigned}</div>
                            </div> */}
                        </div>
                        
                    </div>

                    <div className='col-12 col-md-6'>

                        <div className='d-flex justify-content-center align-items-center bg-skyblue shadow-sm r-10 py-4 mt-3 mt-md-0 ms-md-3'>
                            <div className='f-16 bold'>Pendientes de asignar</div>
                            <div className="pie no-round" style={{"--p":`${state.unassigned * 100 / (state.assigned + state.unassigned)}`,"--c":'#FF812A'}}>{state.unassigned}</div>
                            {/* <div className='nu-sumary nu-sumary--orange'>
                                <div className='f-24 bold'>{state.unassigned}</div>
                            </div> */}
                        </div>
                    </div>
                
                </div>

                <div className='mt-4'>
                    <div className='f-24 bold'>En este momento</div>
                    <div className='f-16'>Total de profesionales registrados que ven tus turnos publicados</div>
                </div>

                <div className='d-flex flex-wrap mt-md-4 '>

                    <div className='col-12 col-md-4'>

                        <div className='d-flex justify-content-md-center align-items-center shadow-sm r-10 py-4 me-md-3 h-100 ps-3 ps-md-0 mt-3 mt-md-0'>
                            
                            <div className='bg-darkblue p-4 r-10 mx-2'>
                                <div className='nu-icon nu-icon--big'>
                                    <MdOutlinePersonOutline size={32} color='white'></MdOutlinePersonOutline>
                                </div>
                            </div>
                            <div className='f-16 bold line-0'><span className="f-32">{state.nurses}</span><br></br>Enfermeras/os</div>
                        </div>
                        
                    </div>

                    <div className='col-12 col-md-4'>

                        <div className='d-flex justify-content-md-center align-items-center shadow-sm r-10 py-4 mx-md-3 h-100 ps-3 ps-md-0 mt-3 mt-md-0'>
                            <div className='bg-darkblue p-4 r-10 mx-2'>
                                <div className='nu-icon nu-icon--big'>
                                    <MdOutlinePersonOutline size={32} color='white'></MdOutlinePersonOutline>
                                </div>
                            </div>
                            <div className='f-16 bold line-0'><span className="f-32">{state.tcaes}</span><br></br>TCAE</div>
                        </div>

                    </div>
                
                    <div className='col-12 col-md-4'>

                         <div className='d-flex justify-content-md-center align-items-center shadow-sm r-10 py-4 ms-md-3 h-100 ps-3 ps-md-0 mt-3 mt-md-0'>

                            {/* <div className='bg-darkblue p-3 r-10 mx-2 d-flex flex-column justify-content-center align-items-center'> */}
                            <div className='bg-darkblue p-4 r-10 mx-2'>

                                <div className='nu-icon nu-icon--big'>
                                    <MdOutlinePersonOff size={32} color='white'></MdOutlinePersonOff>
                                </div>

                                <div className='d-flex'>
                                    {/* <div className='nu-icon'>
                                        <BsSearch size={10} color='white'></BsSearch>
                                    </div>
                                    <div className='f-12 bold text-white cursor-pointer' onClick={()=>{
                                        navigate('/clinic/usuarios')
                                    }}>Revisar</div> */}
                                </div>
                            </div>
                            <div className='f-16 bold line-0'><span className="f-32">{state.bloqueos}</span><br></br>Bloqueados</div>
                        </div>
                    </div>

                </div>

                <div className='mt-4'>
                    <div className='f-24 bold'>Mis valoraciones</div>
                </div>

                 <div className='d-flex flex-wrap mt-md-4 '>

                  

                    <div className='col-12 col-xl-6 mt-2 mt-xl-0'>

                        <div className='d-flex justify-content-center align-items-center bg-gray shadow-sm r-10 py-4 me-xl-3 h-100'>
                            
                            <div className='bg-orange p-4 r-10 mx-3'>
                                <div className='f-28 text-white bold px-2 line-0'>{state.cobertura}</div>
                            </div>

                            <div>
                                <div className='f-16 bold'>Valoraciones de cobertura <br></br> pendientes a realizar.</div>
                                <div className='f-16 mt-1'>Recuerda que tu valoración es importante para hacer más <br></br> precisos los perfiles que se asignan a los turnos.</div>
                            </div>
                        </div>

                    </div>
                
                    <div className='col-12 col-xl-6 mt-2 mt-xl-0'>
                        
                        <div className='d-flex justify-content-center align-items-center bg-gray shadow-sm r-10 py-4 ms-xl-3 h-100'>
                            
                            <div className='bg-orange p-4 r-10 mx-3'>
                                <div className='f-28 text-white bold px-2 line-0'>{state.acogida}</div>
                            </div>

                            <div>
                                <div className='f-16 bold'>Valoraciones de acogida <br></br>pendientes a realizar.</div>
                                <div className='f-16 mt-1'>Son obligatorias, si pasado una semana no has valorado, <br></br>la daremos por completada</div>
                            </div>
                        </div>
                       
                    </div>

                </div>

               

             


            </div>

       
      
        </div>

   
   
  
     
    </div>
  )
}
