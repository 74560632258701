import React, { useContext, useEffect, useState } from 'react'
import { IoMdClose, IoMdStar } from "react-icons/io";
import { MdCheck, MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from "react-icons/md";
import { SlArrowLeft, SlArrowRight } from "react-icons/sl";
import NuCalendar from '../../components/nu_calendar';
import { IoIosSearch } from "react-icons/io";
import { ClinicSideMenu, ProfesionalSideMenu } from '../../components/nu_sidemenu';
import {NuTurnoItem, NuTurnoItemClinic} from '../../components/nu_turno_item';
import { MdKeyboardArrowDown } from "react-icons/md";
import NuSelect from '../../components/nu_select';
import { useNavigate } from 'react-router-dom';
import { Service } from '../../services/api';
import { GlobalContext } from '../../context/global_context';
import { AuthContext } from '../../context/auth_context';
import { NotificationContext } from '../../context/notification_context';
import {NuCalendarClinic} from '../../components/nu_calendar';
import Renderif from '../../utils/renderif';
import { UTCDateObj } from '../../utils';
import NuLoading from '../../components/nu_loading';
import { BsThreeDotsVertical } from 'react-icons/bs';
export default function HomeClinicaView() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const url_shift = urlParams.get('shift')
    // console.log(url_shift)
    const navigate = useNavigate();
    const {assistanceUnit,professionalField, center} = useContext(GlobalContext)

    const {user_data, user} = useContext(AuthContext) 
    console.log(user)
    console.log(user_data)
    const {notify} = useContext(NotificationContext)


    const [state, setState] = useState({
        loading:false,
        show_turno_detail:false,
        show_filters:false,
        show_menu:false,
        daySelected: null,
        turnos:[],
        myProfesionals:[],
        date_filter:{
            init:'',
            end:''
        },
        filters:{
            center:'',
            profesion:'',
            horario:'',
            status:'',
            unidad:'',
            campo:'',
            valoracion:'',
            profesional:''
        },
        url_shift:url_shift
    })

    useEffect(()=>{
        if(state.daySelected !== null){
            state.url_shift = null
            setState({...state})
        }

    },[state.daySelected])



    useEffect(()=>{
        setState({...state, loading:true})
        init();
        return () => {}
    },[user, user_data])

    const init = async () => {

       

        let _turnos = await Service('GET', 'shift/getAll?idOrganization='+user_data?.id) 
        let _profesionals = await Service('GET', 'user/getMyProfessionalsAll')
        


        setState({
            ...state, 
            loading:false,
            myProfesionals:_profesionals.data.list,
            turnos: _turnos.data.list
        })
       
    };
    const checkCompleted = (item) => {
    
        let _today = new Date();
        var end_turno = parseInt(item?.initialHour.split(':')[0])
        var today = new Date(Date.UTC(_today.getFullYear(), _today.getMonth(), _today.getDate(), 0,0,0)).getTime()
        let _date = new Date(item?.date).getTime()

        if(today > _date){
            return true
        }
        if(today < _date){
            return false;
        }
        if(today === _date){
            if(_today.getHours() > end_turno){
                return true
            }
            return false;
        }
    }

    const filter = () => {

    
        if(state.url_shift){
            let _turnos = state.turnos.filter((turno) => turno.id === parseInt(state.url_shift))    
           
            return _turnos;
        }

        let _turnos;
    

        if(state.date_filter.init !== '' && state.date_filter.end !== ''){

          

            // let _init = UTCDateObj(new Date(Date.parse(state.date_filter.init)).toUTCString())
            // let _end = UTCDateObj(new Date(Date.parse(state.date_filter.end)).toUTCString())

            // _turnos = state.turnos.filter((item)=>{
            //     let _turno = UTCDateObj(new Date(item.date).toUTCString())
            //     if(
            //         (_turno.month >= _init.month && _turno.month <= _end.month ) &&
            //         (_turno.date >= _init.date && _turno.date <= _end.date ) && 
            //         (_turno.year >= _init.year && _turno.year <= _end.year )
                
            //     ){
            //         return true;
            //     }
            //     return false

            // })

            let _init = new Date(state.date_filter.init).getTime()
            let _end = new Date(state.date_filter.end).getTime()

            _turnos = state.turnos.filter((item) => {
                let _turno = new Date(item.date).getTime()
                return _turno >= _init && _turno <= _end
            }).sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())

            // return _turnos

        }else{
            
            if(state.daySelected === null ){
                
                // return state.turnos.sort(function(a,b){return a.date - b.date})
                _turnos = state.turnos.sort(function(a,b){return a.date - b.date}).filter((item)=>{
                    if(state.filters.status !== ''){
                        return true
                    }else{
                        if(checkCompleted(item)){
                            return false
                        }
                        return true
                    }
                
                });
               
            }else{
                
                let _date_selected = UTCDateObj(state.daySelected.toUTCString())

                _turnos = state.turnos.filter((item) => {

                    let _turno = UTCDateObj(new Date(item.date).toUTCString())
            
                    if(
                    _turno.month === _date_selected.month &&
                    _turno.date === _date_selected.date &&
                    _turno.year === _date_selected.year ){
                        return true;
                    }
                    return false;
                    
                }) 
            }
        }

        return filters(_turnos);
    }

    const filters = (_turnos) => {
      

        for (const [key, value] of Object.entries(state.filters)) {
        
            if(value !== ''){
                
               if(key === 'center'){
             
                _turnos =  _turnos.filter((item) => item.idCenter === parseInt(value))
               }
               if(key === 'profesion'){
                _turnos =  _turnos.filter((item) => item.profession === value)
               }
               if(key === 'horario'){
                _turnos =  _turnos.filter((item) => item.timeOfDay === value)
               }
               if(key === 'status'){
                _turnos =  _turnos.filter((item) => {


                    switch (value) {
                        case '0':
                            if(item.status === "0" && !checkCompleted(item)){
                                return true
                            }
                            return false

                        case '1':

                            if(item.status === "1" && !checkCompleted(item)){
                                return true
                            }
                            return false
                        

                        case '2':

                            if(checkCompleted(item) && item.idUser !== null && item?.status !== '4'){
                                return true
                            }
                            return false
                        
                        case '3':

                            if(item.status === "4"){
                                return true
                            }
                            return false
                        
                        case '4':

                            if(item.status === "3"){
                                return true
                            }
                            return false
                        
                        case '5':

                            if(checkCompleted(item) && item.idUser === null){
                                return true
                            }
                            return false
                        
                        default:
                            return true;
                    }

                   
                })
               }
               if(key === 'unidad'){
                _turnos =  _turnos.filter((item) => item.idAssistanceUnit === parseInt(value))
               }
               if(key === 'campo'){
                _turnos =  _turnos.filter((item) => item.idProfessionalField === parseInt(value))
               }
               if(key === 'valoracion'){
                if(value === "1"){
                    _turnos =  _turnos.filter((item) => item.rating.length === 0)
                }
                if(value === "2"){
                    _turnos =  _turnos.filter((item) => item.rating.length > 0)
                }
               }
               if(key === 'profesional'){
             
                _turnos = _turnos.filter((item) => item.idUser === parseInt(value))
               }
            }
        }
        return _turnos;

    }

    const onDeleteTurno = (item) => {

        notify({title:'Eliminar turno', message:'¿Deseas eliminar este turno?', type:'confirmation', onAccept: async (value) => {
            if(value){
                setState({...state, loading:true})
                let _delete = await Service('DELETE', `shift/delete/${item.id}`);

                if(_delete.status === 200){
                    state.turnos = state.turnos.filter((turno) => turno.id !== item.id);
                }else{
                    notify({title:'Eliminar turno', message:_delete?.response?.data?.error, type:'error'})
                }
                setState({...state, loading:false})
             
                
            }
        }})
        
     
    }
    const onDuplicateTurno = async (turno) => {

        setState({...state, loading:true})
  
        let _duplicate  = await Service('POST', `shift/duplicate/${turno.id}`)
    
        if(_duplicate.status === 201){
            notify({title:'¡Listo!', message:'Turno duplicado.', type:'success'})
        }else{
            notify({title:'Error!', message:_duplicate.response.data.error, type:'error'})
        } 
        init();
    
    };

    const checkAttendedRequest = (idAssistanceUnit, centerId, idOrganization, publicateTo, idUser) => {
        const isChecked = state.turnos.filter(
          (item) =>
            item.type === '0' &&
            item.idAssistanceUnit === idAssistanceUnit &&
            // item.center.id === centerId &&
            item.center.idOrganization === idOrganization &&
            (item.publicateTo === publicateTo || item.idUser === idUser || JSON.parse(item.publicateTo)?.includes(idUser))
        )
        return isChecked.length ? true : false
    }
   
  return (
    <>
    <Renderif isTrue={state.loading}>
        <NuLoading></NuLoading>
    </Renderif>
    <div className='container-fluid overflow-hidden d-flex h-100'>
      


        <ClinicSideMenu onClose={()=>{setState({...state, show_menu:false})}} show={state.show_menu} page={'dashboard'}/>
   


        <div className='container-fluid overflow-auto hide-scroll col  mx-auto'>

            <div className='bg-white sticky-top pt-1 pt-md-3 px-1 px-md-3'>
     

                <div className='nu-snack'>
                <div>
                        <div className='f-22 text-white bold'>Hola, {user?.name}</div>
                        <div className='d-flex align-items-center'>
                            <div className='nu-icon nu-icon--mid'>
                                <IoMdStar color='#FFB054' size={24}></IoMdStar>
                            </div>

                            {/* <div className='f-16 text-white'>5.0 (0 reseñas)</div> */}
                            <div className='f-16 text-white'>5.0 (0 reseñas)</div>

                        </div>
                    
                </div>
                <div className='col'></div>
                    <div className='avatar avatar--mid' style={{backgroundImage: `url(${user?.picture})`}}></div>
                    <div className='nu-snack-overlay'></div>
                    <div className='ms-2 d-block d-sm-none'>
                        <div className='nu-icon nu-icon--mid cursor-pointer' onClick={()=>{
                            setState({...state, show_menu:true})
                        }}>
                            <BsThreeDotsVertical size={50} color='white'></BsThreeDotsVertical>
                        </div>
                    </div>
                </div>
                       
            </div>


            <div className='container-fluid container-xxl mx-auto d-flex align-items-center px-2 px-md-3 mt-3 mb-3 mb-md-0 bg-white'>

                <div className=' d-flex align-items-center'>
        
                        <div className='f-22 bold'>Turnos publicados</div>
                
                 </div>

                 <div className='col'></div>
                 
                <div className='d-flex'>
                    <div className='col'></div>
                    <div className='nu_btn nu_btn--overlay' onClick={()=>{
                        navigate('/clinic/publicar')
                    }}>
                        <div className='nu_btn-text f-18'>Publicar nuevo turno</div>
                        <div className='nu_btn-overlay'></div>
                    </div>
                </div>
                
            </div>

            <div className='px-2 d-blck d-md-none'>
                    <div className='nu_btn nu_btn--overlay' onClick={()=>{
                        // navigate('/clinic/publicar')
                        setState({...state, show_filters: !state.show_filters})
                    }}>
                        <div className='nu_btn-text f-18'>Filtrar por</div>
                        <div className='nu_btn-overlay'></div>
                    </div>
            </div>
            
        

            <div className='container-fluid container-xl mx-auto  d-flex flex-wrap px-2 px-md-3 bg-white mt-md-3 '>

                    <div className='col-12 col-md-3 bg-sm-gray my-3 my-md-0 r-sm-10 px-3 px-md-0 d-none d-md-block'>

                        <div className='d-flex d-md-none align-items-center pt-3 border-b'>
                            <div className='col'></div>
                            <div className='f-16 bold'>de</div>
                            <div className='mx-2'>
                                <input type='date' value={state.date_filter.init} onChange={(evt)=>{
                                    
                                    state.date_filter.init = evt.currentTarget.value;
                                    setState({...state})
                                }} className='nu-input'  lang='es'></input>
                            </div>
                            <div className='f-16 bold'>a</div>
                            <div className='mx-2'>
                                <input type='date'  value={state.date_filter.end} onChange={(evt)=>{
                                    state.date_filter.end = evt.currentTarget.value;
                                    setState({...state})
                                }} className='nu-input'></input>
                            </div>
                            
                        </div>

                        {/* sticky-top-header */}
                        <div className='py-3'>
                            <NuCalendarClinic
                            turnos={state.url_shift ? state.turnos : filter()} onChange={(value)=>{

                                    state.date_filter.init = '';
                                    state.date_filter.end = '';
                                    if(value === null){
                                        state.daySelected = null
                                    }else{
                                
                                        state.daySelected = new Date(value);
                                    }
                                    setState({...state})

                            }}></NuCalendarClinic>
                            

                            <div className='mt-3'>
                                <div className='f-22 bold'>Filtrar por</div>
                                <NuSelect className={`mt-2 ${state.filters.center !== '' ? 'nu-select--active':''}`}  value={state.filters.center} onChange={(evt)=>{
                                    state.filters.center = evt.currentTarget.value;
                                    setState({...state})
                                }}>
                                    <option value="">Centro</option>
                                {
                                    center.filter((item)=> item.idOrganization === user_data?.id).map((item,i)=>{
                                        return (
                                            <option key={i} value={item.id}>{item.name}</option> 
                                        )
                                    })
                                }
                                </NuSelect>

                                <NuSelect className={`mt-2 ${state.filters.profesion !== '' ? 'nu-select--active':''}`}  value={state.filters.profesion} onChange={(evt)=>{
                                    state.filters.profesion = evt.currentTarget.value;
                                    setState({...state})
                                }}>
                                    <option value="">Profesión</option>
                                    <option value="1">TCAE</option>
                                    <option value="0">Enfermera</option>
                                </NuSelect>

                                <NuSelect className={`mt-2 ${state.filters.horario !== '' ? 'nu-select--active':''}`}  value={state.filters.horario} onChange={(evt)=>{
                                    state.filters.horario = evt.currentTarget.value;
                                    setState({...state})
                                }}>
                                    <option value="">Horario del turno</option>
                                    <option value="0">Mañana</option>
                                    <option value="2">Tarde</option>
                                    <option value="3">Noche</option>
                                    <option value="1">Día 12h</option>
                                    <option value="4">Guardia 24h</option>
                                </NuSelect>

                                <NuSelect className={`mt-2 ${state.filters.status !== '' ? 'nu-select--active':''}`} value={state.filters.status} onChange={(evt)=>{
                                    state.filters.status = evt.currentTarget.value;
                                    setState({...state})
                                }}>
                                    <option value="">Estado del turno</option>
                                    <option value="0">Sin asignar</option>
                                    <option value="1">Asignado</option>
                                    <option value="2">Completado</option>
                                    <option value="3">No presentados</option>
                                    <option value="4">Solicitados</option>
                                    <option value="5">Expirados</option>
                                </NuSelect>

                                <NuSelect className={`mt-2 ${state.filters.unidad !== '' ? 'nu-select--active':''}`} value={state.filters.unidad} onChange={(evt)=>{
                                    state.filters.unidad = evt.currentTarget.value;
                                    setState({...state})
                                }}>
                                    <option value="">Unidad Asistencial</option>
                                    {
                                        assistanceUnit.map((item, i)=>{
                                            return(
                                                <option key={i} value={item.id}>{item.description}</option> 
                                            )
                                        })
                                    }
                                </NuSelect>

                                <NuSelect className={`mt-2 ${state.filters.campo !== '' ? 'nu-select--active':''}`} value={state.filters.campo} onChange={(evt)=>{
                                    state.filters.campo = evt.currentTarget.value;
                                    setState({...state})
                                }}>
                                    <option value="">Ambito profesional</option>
                                    {
                                        professionalField.map((item, i)=>{
                                            return(
                                                <option key={i} value={item.id}>{item.description}</option> 
                                            )
                                        })
                                    }
                                </NuSelect>

                                <NuSelect className={`mt-2 ${state.filters.valoracion !== '' ? 'nu-select--active':''}`} value={state.filters.valoracion} onChange={(evt)=>{
                                    state.filters.valoracion = evt.currentTarget.value;
                                    setState({...state})
                                }}>
                                    <option value="">Valoración</option>
                                    <option value="1">Valoración pendiente</option>
                                    <option value="2">Valoración completada</option>
                                  
                                </NuSelect>

                                <NuSelect className={`mt-2 ${state.filters.profesional !== '' ? 'nu-select--active':''}`}  value={state.filters.profesional} onChange={(evt)=>{
                                    state.filters.profesional = evt.currentTarget.value;
                                    setState({...state})
                                }}>
                                       <option value="">Profesional</option>

                                    {
                                        state.myProfesionals.map((item, i)=>{
                                            return (
                                                <option key={i} value={item.id}>{item.name}</option>
                                            )
                                        })
                                    }


                                   
                                </NuSelect>

                            </div>
                        </div>
                    
                   

                    </div>
                <Renderif isTrue={state.show_filters}>
                    <div className='col-12 col-md-3 bg-sm-gray my-3 my-md-0 r-sm-10 px-3 px-md-0 d-block d-md-none'>

                        <div className='d-flex d-md-none align-items-center pt-3 border-b'>
                            <div className='col'></div>
                            <div className='f-16 bold'>de</div>
                            <div className='mx-2'>
                                <input type='date' value={state.date_filter.init} onChange={(evt)=>{
                                    
                                    state.date_filter.init = evt.currentTarget.value;
                                    setState({...state})
                                }} className='nu-input'  lang='es'></input>
                            </div>
                            <div className='f-16 bold'>a</div>
                            <div className='mx-2'>
                                <input type='date'  value={state.date_filter.end} onChange={(evt)=>{
                                    state.date_filter.end = evt.currentTarget.value;
                                    setState({...state})
                                }} className='nu-input'></input>
                            </div>
                            
                        </div>

                        {/* sticky-top-header */}
                        <div className='py-3'>
                            <NuCalendarClinic
                            turnos={state.turnos} onChange={(value)=>{

                                    state.date_filter.init = '';
                                    state.date_filter.end = '';
                                    if(value === null){
                                        state.daySelected = null
                                    }else{
                                
                                        state.daySelected = new Date(value);
                                    }
                                    setState({...state})

                            }}></NuCalendarClinic>
                            

                            <div className='mt-3'>
                                <div className='f-22 bold'>Filtrar por</div>
                                <NuSelect className={'mt-3'} value={state.filters.center} onChange={(evt)=>{
                                    state.filters.center = evt.currentTarget.value;
                                    setState({...state})
                                }}>
                                    <option value="">Centro</option>
                                {
                                    center.filter((item)=> item.idOrganization === user_data?.id).map((item,i)=>{
                                        return (
                                            <option key={i} value={item.id}>{item.name}</option> 
                                        )
                                    })
                                }
                                </NuSelect>

                                <NuSelect className={'mt-2'} value={state.filters.profesion} onChange={(evt)=>{
                                    state.filters.profesion = evt.currentTarget.value;
                                    setState({...state})
                                }}>
                                    <option value="">Profesión</option>
                                    <option value="1">TCAE</option>
                                    <option value="0">Enfermera</option>
                                </NuSelect>

                                <NuSelect className={'mt-2'} value={state.filters.horario} onChange={(evt)=>{
                                    state.filters.horario = evt.currentTarget.value;
                                    setState({...state})
                                }}>
                                    <option value="">Horario del turno</option>
                                    <option value="0">Mañana</option>
                                    <option value="2">Tarde</option>
                                    <option value="3">Noche</option>
                                    <option value="1">Día 12h</option>
                                    <option value="4">Guardia 24h</option>
                                </NuSelect>

                                <NuSelect className={'mt-2'} value={state.filters.status} onChange={(evt)=>{
                                    state.filters.status = evt.currentTarget.value;
                                    setState({...state})
                                }}>
                                    <option value="">Estado del turno</option>
                                    <option value="0">Sin asignar</option>
                                    <option value="1">Asignado</option>
                                    <option value="2">Completado</option>
                                </NuSelect>

                                <NuSelect className={'mt-2'} value={state.filters.unidad} onChange={(evt)=>{
                                    state.filters.unidad = evt.currentTarget.value;
                                    setState({...state})
                                }}>
                                    <option value="">Unidad Asistencial</option>
                                    {
                                        assistanceUnit.map((item, i)=>{
                                            return(
                                                <option key={i} value={item.id}>{item.description}</option> 
                                            )
                                        })
                                    }
                                </NuSelect>

                                <NuSelect className={'mt-2'} value={state.filters.campo} onChange={(evt)=>{
                                    state.filters.campo = evt.currentTarget.value;
                                    setState({...state})
                                }}>
                                    <option value="">Ambito profesional</option>
                                    {
                                        professionalField.map((item, i)=>{
                                            return(
                                                <option key={i} value={item.id}>{item.description}</option> 
                                            )
                                        })
                                    }
                                </NuSelect>

                                <NuSelect className={'mt-2'} value={state.filters.valoracion} onChange={(evt)=>{
                                    state.filters.valoracion = evt.currentTarget.value;
                                    setState({...state})
                                }}>
                                    <option value="">Valoración</option>
                                    <option value="1">Valoración pendiente</option>
                                    <option value="2">Valoración completada</option>
                                  
                                </NuSelect>

                            </div>
                        </div>
                    
                   

                    </div>
                </Renderif>


                
                <div className='col-12 col-md-9'>

                 

                    <div className='ps-md-3'>  

                        <div className='d-none d-md-flex align-items-center'>
                            <div className='f-16 bold'>Filtrar por fecha de</div>
                            <div className='mx-2'>
                                <input type='date' value={state.date_filter.init} onChange={(evt)=>{
                                 
                                    state.date_filter.init = evt.currentTarget.value;
                                    setState({...state})
                                }} className='nu-input'  lang='es'></input>
                            </div>
                            <div className='f-16 bold'>a</div>
                            <div className='mx-2'>
                                <input type='date'  value={state.date_filter.end} onChange={(evt)=>{
                                    state.date_filter.end = evt.currentTarget.value;
                                    setState({...state})
                                }} className='nu-input'></input>
                            </div>
                            
                        </div>

                        <Renderif isTrue={filter().length === 0}>
                            <div id='' className='bg-gray-00 r-10 py-2 px-0 px-md-5 d-flex mt-3 w-100 overflow-hidden'>

                                <div className='col-8 px-3'>
                                    <div className='bold f-14 text-white bg-darkblue p-2 text-center r-10 mt-5 px-5 position-relative'>
                                        
                                         <div className='bubble-arrow2'></div>¡Ups! Parece que no tienes turnos publicados.</div>
                                   
                                  <div className='d-flex justify-content-center'>
                                    <div className='d-flex mt-5'>
                                            
                                            <div className='nu_btn nu_btn--overlay' onClick={()=>{
                                                navigate('/clinic/publicar')
                                            }}>
                                                <div className='nu_btn-text f-16'>Publicar turno</div>
                                                <div className='nu_btn-overlay'></div>
                                            </div>
                                        </div>
                                  </div>
                                    
                                </div>

                                
                                <div className='col-4 pt-2'>
                                    <img width={200} src='/assets/nurse_empty.png'></img>
                                </div>
                            </div>
                        </Renderif>
                            

                        {
                            filter().map((item,i)=>{

                                return (
                                    <NuTurnoItemClinic 
                                    key={i} 
                                    item={item} 
                                    onDuplicate={(turno)=>{
                                        onDuplicateTurno(turno)
                                    }}
                                    onClick={()=>{
                                        setState({...state, show_turno_detail:true})
                                    }} 
                                    onDelete={()=>{
                                        onDeleteTurno(item)
                                    }}
                                    checkAttendedRequest={checkAttendedRequest}
                                    />
                                )
                            })
                        }

                        <div className='my-5 py-5'></div>
                    </div>

                   
                </div>

            </div>
      
        </div>

   
        <div className={`nu-turno-detail hide-scroll ${state.show_turno_detail ? 'nu-turno-detail--active':''}`} onClick={()=>{
            setState({...state, show_turno_detail:false })
        }}>
        
            <div className='nu-turno-content hide-scroll h-100' onClick={(evt)=>{evt.stopPropagation()}}>
                <div className='d-flex'>
                    <div>
                        <div className='f-22 bold'>
                            CUAP Sagrera
                        </div>
                        <div className='d-flex align-items-center'>
                            <div className='nu-icon nu-icon--mid'>
                                <IoMdStar color='#FFB054' size={24}></IoMdStar>
                            </div>
                            <div className='f-14 bold'>4.7 (70 Valoraciones)</div>
                        </div>
                    </div>
                    <div className='col'></div>
                    
                    <div className='nu-icon nu-icon--mid r-100 bg-darkblue m-1 cursor-pointer' onClick={()=>{
                        setState({...state, show_turno_detail:false})
                    }}>
                        <IoMdClose color='white' size={16}></IoMdClose>
                    </div>
                </div>

                <div className='mt-3'>
                    <div className='nu-turno-detail-image' style={{backgroundImage:'url(https://picsum.photos/300/120)'}}></div>
                    {/* <img  width="100%" height={150} className='r-10' src='https://picsum.photos/300/120'></img> */}
                </div>
                
                <div className='mt-3'>
                    <div className='nu_btn nu_btn--darkgreen w-100'>
                        <div className='nu_btn-text'>Asignarme el turno</div>
                    </div>
                </div>

                <div className='mt-3'>
                        <div className='f-22 bold'>
                        CUAP Sagrera
                    </div>
                </div>

                    <div className='d-flex mt-3'>

                        <div className='me-3'>
                            <div className='d-flex mt-0'>
                                <div className='nu-icon nu-icon--mid'>
                                    <img width="80%" src='/assets/calendar_solid.svg'></img>
                                </div>
                                <div className='ps-2'>
                                    <div className='f-14 bold'>Fecha</div>
                                    <div className='f-12'>24 de Febrero 2024</div>
                                </div>
                            </div>

                            <div className='d-flex mt-3'>
                                <div className='nu-icon nu-icon--mid'>
                                    <img width="80%" src='/assets/clock_solid.svg'></img>
                                </div>
                                <div className='ps-2'>
                                    <div className='f-14 bold'>Hora de inicio</div>
                                    <div className='f-12 '>12:00 AM</div>
                                    
                                </div>
                            </div>
                        </div>

                        <div className='ms-3'>

                            <div className='d-flex mt-0'>
                                <div className='nu-icon nu-icon--mid'>
                                    <img width="80%" src='/assets/calendar_clock_solid.svg'></img>
                                </div>
                                <div className='ps-2'>
                                    <div className='f-14  bold'>Turno de</div>
                                    <div className='f-12 '>Mañana</div>
                                </div>
                            </div>

                            <div className='d-flex mt-3'>
                                <div className='nu-icon nu-icon--mid'>
                                    <img width="65%" src='/assets/euro_symbol_solid.svg'></img>
                                </div>
                                <div className='ps-2'>
                                    <div className='f-14 bold'>500.00</div>
                                    <div className='f-12 opacity-0'>empty</div>
                                
                                </div>
                            </div>

                        </div>
                    </div>

                <div className='mt-5'>

            
                    <div className='mt-3'>
                        <div className='f-22 bold'> Detalle del  turno</div>
                        <div className='f-14'><span className='bold'>Organización</span>: Nombre</div>
                        <div className='f-14'><span className='bold'>Nombre del centro</span>: Hospital de Mataro</div>
                        <div className='f-14'><span className='bold'>Tipo de centro</span>: Hospital</div>
                        <div className='f-14'><span className='bold'>Inicio del turno</span>: 12:00 AM</div>
                        <div className='f-14'><span className='bold'>Fin del turno</span>: 19:00 PM</div>
                        <div className='f-14'><span className='bold'>Unidad Asistencial</span>: Unidad</div>
                        <div className='f-14'><span className='bold'>Ámbito Profesional</span>: Ambito</div>
                    </div>

                    <div className='mt-3'>
                        <div className='f-22 bold'>Información del centro</div>
                        <div className='f-14'><span className='bold'>Parking</span>: Texto Libre</div>
                        <div className='f-14'><span className='bold'>Dietas</span>: Texto Libre</div>
                        <div className='f-14'><span className='bold'>Uniforme</span>: Texto Libre</div>
                        <div className='f-14'><span className='bold'>Punto de encuentro</span>: Texto Libre</div>
                        <div className='f-14'><span className='bold'>Notas</span>: Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto. Lorem Ipsum ha sido el texto de relleno estándar de las industrias desde el año 1500.</div>

                    </div>

                </div>
            </div>
        </div>
  
     
    </div>
    </>
  )
}
