import React, { Children, useContext, useEffect, useState } from 'react'
import { IoMdClose, IoMdInformationCircleOutline, IoMdStar } from "react-icons/io";
import { MdCheck, MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from "react-icons/md";
import { SlArrowLeft, SlArrowRight } from "react-icons/sl";
import NuCalendar from '../../components/nu_calendar';
import { FaStar, FaTrash } from "react-icons/fa";
import { IoIosSearch } from "react-icons/io";
import { AdminSideMenu, ClinicSideMenu, ProfesionalSideMenu } from '../../components/nu_sidemenu';
import { MdOutlinePersonOff } from "react-icons/md";
import NuSelect from '../../components/nu_select';
import { IoClose, IoCloseCircle } from 'react-icons/io5';
import { AdminContext } from '../../context/admin_context';
import { useLocation, useNavigate } from 'react-router-dom';
import Renderif from '../../utils/renderif';
import NuLoading from '../../components/nu_loading';
import { Service } from '../../services/api';
import { NotificationContext } from '../../context/notification_context';
import { BiFilter, BiSearch, BiTrash } from 'react-icons/bi';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { darkBlue } from '../../styles/colors';
import { alphaOrder, restrictionReasons } from '../../utils';
import CheckBox from '../../components/nu_checkbox';
import { AuthContext } from '../../context/auth_context';
import { RxAvatar } from 'react-icons/rx';
import { HiBadgeCheck } from 'react-icons/hi';
export default function ProfesionalesAdminView() {
    const location = useLocation();
    let view = location?.state;


    const {professionals, loadProfessionals, getProfesionByUserType} = useContext(AdminContext);
    const {notify} = useContext(NotificationContext);
    const navigate = useNavigate();
    const [state, setState] = useState({
        filters:view !== null ? '3' : '',
        filterUserType: '',
        page:1,
        count:7,
        professionals:[],
        professionalsCount:0,
        search:'',
        reasons: [],
        modalToBlock: false,
        professionalToBlock: null,
        show_validate:false
    })

    const status = [
        'Pendientes de validar',
        'Perfil validado',
        'En revisión',
        'Bloqueados'
    ]

    const type = [
        '',
        'Enfermeria',
        '',
        'TCAE',
     
    ]
    



    // useEffect(()=>{
    //     let _aux_state = {
    //         filters: state.filters,
    //         filterUserType: state.filterUserType,
    //         page:state.page,
    //         search:state.search
    //     }
    //     localStorage.setItem('profesionals_state', JSON.stringify(_aux_state))

    // },[state])


    const save_state = () =>{
        let _aux_state = {
            filters: state.filters,
            filterUserType: state.filterUserType,
            page:state.page,
            search:state.search
        }
        localStorage.setItem('profesionals_state', JSON.stringify(_aux_state))
    }




    useEffect(()=>{
        
        let _save_state = JSON.parse(localStorage.getItem('profesionals_state'))

        console.log(_save_state)

        if(_save_state !== null){
            state.filters = _save_state.filters
            state.filterUserType = _save_state.filterUserType
            state.page = _save_state.page
            state.search = _save_state.search
        }

        // const init = async () => {
        //     console.log('INIT')
        //     //loadProfessionals({page:1}
          
        //     setState({...state, loading:true})
        //     let _profesionals = await loadProfessionals({page:state.page, count:state.count, status:state.filters})
        //     state.professionals = _profesionals.data.list
        //     state.professionalsCount = _profesionals.data.total
        //     setState({...state, loading:false})
        // }
        init();
        return () => {}
    },[])

    // useEffect(()=>{

       
    //     init()
    // },[state.filters ])

    const init = async () => {
            
       
        setState({...state, loading:true})
        let _profesionals = await loadProfessionals({page:state.page, count:state.count, status:state.filters, search:state.search, type:state.filterUserType })
        state.professionals = _profesionals.data.list
        state.professionalsCount = _profesionals.data.total
        setState({...state, loading:false, page:state.page})
       
     

    }

    const loadPage = async (page) => {
        setState({...state, loading:true})
        let _profesionals = await loadProfessionals({page:state.page + page, count:state.count, status:state.filters, search:state.search, type:state.filterUserType})
        state.page = state.page + page
        setState({...state, 
            // page:state.page + page,
            loading:false, 
            professionals:_profesionals.data.list, 
            professionalsCount:_profesionals.data.total,
    
        })
         save_state()
    }

    

    const renderPagination = () => {


        let pages = Math.ceil(state.professionalsCount / state.count);
        let items = []
        for (let i = 1; i <= pages; i++) {
            items.push(i);
        }

        return (
            <div>
               <span className='bold'>{state.page}</span> de <span className='bold'>{items.length}</span>
            </div>
        )

        // items.reverse()

        return items.map((item) => {
           
            return (
                <div style={{width:32}} className={`f-16 bold text-dark px-2 cursor-pointer mb-1 r-10 text-center ${state.page === item ? 'bg-light':'' }`} onClick={async ()=>{
                    
                    setState({...state, loading:true})
                    let _profesionals = await loadProfessionals({page:item, count:state.count, status:state.filters})

                    setState({...state, 
                        page:item,
                        loading:false, 
                        professionals:_profesionals.data.list, 
                        professionalsCount:_profesionals.data.total,
                
                    })
                }}>{item}</div>
            )
        })
        
        


     

    }

    const onBlockProfesional = async  () => {
        if(!state.reasons.length) {
            notify({title:'¡Error!', message:'Por favor selecciona al menos un motivo.', type:'error'})
            return
          }

        const reasonsBlock = state.reasons.sort().map((item) => restrictionReasons[item])
        let body = {
            blocked_reason: JSON.stringify(reasonsBlock)
        }
        
        let _block = await Service ('PUT', `user/block/${state.professionalToBlock}`, body)
        if(_block.status === 201){
            notify({title:'¡Listo!', message:'Profesional bloqueado', type:'success'})

            setState({...state, loading:true})
            let _profesionals = await loadProfessionals({page:state.page, count:state.count, status:state.filters})
            state.professionals = _profesionals.data.list
            state.professionalsCount = _profesionals.data.total
            setState({...state, loading:false})


        }else{
            notify({title:'Erorr!', message:'No se ha podiado bloquear este profesional.', type:'error'})
        }
        setState({...state, reasons:[], modalToBlock:false, professionalToBlock:null})
    }

    const onUnblockProfesional = async  (idUser) => {
        let body = {
            idUser
        }
        let _block = await Service ('PUT', `user/unblock/${idUser}`)
        if(_block.status === 201){
            notify({title:'¡Listo!', message:'Profesional desbloqueado', type:'success'})

            setState({...state, loading:true})
            let _profesionals = await loadProfessionals({page:state.page, count:state.count, status:state.filters})
            state.professionals = _profesionals.data.list
            state.professionalsCount = _profesionals.data.total
            setState({...state, loading:false})


        }else{
            notify({title:'Erorr!', message:'No se ha podiado desbloquear este profesional.', type:'error'})
        }
    }
    const onDeleteUser = (user) => {
     

        notify({title:'Eliminar usuario', message:'¿Deseas eliminar este usuario?', type:'confirmation', onAccept: async (value) => {
            if(value){
                setState({...state, loading:true})
                let _delete = await Service('DELETE', `user/${user.id}`);
                console.log(_delete);
                init()
                // init();
                // state.turnos = state.turnos.filter((turno) => turno.id !== item.id);
                // setState({...state, loading:false})
            }
        }})
    }
    const onSearch = async () => {
       
            setState({...state, loading:true})
            let _profesionals = await loadProfessionals({page:1, count:state.count, status:state.filters, search:state.search, type:state.filterUserType })
            state.professionals = _profesionals.data.list
            state.professionalsCount = _profesionals.data.total
            state.page = 1
            setState({...state, loading:false})
            save_state()
            

    }

  return (
    <>
    <Renderif isTrue={state.loading}>
        <NuLoading></NuLoading>
    </Renderif>
    <div className='container-fluid overflow-hidden d-flex h-100'>

        <AdminSideMenu page='profesionales' />
   
        <div className='container-fluid overflow-auto hide-scroll col  mx-auto'>

            <div className='bg-white sticky-top pt-3 px-3'>
     

                <div className='nu-snack'>
                <div>
                    <div className='f-22 text-white bold'>Hola, Admin</div>
                </div>
                <div className='col'></div>
                    <div className='avatar avatar--mid' style={{backgroundImage: 'url(/assets/avatar.png)'}}></div>
                    <div className='nu-snack-overlay'></div>
                </div>
                       
            </div>

            <div className='container-fluid container-xl mx-auto p-3'>


          

                <div className='f-24  bold mt-2'>Profesionales</div>

                <div className='d-flex align-items-end mt-3'>

                    {/* <div className='f-16 bold'>Filtrar por: </div> */}

                    <div className='me-2'>
                        <div className='f-14'>Status</div>
                       <NuSelect value={state.filters} onChange={(evt)=>{
                      
                                  
                                state.filters = evt.currentTarget.value
                                setState({...state})
                      
                       }}>
                            <option value="">Todos</option>
                            <option value="0">Pendiente de validar</option>
                            <option value="2">En revisión</option>
                            <option value="1">Perfil Validado</option>
                            <option value="3">Bloqueados</option>
                            {/* <option value={"Profesional bloqueado"}>Profesional bloqueado</option> */}
                       </NuSelect>
                    </div>

                    <div className='me-2'>
                        <div className='f-14'>Tipo</div>
                       <NuSelect value={state.filterUserType} onChange={(evt)=>{
                      
                                  
                                state.filterUserType = evt.currentTarget.value
                                setState({...state})
                      
                       }}>
                            <option value="">Todos</option>
                            <option value="1">Enfermeria</option>
                            <option value="3">TCAE</option>
                  
                       </NuSelect>
                    </div>

                  
                    <div className='col-5'>
                        <div className='f-14'>Nombre</div>
                       <input className="nu-input--light p-2" value={state.search} placeholder='Busqueda por nombre. Ej. Roberto' onChange={(evt)=>{
                        setState({...state, search:evt.currentTarget.value})
                       }}></input>
                    </div>

                    

                    <div className='col d-flex'>
                       
                            <div className='nu_btn nu_btn--primary ms-2 py-2' onClick={()=>{
                                    // onDeleteUser(item)
                                    // state.page = 1
                                    onSearch()
                                    
                                }}>

                                <div className='nu-icon nu-icon--mid'>
                                    <BiSearch size={20}></BiSearch>
                                </div>

                                <div className='nu_btn-text '>Buscar</div>
                            </div>

                            <Renderif isTrue={state.search !== '' || state.filters !== '' || state.filterUserType !== ''}>
                                <div className='nu_btn ms-2 py-2' onClick={()=>{
                                        state.search = ''
                                        state.filters = ''
                                        state.filterUserType = ''
                                        // state.page = 1
                                        onSearch()
                                        
                                    }}>

                                    <div className='nu-icon nu-icon--mid'>
                                        <BiFilter size={20}></BiFilter>
                                    </div>

                                    <div className='nu_btn-text '>Limpiar Filtro</div>
                                </div>
                            </Renderif>
                            
                      
                    </div>
                


                    {/* <div className='col'></div> */}

                </div>
                
                {/* <div className='d-flex mt-2'>
                    <Renderif isTrue={state.filters !== ''}>
                        <div className='nu-tag'>
                            <div className='f-16'>{status[state.filters]}</div>
                            <div className='nu-icon nu-icon--mid cursor-pointer' onClick={()=>{
                                // setState({...state, filters:''})
                                state.filters = ''
                                onSearch();
                                }}>
                                <IoClose size={25}></IoClose>
                            </div>
                        </div>
                    </Renderif>
                    <Renderif isTrue={state.filterUserType !== ''}>
                        <div className='nu-tag'>
                            <div className='f-16'>{type[state.filterUserType]}</div>
                            <div className='nu-icon nu-icon--mid cursor-pointer' onClick={()=>{
                                state.filterUserType = ''
                                onSearch();
                            }}>
                                <IoClose size={25}></IoClose>
                            </div>
                        </div>
                    </Renderif>
                    <Renderif isTrue={state.search !== ''}>
                        <div className='nu-tag'>
                            <div className='f-16'>{state.search}</div>
                            <div className='nu-icon nu-icon--mid cursor-pointer' onClick={()=>{
                                state.search = ''
                                onSearch()
                            }}>
                                <IoClose size={25}></IoClose>
                            </div>
                        </div>
                    </Renderif>
                </div> */}

                {/* <div className='d-flex flex-wrap mt-3'>

                    {state.filters.map((item,i)=>{
                        return(
                            <div key={i} className='nu-tag'>
                                <div className='f-12 bold'>{item}</div>
                                <div className='nu-icon nu-icon--mid cursor-pointer' onClick={()=>{
                                    if(state.filters.includes(item)){

                                        
                                        state.filters = state.filters.filter((filter)=>filter !== item)
                          
                                        setState({...state})
                                    }
                                }}>
                                    <IoClose size={14}></IoClose>
                                </div>
                            </div>
                        )
                    })}


                </div> */}

                <div>
                    { 
                        // professionals?.map((item, i)=>{
                        state.professionals?.map((item, i)=>{


                   
                            
                            return(
                                <div key={i} className='d-flex align-items-center border-bottom py-3'>
                            
                                   
                                    <div className='col'>
                                        
                                        <div className='d-flex  align-items-start'>
                                            <div className='avatar me-2' style={{backgroundImage: 'url(/assets/avatar.png)'}}></div>
                                            <div className=''>
                                                <Renderif isTrue={item?.name === undefined}>
                                                    <div className='f-14 bold'>Sin nombre</div>
                                                </Renderif>
                                                <div className='f-14 bold'>{item?.name}</div>
                                                <div className='f-12'>{getProfesionByUserType(item?.idUserType)}</div>
                                            </div>
                                        </div>

                        
                                    </div>
                                    <div className='col'>
                                        
                                        <div className='f-14 bold'>{
                                            item?.status === "0" ? 'Pendiente de validar' : 'Perfil Validado'
                                        }</div>
                                        <Renderif isTrue={item.documentationCompleted === true && item.status === '0'}>
                                            <div className='f-12 bold text-success'>En revisión</div>
                                        </Renderif>

                                    </div>
                                    <div className='col'>
                                        <div className='f-14 bold'>{item?.name === "Usuario eliminado" ? "-" : item?.email}</div>
                                    </div>
                                    <div className='col'>

                                        <div className='d-flex align-items-center'>
                                            <div className='nu-icon nu-icon--mid text-decoration-underline cursor-pointer' onClick={()=>{
                                                navigate(`/admin/valoraciones/${item.id}/0`)
                                            }}>
                                                <IoMdStar color='#FFB054' size={24}></IoMdStar>
                                            </div>
                                            <div className='f-12 bolder text-decoration-underline cursor-pointer' onClick={()=>{
                                                navigate(`/admin/valoraciones/${item.id}/0`)
                                            }}>{item.stars === null ? 'Sin Valoraciones (0)' : `${parseFloat(item.stars).toFixed(1) } (${item.ratings})`} </div>
                                       
                                            
                                        </div>
                                        
                                    </div>
                                    {/* <div className='pe-1'>
                                        <div className='nu_btn nu_btn--primary' onClick={()=>{
                                                
                                                navigate('/admin/profesional/'+item.id)
                                            }}>

                                            <div className='nu-icon nu-icon--mid'>
                                                <IoIosSearch size={20}></IoIosSearch>
                                            </div>

                                            <div className='nu_btn-text '>Ver perfil</div>
                                        </div>
                                    </div>
                                    <div className='px-1'>

                                        <div className='nu_btn nu_btn--primary' onClick={()=>{
                                                navigate('/admin/documentacion/'+item.id)
                                            }}>

                                            <div className='nu-icon nu-icon--mid'>
                                                <IoIosSearch size={20}></IoIosSearch>
                                            </div>

                                            <div className='nu_btn-text '>Ver documentación</div>
                                        </div>
                                    </div>
                                    <div className='ps-1'>

                                        <Renderif isTrue={item.blocked === false}>
                                            <div className='nu_btn nu_btn--danger' onClick={()=>{
                                                    onBlockProfesional(item.id)
                                                }}>

                                                <div className='nu-icon nu-icon--mid'>
                                                    <MdOutlinePersonOff size={20}></MdOutlinePersonOff>
                                                </div>

                                                <div className='nu_btn-text ps-2'>Bloquear</div>
                                            </div>
                                        </Renderif>

                                        <Renderif isTrue={item.blocked === true}>
                                            <div className='nu_btn nu_btn--light' onClick={()=>{
                                              onUnblockProfesional(item.id)
                                                }}>

                                                <div className='nu-icon nu-icon--mid'>
                                                    <MdOutlinePersonOff size={20}></MdOutlinePersonOff>
                                                </div>

                                                <div className='nu_btn-text ps-2'>Desbloquear</div>
                                            </div>
                                        </Renderif>

                                        

                                      

                                    </div>
                                    <div className='px-1'>

                                        <div className='nu_btn nu_btn--danger' onClick={()=>{
                                                onDeleteUser(item)
                                            }}>

                                            <div className='nu-icon nu-icon--mid'>
                                                <BiTrash size={20}></BiTrash>
                                            </div>

                                            <div className='nu_btn-text '>Eliminar</div>
                                        </div>
                                    </div> */}
                                    <div>
                                        
                                        <NuDropDown item={item} onSelect={(key)=>{
                                            switch (key) {
                                                    case 0:
                                                        navigate('/admin/profesional/'+item.id)
                                                        break;
                                                    case 1:
                                                        setState({...state, show_validate:true, profesiona_selected:item})
                                                        break;
                                                    case 2:
                                                        // onBlockProfesional(item.id)
                                                        setState({...state, modalToBlock:true, professionalToBlock: item.id})
                                                        break;
                                                    case 3:
                                                        onUnblockProfesional(item.id)
                                                        break;
                                                    case 4:
                                                        onDeleteUser(item)
                                                        break;
                                                    case 5:
                                                        break;
                                            
                                                default:
                                                    break;
                                            }
                                        }}></NuDropDown>
                                    </div>


                                    <Renderif isTrue={state.modalToBlock}>
                                        <div className='nu-modal'>
                                            <div className='nu-modal-body' onClick={(evt)=>{evt.stopPropagation()}}>
                                                <div className='d-flex'>
                                                    <div className='col'></div>
                                                    <div className='nu-icon nu-icon--big cursor-pointer' onClick={()=>{
                                                        setState({...state, modalToBlock:false})
                                                    }}>
                                                        <IoCloseCircle color={darkBlue} size={30}></IoCloseCircle>
                                                    </div>
                                                </div>
                                                <div className='col d-flex flex-column justify-content-center align-items-center'>
                                                    <img width="100px" src='/assets/tick-circle-warning.png'></img>
                                                    <div className='f-26 bold text-center'>Marca los motivos por los cuales este<br></br>profesional dejará de ver tus turnos publicados: </div>
                                                </div>

                                                <div className='py-1'></div>

                                                {restrictionReasons.map((item, i)=>{
                                                    return (
                                                        <div key={i} className='px-3'>
                                                            <CheckBox text={item.label} value={item.type} onChange={(evt)=>{
                                                                if(evt.value){
                                                                setState({...state, reasons: [...state.reasons, item.type]})
                                                                } else {
                                                                state.reasons = state.reasons.filter((reason) => reason!==item.type)
                                                                }
                                                            }}/>
                                                        </div>
                                                    )
                                                })}

                                                <div className='pt-2 f-14 text-center text-red'>Debes elegir al menos 1 motivo para poder bloquear</div>

                                                <div className='d-flex align-items-center border-bottom py-3'>
                                                    <div className='col d-flex justify-content-center'>
                                                        <div className='nu_btn nu_btn--gray' style={{ paddingInline: '2rem', paddingBlock: '0.6rem' }} onClick={(evt) => {
                                                            evt.stopPropagation()
                                                            setState({...state, modalToBlock:false})
                                                        }}>
                                                            <div className='nu_btn-text f-16'>Cancelar</div>
                                                        </div>
                                                    </div>
                                                    <div className='col d-flex justify-content-center'>
                                                        <div className='nu_btn nu_btn--overlay' style={{ paddingInline: '3rem', paddingBlock: '0.6rem' }} onClick={(evt) => {
                                                            evt.stopPropagation()
                                                            onBlockProfesional()
                                                        }}>
                                                            <div className='nu_btn-text f-16'>Aceptar</div>
                                                            <div className='nu_btn-overlay'></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Renderif>

                                </div>
                            )})

                    }

                </div>

                <div className='d-flex justify-content-center my-5'>
                    <div className='nu-icon nu-icon--mid cursor-pointer' onClick={()=>{
                          loadPage(-1)
                    }}>
                        <SlArrowLeft></SlArrowLeft>
                    </div>
                    <div className='d-flex flex-wrap px-3'>
                        {renderPagination()}
                    </div>
                    <div className='nu-icon nu-icon--mid cursor-pointer' onClick={async ()=>{

                        loadPage(1)

                        
                        // setState({...state, page:state.page + 1})
                    }}>
                        <SlArrowRight></SlArrowRight>
                    </div>
                </div>
             
                

               

              
          
            </div>

       
      
        </div>

        <SideDetailReception 
        profesionals={state.professionals}
        active={state.show_validate} 
        profesional={state.profesiona_selected} 
        centers={state.centers}
        onClose={()=>{
            setState({...state, show_validate:false})
        }} 
        onValidate={(values)=>{
                    // onCompleteReception(values)
            }} ></SideDetailReception>

    </div>
    </>
  )
}

function SideDetailReception({active, profesional, profesionals, centers = [], onValidate = () => {}, onClose=() => {}}) {
    const {getUserType, center, centerType,assistanceUnit, getAssistanceUnit, professionalField, getProfessionalField} = useContext(AdminContext)
    const {user_data} =  useContext(AuthContext);
    const {notify} = useContext(NotificationContext)
    const [state, setState] = useState({
        
        centers:[],
        center_selected:'',
        assistenceunit_selected:'',
        centertype_selected:'',
        centertype_selected_object:{},
        professionalField_selected:'',
        loading:false,
        receptions:[],
        experience:[],
        hoverInfo: false,
        hoverInfoExperience: false,
        modalToAccept: false,
        modalConfirm: false,
    })

    useEffect(() => {
        
       
        if(profesional?.id !== null){
            receptions();
        }
   
    
       return () => {}

    },[profesional, active])

 
    const receptions = async (confirmed = false) => {
            setState({...state, loading:true})
            let my_centers = center
            my_centers = my_centers.filter((item)=> item.id !== 78 && item.id !== 86 && item.id !== 87 && item.id !== 88)
            state.centers = my_centers

            let _experiencia = await Service('GET', `experience?idProfessional=${profesional?.id}`)
            console.log(_experiencia.data)

            setState({...state, loading:false, experience:_experiencia.data.list})
    }
    const onCreateExperience = async (values) =>{
      
       
       
        if(state.center_selected === ''){
            notify({title:'Validar experiencia', message:'Selecciona un centro.', type:'error'}) 
            return;
        }
  
        if(state?.centertype_selected_object?.idCenterType === 2){
            if(state.assistenceunit_selected === ""){
                notify({title:'Validar experiencia', message:'Selecciona una unidad asistencial.', type:'error'})
                return
            }
        }
 
        let body = {
            idProfessional: parseInt(profesional.id),
            idCenter:parseInt(state.center_selected),
            idOrganization: state.centertype_selected_object?.idOrganization
        }
      
        
        if(state.professionalField_selected !== ""){
            body.idProfessionalField = parseInt(state.professionalField_selected)
        }else{
            body.idProfessionalField = 1
        }
      
        if(state?.centertype_selected_object?.idCenterType === 2){
            body.idAssistanceUnit = parseInt(state.assistenceunit_selected)
        }
        let findExperience = state.experience.find((ex) => ex.idAssistanceUnit === body.idAssistanceUnit && ex.idProfessionalField === body.idProfessionalField && ex.idCenter === body.idCenter)
   
        if(findExperience !== undefined){
            notify({title:'Añadir', message:'Experiencia ya existe.', type:'error'}) 
            return
        }
        
        let _completeReception = await Service('POST', 'experience',body)

        state.center_selected = ""
        state.centertype_selected_object = {}
        state.assistenceunit_selected = ""
        state.professionalField_selected = ""
        state.loading = false;
        
        if(_completeReception.status === 201){
            notify({title:'¡Listo!', message:'Experiencia creada exitosamente.', type:'success'})
            receptions();
            
            setState({...state})
            return;
        }        
        notify({title:'Error!', message:'Ocurrio un erorr al crear experiencia.', type:'error'})
        setState({...state})
    }
    const onDeleteExpetience = async (id) => {
        setState({...state, loading:true})
        let _onDeleteExperience = await Service('DELETE', `experience/${id}`)

        state.center_selected = ""
        state.centertype_selected_object = {}
        state.assistenceunit_selected = ""
        state.professionalField_selected = ""
        state.loading = false;
        
        if(_onDeleteExperience.status === 200){
            notify({title:'¡Listo!', message:'Experecia elimiada existosamente.', type:'success'})
            receptions();
            setState({...state})
            return;
        }
    }

    const confirmReception = async (values) => {
        setState({...state, loading:true})

        let body = {}
        body = {
            idCenter:parseInt(state.center_selected),
            idUser:parseInt(profesional.id)
        }
        if(values.centertype_selected === '2'){
            body.idAssistanceUnit = parseInt(state.assistenceunit_selected)
        }
        let _completeReception = await Service('POST', 'completedReception/create',body)
        let _experience = {
            idProfessional: parseInt(profesional.id),
            idCenter:parseInt(state.center_selected),
            idAssistanceUnit:parseInt(state.assistenceunit_selected)
        }
        if(values.centertype_selected === '2'){
            let _createExperience = await Service('POST', 'experience',_experience)
        }
        
        if(_completeReception.status === 200){
            // notify({title:'¡Listo!', message:'Se completado turno de acogida.', type:'success'})
            receptions(true);
            setState({...state, modalToAccept:false, modalConfirm:true})
            return;
        }
        setState({...state, loading:false})
    }

    const handleMouseEnter = () => {
        setState({...state, hoverInfo:true})
    }

    const handleClose = () => {
        setState({...state, hoverInfo:false})
    }
    
    const handleMouseEnterExperience = () => {
        setState({...state, hoverInfoExperience:true})
    }

    const handleCloseExperience = () => {
        setState({...state, hoverInfoExperience:false})
    }

    const hoverContentStyle = {
        position: 'absolute',
        top: '6.5rem',
        right: '1rem',
        backgroundColor: 'white',
        borderRadius: '10px',
        boxShadow: '0 6px 12px rgba(0, 0, 0, 0.2)',
        paddingTop: '20px',
        paddingBottom: '2px',
        paddingLeft: '25px',
        paddingRigth: '25px',
        zIndex: 1000,
        width: '22.5rem',
    };
      
    const closeButtonStyle = {
        position: 'absolute',
        top: '5px',
        right: '2px',
    };

    const paragraphStyle = {
        fontSize: '14px',
      };


  return (
    <div className={`nu-turno-detail hide-scroll ${active ? 'nu-turno-detail--active':''}`} onClick={()=>{
        // setState({...state, show_turno_detail:false })
        onClose()
    }}>
            <div className='nu-turno-content hide-scroll h-100' onClick={(evt)=>{evt.stopPropagation()}}>

                <div className='d-flex'>
                    <div className='col'></div>
                    
                    <div className='nu-icon nu-icon--mid r-100 bg-darkblue m-1 cursor-pointer' onClick={()=>{
                        // setState({...state, show_turno_detail:false})
                        onClose()
                    }}>
                        <IoMdClose color='white' size={16}></IoMdClose>
                    </div>
                </div>

                <div className=''>
                        <div className='d-flex  align-items-start b-b pb-3'>
                            {/* <div className='avatar me-2'></div> */}
                            <div className='nu-icon nu-icon--mid'>
                                <RxAvatar color='#0D78BF' size={30}></RxAvatar>
                            </div>
                            <div className=''>
                                <Renderif isTrue={profesional?.name === undefined}>
                                    <div className='f-16 bold'>Sin nombre</div>
                                </Renderif>
                                <div className='f-16 bold ps-2'>{profesional?.name}</div>

                                <div className='d-flex align-items-center ps-2'>
                       
                                        <div className='f-14 line-0'>{getUserType(profesional?.idUserType.toString())}</div>
                                        <div className='d-flex align-items-center ps-1'>
                                            <div className='nu-icon nu-icon--mid'>
                                                <IoMdStar color='#FFB054' size={20}></IoMdStar>
                                            </div>
                                            <div className='f-14 bolder line-0'>{profesional?.stars === null ? 'Sin Valoraciones' : parseFloat(profesional?.stars).toFixed(1)}</div>
                                            <div className='nu-icon nu-icon--mid' onMouseEnter={handleMouseEnter}>
                                                <IoMdInformationCircleOutline color='gray' size={18}></IoMdInformationCircleOutline>
                                            </div>
                                            {state.hoverInfo && (
                                                <div className='hover-content' style={hoverContentStyle}>
                                                    <div style={closeButtonStyle} className='nu-icon r-100 bg-darkblue m-1 cursor-pointer' onClick={handleClose}>
                                                        <IoMdClose color='white' size={16}></IoMdClose>
                                                    </div>
                                                    {/* <button onClick={handleClose} style={closeButtonStyle}>X</button> */}
                                                    <p className='pt-2' style={paragraphStyle}>Para valorar un profesional debe hacerse desde la valoración del turno.</p>
                                                </div>
                                            )}
                                        </div>
                                    
                                </div>
                            </div>
                        </div>
                </div>

                <div className='mt-3'>
                    <div className='d-flex align-items-center'>
                        <div className='f-18 bold text-darkblue'>Experiencia validada</div>
                        <div className='nu-icon nu-icon--mid' onMouseEnter={handleMouseEnterExperience}>
                            <IoMdInformationCircleOutline color='gray' size={18}></IoMdInformationCircleOutline>
                        </div>
                        {state.hoverInfoExperience && (
                            <div className='hover-content' style={hoverContentStyle}>
                                <div style={closeButtonStyle} className='nu-icon r-100 bg-darkblue m-1 cursor-pointer' onClick={handleCloseExperience}>
                                    <IoMdClose color='white' size={16}></IoMdClose>
                                </div>
                                <p className='pt-2' style={paragraphStyle}>
                                    Amplía las áreas de conocimiento del profesional para que pueda ver las publicaciones en las unidades y ámbitos donde tiene experiencia y aporta más valor.
                                </p>
                            </div>
                        )}
                        <div className='col'></div>
                        <Renderif isTrue={state.loading}>
                            <div className="spinner-border spinner--small text-darkblue"></div>
                        </Renderif>
                    </div>
                    <div className='mt-3'></div>
                    <Renderif isTrue={!state.loading}>
                    {
                        state.centers?.map((center, i) => {
                        
                            
                            if(state.experience?.filter((item)=> item.idCenter === center.id)?.length === 0) return;
                            return (
                                <div key={i} className="d-flex flex-column b-b pb-3 mt-2">
                                    <div className='d-flex'>
                                        <div className='col'>
                                            <div className='f-12 bold'>{center?.name}</div>
                                        </div>
                                        <div className='col'></div>
                                        <div className=''>
                                            <Renderif isTrue={state.experience?.filter((item)=> item.idCenter === center.id)?.length === 0}>
                                                <div className='f-12 bold text-gray'>Sin Experiencia</div>
                                            </Renderif>
                                        
                                        

                                        </div>
                                    </div>
                                    <div className='d-flex flex-column'>
                                    {

                                            state.experience?.filter((item)=> item.idCenter === center.id)?.map((item, j)=>{
                                                // if (item.idAssistanceUnit === null) return
                                             
                                                return (
                                                    <div className='d-flex mt-2 pt-3 b-t'>
                                                        <div className='d-flex flex-column'>

                                                          <Renderif isTrue={center?.idCenterType === 2}>
                                                            <div className='d-flex align-items-center'>
                                                                <div className='nu-icon nu-icon--mid'>
                                                                        <HiBadgeCheck color='#16B2DE' size={18}></HiBadgeCheck>
                                                                    </div>
                                                                    
                                                                    <Renderif isTrue={center?.idAssistanceUnit  !== null}>
                                                                        <div className='f-12 bold text-gray' key={j}> Unidad Asistencial: {getAssistanceUnit(item.idAssistanceUnit)}</div>
                                                                        
                                                                    </Renderif>
                                                            </div>
                                                          </Renderif>

                                                          <div className='d-flex align-items-center'>
                                                                <div className={`nu-icon nu-icon--mid`}>
                                                                    <HiBadgeCheck color='#16B2DE' size={18}></HiBadgeCheck>
                                                                </div>
                                                                
                                                                <Renderif isTrue={item.idProfessionalField !== null}>
                                                                    <div className='f-12 bold text-gray' key={j}> Ámbito Profesional: {getProfessionalField(item.idProfessionalField)}</div>
                                                                
                                                                </Renderif>
                                                                <Renderif isTrue={item.idProfessionalField === null}>
                                                                    <div className='f-12 bold text-gray' key={j}> Ámbito Profesional: No aplica</div>
                                                                
                                                                </Renderif>
                                                          </div>

                                                           
                                                      
                                                        </div>
                                                        <div className='col'></div>
                                                        <div className='nu-icon nu-icon--mid cursor-pointer' onClick={() => {
                                                            onDeleteExpetience(item.id)
                                                        }}>
                                                            <FaTrash color='gray' size={14}></FaTrash>
                                                        </div>

                                                        
                                                    </div>

                                                    
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            )
                        })
                    }
                    </Renderif>


                </div>

                <div className='mt-2'>

                    <div className='f-18 bold text-darkblue'>Añadir experiencia en:</div>
                    <div>
                            <div className='mt-2'>
                                <NuSelect value={state.center_selected} onChange={(evt)=>{
                                        // console.log("---> ", centers.find(item => item.id.toString() === evt.currentTarget.value))
                                        state.assistenceunit_selected = ""
                                        state.professionalField_selected = ""
                                        setState({
                                            ...state,
                                            center_selected:evt.currentTarget.value,
                                            centertype_selected_object: state.centers.find(item => item.id.toString() === evt.currentTarget.value)
                                        })
                                    }}>
                                    <option value="">Seleccionar centro</option>
                                    {
                                        state.centers.map((center, i)=>{
                                        
                                            return (
                                                <option value={center.id}>{center.name}</option>
                                            )
                                        })
                                    }
                                
                                </NuSelect>
                            </div>

                            {/* <div className='mt-2'>
                                <NuSelect value={state.centertype_selected} onChange={(evt)=>{
                                        setState({...state, centertype_selected:evt.currentTarget.value})
                                    }}>
                                    <option value="">Seleccionar tipo de centro</option>
                                    {alphaOrder(centerType, 'description').map((item,i)=>{
                                            if(item.id==1) return;
                                    
                                            return (
                                                <option key={i} value={item.id}>{item.description}</option> 
                                            )
                                        
                                    })}
                                </NuSelect>
                            </div> */}
                            
                            <Renderif isTrue={state?.centertype_selected_object?.idCenterType === 2}>
                               <div className='mt-2'>
                                    <NuSelect value={state.assistenceunit_selected} onChange={(evt)=>{
                                            setState({...state, assistenceunit_selected:evt.currentTarget.value})
                                        }}>
                                        <option value="">Seleccionar Unidad Asistencial</option>
                                        { alphaOrder(assistanceUnit, 'description').map((item,i)=>{
                                            if(item.id === 1) {return}
                                            if(item.id === 14) {return}
                                            if(item.id === 21) {return}
                                            
                                            return (
                                                <option key={i} value={item.id}>{item.description}</option> 
                                            )
                                      
                                    })}
                                    </NuSelect>
                               </div>
                            </Renderif>

                            <div className='mt-2'>
                                <NuSelect value={state.professionalField_selected} onChange={(evt)=>{
                                        setState({...state, professionalField_selected:evt.currentTarget.value})
                                    }}>
                                    <option value="">Selecciona ámbito profesional</option>
                                    <Renderif isTrue={state?.centertype_selected_object?.idCenterType === 2}>
                                        <option value="1">No aplica</option>
                                    </Renderif>
                                     {alphaOrder(professionalField, 'description').map((item,i)=>{
                                            if(item.id==1) return;
                                    
                                            return (
                                                <option key={i} value={item.id}>{item.description}</option> 
                                            )
                                        
                                    })}
                                </NuSelect>
                            </div>



                            <div className='d-flex mt-3'>

                                <div className='col'></div>

                                <div className='nu_btn nu_btn--orange' onClick={()=>{
                                    // setState({...state, show_validate:true, profesiona_selected:item})
                                    // onCompleteReception()
                                    onCreateExperience({
                                        center_selected:state.center_selected,
                                        assistenceunit_selected:state.assistenceunit_selected,
                                        centertype_selected:state.centertype_selected,
                                    })

                                    }}>

                                    {/* <div className='nu-icon nu-icon--mid me-1'>
                                        <HiBadgeCheck size={18}></HiBadgeCheck>
                                    </div> */}

                                    <div className='nu_btn-text '>Añadir</div>
                                </div>
                            </div>

                    </div>
                </div>


            </div>

            <div>

            </div>
            <Renderif isTrue={state.modalToAccept}>
                <div className='nu-modal'>
                    <div className='nu-modal-body' onClick={(evt)=>{evt.stopPropagation()}}>
                        <div className='d-flex'>
                            <div className='col'></div>
                            <div className='nu-icon nu-icon--big cursor-pointer' onClick={()=>{
                                setState({...state, modalToAccept:false})
                            }}>
                                <IoCloseCircle color={darkBlue} size={30}></IoCloseCircle>
                            </div>
                        </div>
                        <div className='col d-flex flex-column justify-content-center align-items-center'>
                            <img width="120px" src='/assets/tick-circle.png'></img>
                            <div className='f-30 bold text-center'>Acogida validada para<br></br>usuario {profesional?.name} </div>
                            <div className='pt-2 f-14 text-center'>Al validar una acogida para este profesional, podrá visualizar turnos<br></br>de cobertura en el centro y/o unidad que hayas seleccionado.</div>
                        </div>
                        <div className='d-flex align-items-center border-bottom py-3'>
                            <div className='col d-flex justify-content-center'>
                                <div className='nu_btn nu_btn--gray' style={{ paddingInline: '3rem', paddingBlock: '0.6rem' }} onClick={(evt) => {
                                    evt.stopPropagation()
                                    setState({...state, modalToAccept:false})
                                }}>
                                    <div className='nu_btn-text f-16'>Cancelar</div>
                                </div>
                            </div>
                            <div className='col d-flex justify-content-center'>
                                <div className='nu_btn nu_btn--orange' style={{ paddingInline: '3rem', paddingBlock: '0.6rem' }} onClick={(evt) => {
                                    evt.stopPropagation()
                                    confirmReception({
                                        center_selected:state.center_selected,
                                        assistenceunit_selected:state.assistenceunit_selected,
                                        centertype_selected:state.centertype_selected,
                                    })
                                }}>
                                    <div className='nu_btn-text f-16'>Sí, validar Acogida</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Renderif>
            <Renderif isTrue={state.modalConfirm}>
                <div className='nu-modal'>
                    <div className='nu-modal-body'>
                        <div className='d-flex'>
                            <div className='col'></div>
                            <div className='nu-icon nu-icon--big cursor-pointer' onClick={(evt)=>{
                                evt.stopPropagation()
                                setState({...state, modalToAccept: false, modalConfirm: false})
                            }}>
                                <IoCloseCircle color={darkBlue} size={30}></IoCloseCircle>
                            </div>
                        </div>
                        <div className='col d-flex flex-column justify-content-center align-items-center'>
                            <img width="120px" src='/assets/tick-circle.png'></img>
                            <div className='f-30 bold text-center'>Acogida validada para<br></br>usuario {profesional?.name} </div>
                        </div>
                    </div>
                </div>
            </Renderif>
    </div>
  )
}

function NuDropDown({item, onSelect = () => {}}) {
    
    const [state, setState] = useState({
        open:false
    })

    const _onSelect = (key) => {
        setState({...state, open:false})
        onSelect(key)
    }

    return (
        <>
        <Renderif isTrue={state.open}>
        <div className='dropdown-overlay' onClick={(evt)=>{
            
            setState({...state, open:false})
        }}></div>
        </Renderif>

        <div className="nudropdow">
            <div className='nu-icon nu-icon--big cursor-pointer' onClick={()=>{
                setState({...state, open:!state.open})
            }}>
                <BsThreeDotsVertical size={25} color={darkBlue}></BsThreeDotsVertical>
            </div>
            <Renderif isTrue={state.open}>
                <div className='dropdow-items'>
                    <div className='dropdow-item' onClick={()=>{_onSelect(0)}}>
                        <div className='nu-icon nu-icon--mid'>
                            <IoIosSearch size={18}></IoIosSearch>
                        </div>

                        <div className='nu_btn-text '>Ver perfil</div>
                    </div>

                    <div className='dropdow-item' onClick={()=>{_onSelect(1)}}>
                        <div className='nu-icon nu-icon--mid'>
                            <IoIosSearch size={18}></IoIosSearch>
                        </div>

                        <div className='nu_btn-text '>Ver experiencias</div>
                    </div>
                    {/* <div className='dropdow-item' onClick={()=>{_onSelect(1)}}>
                        <div className='nu-icon nu-icon--mid'>
                            <IoIosSearch size={18}></IoIosSearch>
                        </div>

                        <div className='nu_btn-text '>Ver documentación</div>
                    </div> */}
                    <Renderif isTrue={item.blocked === false}>
                        <div className='dropdow-item' onClick={()=>{_onSelect(2)}}>
                         
                                <div className='nu-icon nu-icon--mid'>
                                    <MdOutlinePersonOff size={20}></MdOutlinePersonOff>
                                </div>

                                <div className='nu_btn-text'>Bloquear</div>
                           
                        </div>
                    </Renderif>

                    <Renderif isTrue={item.blocked === true}>
                        <div className='dropdow-item' onClick={()=>{_onSelect(3)}}>
                            
                                <div className='nu-icon nu-icon--mid'>
                                    <MdOutlinePersonOff size={20}></MdOutlinePersonOff>
                                </div>

                                <div className='nu_btn-text'>Desbloquear</div>
                       
                        </div>
                    </Renderif>
                    <div className='dropdow-item' onClick={()=>{_onSelect(4)}}>
                        <div className='nu-icon nu-icon--mid'>
                            <BiTrash size={16} color={'red'}></BiTrash>
                        </div>

                        <div className='nu_btn-text text-danger '>Eliminar</div>
                    </div>
                </div>
            </Renderif>
        </div>
        </>
    )
 
}


