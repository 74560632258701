import React, { useContext, useEffect, useState } from 'react'
import { IoMdClose, IoMdStar } from "react-icons/io";
import { MdCheck, MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from "react-icons/md";
import { SlArrowLeft, SlArrowRight } from "react-icons/sl";
import NuCalendar from '../../components/nu_calendar';
import { FaStar } from "react-icons/fa";
import { IoIosSearch } from "react-icons/io";
import { ProfesionalSideMenu } from '../../components/nu_sidemenu';
import { AuthContext } from '../../context/auth_context';
import { Service } from '../../services/api';
import { GlobalContext } from '../../context/global_context';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { set } from 'date-fns';
export default function ValoracionesProfesionalView() {
    const {user, user_data} = useContext(AuthContext);
    const {getCenter, getRating,getCenterType} = useContext(GlobalContext);
  
    const [state, setState] = useState({
        show_menu:false,
        valoraciones:[]
    })



    useEffect(()=>{
        const init = async() => {
            
            if(user !== null){
                let _valoraciones = await Service('GET', 'rating/getAll?idUser='+user?.id);
                setState({...state, valoraciones:_valoraciones.data.list})
            }
           
        };
        init();
    },[user])
  return (
    <div className='container-fluid overflow-hidden d-flex h-100'>


        <ProfesionalSideMenu onClose={()=>{setState({...state, show_menu:false})}} show={state.show_menu} page='valoraciones' />
   


        <div className='container-fluid overflow-auto hide-scroll col  mx-auto'>

            <div className='bg-white sticky-top pt-1 pt-md-3 px-1 px-md-3'>
     

                <div className='nu-snack'>
                <div>
                        <div className='f-22 text-white bold'>Hola, {user?.name}</div>
                        <div className='d-flex align-items-center'>
                            <div className='nu-icon nu-icon--mid'>
                                <IoMdStar color='#FFB054' size={24}></IoMdStar>
                            </div>

                            <div className='f-16 text-white'>{getRating().rate} ( {getRating().valoraciones} reseñas)</div>

                        </div>
                    
                </div>
                <div className='col'></div>
                    <div className='avatar avatar--mid' style={{backgroundImage: `url(${user?.picture})`}}></div>
                    <div className='nu-snack-overlay'></div>

                    <div className='ms-2 d-block d-sm-none'>
                        <div className='nu-icon nu-icon--mid cursor-pointer' onClick={()=>{
                            setState({...state, show_menu:true})
                        }}>
                            <BsThreeDotsVertical size={50} color='white'></BsThreeDotsVertical>
                        </div>
                    </div>
                </div>
                       
            </div>

            <div className='container-fluid container-xl p-1 p-md-3'>


                <div className='col bg-darkblue p-4 r-10'>
                    <div className='f-22 text-white  bold'>Tus valoraciones son privadas</div>
                    <div className='f-16 text-white'>El feedback es anónimo y puede ayudarte a mejorar en tu carrera.</div>
                </div>

                <div className='f-22  bold mt-4'>Mis valoraciones</div>
                {state.valoraciones.map((item, i)=>{

            
                
                  
                    return (
                        <div key={i} className='bg-gray r-10 p-4 mt-2'>

                            <div>
                                <div className='d-flex'>
                                  
                                    {/* <div className='f-16'><span className='bold'>Turno de:</span> {item.type === '0' ? 'Acogida':'Cobertura'}</div> */}
                                    
                                    <div className='col'></div>
                                    <div className='d-flex align-items-center'>
                                        <div className='nu-icon nu-icon--mid'>
                                            <FaStar size={16} color='#FFB054'></FaStar>
                                        </div>
                                        <div className='f-16 bolder'>{item.stars}</div>
                                    </div>
                                </div>
                                <div className='f-16'><span className='bold'>Centro:</span> {getCenter(item.idCenter)?.name}</div>
                                {/* <div className='f-16'><span className='bold'>Turno de:</span> {item.type === '0' ? 'Acogida':'Cobertura'}</div> */}
                              
                                <div className='f-16'>
                                {item.comment}
                                </div>
                            </div>
        
                        </div>
                    )
                })}

             

              
        
                

               

              
          
            </div>

       
      
        </div>

   
   
  
     
    </div>
  )
}
