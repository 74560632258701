import React, { useContext, useEffect, useState } from 'react'
import { IoMdClose, IoMdStar } from "react-icons/io";
import { MdCheck, MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from "react-icons/md";
import { SlArrowLeft, SlArrowRight } from "react-icons/sl";
import NuCalendar from '../../components/nu_calendar';
import { FaStar } from "react-icons/fa";
import { IoIosSearch } from "react-icons/io";
import { AdminSideMenu, ClinicSideMenu, ProfesionalSideMenu } from '../../components/nu_sidemenu';
import { MdOutlinePersonOff } from "react-icons/md";
import NuSelect from '../../components/nu_select';
import { IoClose } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import { AdminContext } from '../../context/admin_context';
import Renderif from '../../utils/renderif';
import NuLoading from '../../components/nu_loading';
import { BiSearch, BiTrash } from 'react-icons/bi';
import { NotificationContext } from '../../context/notification_context';
import { Service } from '../../services/api';
import { BsFilter } from 'react-icons/bs';
export default function ClinicsAdminView() {
    const navigate  = useNavigate();
    const {organizations, organizationsCount, loadOrganizations} = useContext(AdminContext);
    const {notify} = useContext(NotificationContext)

    const [state, setState] = useState({
        search:'',
        page:null,
        loading:false,
        filters:[],
        page:1,
        count:7,
        organizations:[],
        organizationsCount:0
    })

    useEffect(()=>{
        


        init();
        return () => {}

    },[])

    const init = async () => {


        setState({...state, loading:true})
      
  
        let _organizations = await loadOrganizations({page:state.page, count:state.count, search:state.search})

    
        state.organizations = _organizations.data.list
        state.organizationsCount = _organizations.data.total

        setState({...state, 
            loading:false, 
        })



        
    };

   
    // useEffect(()=>{
    //     const init = async () => {
    //         setState({...state, loading:true})
    //         let _organizations = await loadOrganizations({page:state.page})
    //         setState({...state, loading:false, organizations:_organizations.data.list, organizationsCount:_organizations.data.total})
    //     };
    //     init();
    //     return () => {}
    // },[state.page])

    const loadPage = async (page) => {
        let pages = Math.ceil(state.organizationsCount / state.count);
     

        if(state.page === 1 && page === -1){
            return
        }
        if(state.page === pages && page === 1){
            return
        }

        setState({...state, loading:true})
        let _profesionals = await loadOrganizations({page:state.page + page, count:state.count, search:state.search})

  
        
        setState({...state, 
            page:state.page + page,
            loading:false, 
            organizations:_profesionals.data.list, 
            organizationsCount:_profesionals.data.total,
    
        })
    }

    const renderPagination = () => {
     

        let pages = Math.ceil(state.organizationsCount / state.count);
        let items = []
        for (let i = 1; i <= pages; i++) {
            items.push(i);
        }

        return (
            <div>
               <span className='bold'>{state.page}</span> de <span className='bold'>{items.length}</span>
            </div>
        )

        // items.reverse()

        return items.map((item) => {
           
            return (
                <div style={{width:32}} className={`f-16 bold text-dark px-2 cursor-pointer mb-1 r-10 text-center ${state.page === item ? 'bg-light':'' }`} onClick={async ()=>{
                    
                    setState({...state, loading:true})
                    let _profesionals = await loadOrganizations({page:item, count:state.count})

              
                    
                    setState({...state, 
                        page:item,
                        loading:false, 
                        organizations:_profesionals.data.list, 
                        organizationsCount:_profesionals.data.total,
                
                    })
                }}>{item}</div>
            )
        })
        
        


     

    }
    const onDeleteUser = (user) => {


        notify({title:'Eliminar usuario', message:'¿Deseas eliminar este usuario?', type:'confirmation', onAccept: async (value) => {
            if(value){
                setState({...state, loading:true})
                let _delete = await Service('DELETE', `user/${user.id}`);
              
                init()
                // init();
                // state.turnos = state.turnos.filter((turno) => turno.id !== item.id);
                // setState({...state, loading:false})
            }
        }})
    }

  return (
    <>
    <Renderif isTrue={state.loading}>
        <NuLoading></NuLoading>
    </Renderif>
    <div className='container-fluid overflow-hidden d-flex h-100'>


        <AdminSideMenu page='organizaciones' />
   


        <div className='container-fluid overflow-auto hide-scroll col  mx-auto'>

            <div className='bg-white sticky-top pt-3 px-3'>
     

                <div className='nu-snack'>
                <div>
                        <div className='f-22 text-white bold'>Hola, Admin</div>
                       
                    
                </div>
                <div className='col'></div>
                    <div className='avatar avatar--mid' style={{backgroundImage: 'url(/assets/avatar.png)'}}></div>
                    <div className='nu-snack-overlay'></div>
                </div>
                       
            </div>

            <div className='container-fluid container-xl mx-auto p-3'>


          

                <div className='f-24  bold mt-2'>Organizaciones</div>

                  <div className='d-flex align-items-end mt-3'>

                    {/* <div className='f-16 bold'>Filtrar por: </div> */}

                 

                  
                    <div className='col-5'>
                        <div className='f-14'>Nombre</div>
                       <input className="nu-input--light p-2" value={state.search} placeholder='Busqueda por nombre.' onChange={(evt)=>{
                        setState({...state, search:evt.currentTarget.value})
                       }}></input>
                    </div>

                    

                    <div className='col d-flex'>
                       
                            <div className='nu_btn nu_btn--primary ms-2 py-2' onClick={()=>{
                                    // onDeleteUser(item)
                                    state.page = 1
                                    init()
                                    
                                }}>

                                <div className='nu-icon nu-icon--mid'>
                                    <BiSearch size={20}></BiSearch>
                                </div>

                                <div className='nu_btn-text '>Buscar</div>
                            </div>

                            <Renderif isTrue={state.search !== ''}>
                                <div className='nu_btn ms-2 py-2' onClick={()=>{
                                        state.search = ''
                                        state.page = 1
                                        init()
                                        
                                    }}>

                                    <div className='nu-icon nu-icon--mid'>
                                        <BsFilter size={20}></BsFilter >
                                    </div>

                                    <div className='nu_btn-text '>Limpiar Filtro</div>
                                </div>
                            </Renderif>
                            
                      
                    </div>
                


                    {/* <div className='col'></div> */}

                </div>
                <div className='d-flex flex-wrap mt-3'>

                    {state.filters.map((item,i)=>{
                        return(
                            <div key={i} className='nu-tag'>
                                <div className='f-12 bold'>{item}</div>
                                <div className='nu-icon nu-icon--mid cursor-pointer' onClick={()=>{
                                    if(state.filters.includes(item)){

                                        
                                        state.filters = state.filters.filter((filter)=>filter !== item)
                          
                                        setState({...state})
                                    }
                                }}>
                                    <IoClose size={14}></IoClose>
                                </div>
                            </div>
                        )
                    })}


                </div>

                <div>
                    {
                        state.organizations?.map((item, i)=>{
                           
                  
                            return(
                                <div key={i} className='d-flex align-items-center border-bottom py-3'>

                            
                                    <div className='col'>
                                        
                                        <div className='d-flex  align-items-start'>
                                            <div className='avatar me-2' style={{backgroundImage: 'url(/assets/avatar.png)'}}></div>
                                            <div className='f-14 bold'>{item?.name}</div>
                                        </div>

                        
                                    </div>

                                    <div className='col'>
                                        <div className='f-14 bold'>{item?.name === 'Usuario eliminado' ? '-' :item?.email}</div>
                                    </div>
                                  
                                 
                                    <div className='col'>

                                        <div className='d-flex align-items-center'>
                                            <div className='nu-icon nu-icon--mid text-decoration-underline cursor-pointer' onClick={()=>{
                                                navigate(`/admin/valoraciones/${item.id}/1`)
                                            }}>
                                                <IoMdStar color='#FFB054' size={24}></IoMdStar>
                                            </div>
                                            <div className='f-12 bolder text-decoration-underline cursor-pointer' onClick={()=>{
                                                navigate(`/admin/valoraciones/${item.id}/1`)
                                            }}>{item.stars === null ? 'Sin Valoraciones (0)' : `${parseFloat(item.stars).toFixed(1) } (${item.ratings})`} </div>
                                       
                                            
                                        </div>
                                        
                                    </div>
                                    <div className='col'> </div>
                                   
                                    <div className='pe-1'>
                                        <div className='nu_btn nu_btn--primary' onClick={()=>{
                                                navigate('/admin/clinica/'+item.id)
                                            }}>

                                            <div className='nu-icon nu-icon--mid'>
                                                <IoIosSearch size={20}></IoIosSearch>
                                            </div>

                                            <div className='nu_btn-text ps-2'>Ver perfil</div>
                                        </div>
                                    </div>
                                      <div className='px-1'>

                                        <div className='nu_btn nu_btn--danger' onClick={()=>{
                                                onDeleteUser(item)
                                            }}>

                                            <div className='nu-icon nu-icon--mid'>
                                                <BiTrash size={20}></BiTrash>
                                            </div>

                                            <div className='nu_btn-text '>Eliminar</div>
                                        </div>
                                    </div>
                                  


                                </div>
                            )})

                    }

                </div>

                <div className='d-flex justify-content-center my-5'>
                    <div className='nu-icon nu-icon--mid cursor-pointer' onClick={()=>{
                        loadPage(-1)
                    }}>
                        <SlArrowLeft></SlArrowLeft>
                    </div>
                    <div className='d-flex flex-wrap px-3'>
                        {renderPagination()}
                    </div>
                    <div className='nu-icon nu-icon--mid cursor-pointer' onClick={()=>{
                    
                        loadPage(1)
                    }}>
                        <SlArrowRight></SlArrowRight>
                    </div>
                </div>
             
                

               

              
          
            </div>

       
      
        </div>

   
   
  
     
    </div>
    </>
  )
}
