import React, {useContext, useEffect, useState} from 'react'
import CheckBox from '../../components/nu_checkbox'
import { useNavigate } from 'react-router-dom';
import { IoIosInformationCircleOutline } from "react-icons/io";
import NuComboBox from '../../components/nu_combobox';
import { IoIosArrowBack } from "react-icons/io";
import { darkBlue } from '../../styles/colors';
import { AuthContext } from '../../context/auth_context';
import { Service } from '../../services/api';
import { NotificationContext } from '../../context/notification_context';
import Renderif from '../../utils/renderif';
import { GlobalContext } from '../../context/global_context';
import { FaClinicMedical, FaHospital } from 'react-icons/fa';

export default function RegisterAddCenterView() {

    const {user, user_data} = useContext(AuthContext);
    const {notify} = useContext(NotificationContext);
    const {assistanceUnit, professionalField} = useContext(GlobalContext);

    const navigate = useNavigate();
    const [state, setState] = useState({
        // name:'Nombre',
        // address:'Direccion',
        // postalCode:'112133',
        // province:'provincia',
        // description:'asd',
        // parking:'',
        // dietas:'',
        // uniforme:'',
        // location:'',
        // notes:'Test notes',


        name:'',
        address:'',
        postalCode:'',
        province:'',
        description:'',
        parking:'',
        dietas:'',
        uniforme:'',
        location:'',
        notes:'',

        add:false,
        requests:false,
        centers:[],
        center_selected:'',
        preferences:[],

        assistanceUnit:[],
        professionalField:[],
        show_preferences:false,
        center_preferences:[],
        organization_preferences:[],
        loading_preferences:false,
        editing_center:null,
        centers:[]
        
        

        
    })

    useEffect(()=>{
        const init = async () => {
            let _centerType = await Service('GET', 'centerType/getAll');
            let _assistanceUnit = await Service('GET', `assistanceUnit/getAll`);
            console.log(_assistanceUnit)
            let _professionalField = await Service('GET', `professionalField/getAll`);

            state.assistanceUnit = _assistanceUnit.data.list.filter((item) => item.id !== 16 && item.id !== 14 && item.id !== 1)
            state.professionalField = _professionalField.data.list

            if(_centerType.status === 200){
                state.centers = _centerType.data.list
            }

            setState({...state})
        }
        init()
        return () => {}
    },[])
    const provincias = [
        {
            provincia: 'Andalucía',
            colegios: ['Almería', 'Cádiz', 'Córdoba', 'Granada', 'Huelva', 'Jaén', 'Málaga', 'Sevilla']
        },
        {
            provincia: 'Aragón',
            colegios: ['Zaragoza', 'Teruel', 'Huesca']
        },
        {
            provincia: 'Principado de Asturias',
            colegios: ['Asturias']
        },
        {
            provincia: 'Illes Balears',
            colegios: ['Illes Balears']
        },
        {
            provincia: 'Canarias',
            colegios: ['Las Palmas', 'Santa Cruz de Tenerife']
        },
        {
            provincia: 'Cantabria',
            colegios: ['Cantabria']
        },
        {
            provincia: 'Castilla y León',
            colegios: ['Ávila', 'Burgos', 'León', 'Palencia', 'Salamanca', 'Segovia', 'Soria', 'Valladolid', 'Zamora']
        },
        {
            provincia: 'Castilla y la Mancha',
            colegios: ['Albacete', 'Ciudad Real', 'Cuenca', 'Guadalajara y Toledo']
        },
        {
            provincia: 'Cataluña',
            colegios: ['Barcelona', 'Tarragona', 'Girona', 'Lleida']
        },
        {
            provincia: 'Comunidad Valenciana',
            colegios: ['Alicante', 'Valencia', 'Castellón']
        },
        {
            provincia: 'Extremadura',
            colegios: ['Cáceres','Badajoz']
        },
        {
            provincia: 'Galicia',
            colegios: ['A Coruña','Lugo', 'Ourense', 'Pontevedra']
        },
        {
            provincia: 'Murcia',
            colegios: ['Murcia']
        },
        {
            provincia: 'Madrid',
            colegios: ['Madrid']
        },
        {
            provincia: 'Navarra',
            colegios: ['Navarra']
        },
        {
            provincia: 'País Vasco',
            colegios: ['Bizcaia','Gipuzkoa','Araba']
        },
        {
            provincia: 'La Rioja',
            colegios: ['La Rioja']
        },
        {
            provincia: 'Ceuta',
            colegios: ['Ceuta']
        }
        ,
        {
            provincia: 'Melilla',
            colegios: ['Melilla']
        }
        
    ];



    const validator = () => {
        state.errors = {}
        
        if(state.name === ''){
            state.errors.name = 'Nombre completo es obligatorio'
        }
       
        if(state.postalCode === ''){
            state.errors.postalCode = 'Código postal es obligatorio'
        }
        if(state.address === ''){
            state.errors.address = 'Dirección es obligatorio'
        }
        if(state.province === ''){
            state.errors.province = 'Provincia es obligatorio'
        }
        if(state.parking === ''){
            state.errors.parking = 'Parking es obligatorio'
        }
        if(state.dietas === ''){
            state.errors.dietas = 'Dietas es obligatorio'
        }
        if(state.uniforme === ''){
            state.errors.uniforme = 'Uniforme es obligatorio'
        }
        if(state.location === ''){
            state.errors.location = 'Punto de encuentro es obligatorio'
        }
      
    
        setState({...state})
        if(Object.keys(state.errors).length > 0){
            
            return false;
        }
       return true;
        

    }
    const onCreateCenter =  async () => {



    

        if(!validator()){
            return;
        }

        let _description = `<div><span>Parking</span>: ${state.parking}</div><div><span>Dietas</span>: ${state.dietas}</div><div><span>Uniforme</span>: ${state.uniforme}</div><div><span>Punto de encuentro</span>: ${state.location}</div><div><span>Notas</span>: ${state.notes}</div>`;

        let body = {
            idOrganization:user_data.id,
            name:state.name,
            address:state.address,
            postalCode:state.postalCode,
            province: state.province,
            description:null,
            info: JSON.stringify({
                Parking:state.parking,
                Dietas:state.dietas,
                Uniforme:state.uniforme,
                Location:state.location,
                Notas:state.notes,
                Requests:state.preferences
            }),
            idCenterType: parseInt(state.center_selected)
        }

        let _addCenter = await Service('POST', 'center/create', JSON.stringify(body) )
        console.log(_addCenter)
        if(_addCenter.status === 201){

            notify({title:'¡Listo!', message:'Haz añadido un nuevo centro.', type:'success'})

            if(state.requests){
                state.show_preferences = true;
                state.editing_center = _addCenter.data.result.id
                loadPreferences()
                return;
            }else{
                if(state.add){
                    setState({
                        ...state,
                        name:'',
                        address:'',
                        postalCode:'',
                        province:'',
                        description:'',
                        parking:'',
                        dietas:'',
                        uniforme:'',
                        location:'',
                        notes:'',
                        add:false,
                        requests:false,
                        center_selected:'',
                        preferences:[],
                        show_preferences:false,
                        editing_center:null,
                        center_preferences:[],
                        organization_preferences:[]
        
                    })
                    return;
                }

            }
           

            
            notify({title:'¡Bienvenido!', message:user.name, type:'success'})
            navigate('/clinic')
          

            
        }else{
            notify({title:'Error', message:_addCenter?.response?.data?.error, type:'error'})
            setState({...state, loading:false})
        }
    }
    const _centerType = (idCenter) => {
        let _center = state.centers?.find((center)=>center.id === idCenter)
        return _center?.idCenterType
    } 
    const _center = (idCenter) => {
        let _center = state.centers?.find((center)=>center.id === idCenter)
        return _center
    } 

    const checkAssistanceUnit = (idAssistanceUnit) => {
       
        return state.center_preferences.some((pref) => pref.idAssistanceUnit === idAssistanceUnit && pref.idProfessionalField === null)
    }
    const checkProfessionalFieldOther = (idProfessionalField) => {
        return state.center_preferences.some((pref) => pref.idProfessionalField === idProfessionalField)
    }
    const checkProfessionalField = (idAssistanceUnit, idProfessionalField) => {
        return state.center_preferences.some((pref) => pref.idAssistanceUnit === idAssistanceUnit && pref.idProfessionalField === idProfessionalField)
    }
    const onAddPreference = async (idAssistanceUnit, idProfessionalField) => {
        let body = {
            idOrganization:user_data.id,
            idAssistanceUnit:idAssistanceUnit,
            idProfessionalField:idProfessionalField,
            idCenter:parseInt(state.editing_center)
        }
        let _addPreference = await Service('POST', 'organization_preferences/create', body);
        loadPreferences()
        
    }
    const onAddAllProfessionalField = async (idAssistanceUnit) => {
        setState({...state, loading_preferences:idAssistanceUnit})

        for (let i = 0; i < professionalField.length; i++) {
            if(professionalField[i].id !== 1){
                let _pref = state.center_preferences.some((pref) => pref.idAssistanceUnit === idAssistanceUnit && pref.idProfessionalField === professionalField[i].id);
                console.log(_pref)
                if(!_pref){
                    let body = {
                        idOrganization:user_data.id,
                        idAssistanceUnit:idAssistanceUnit,
                        idProfessionalField:professionalField[i].id,
                        idCenter:parseInt(state.editing_center)
                    }
                    let _addPreference = await Service('POST', 'organization_preferences/create', body);
                }
            }
            
            
        }
        loadPreferences()


    }
    const onDeleteAllProfessionalField = async (idAssistanceUnit) => {
        setState({...state, loading_preferences:idAssistanceUnit})

        for (let i = 0; i < professionalField.length; i++) {
            if(professionalField[i].id !== 1){
                let _pref = state.center_preferences.some((pref) => pref.idAssistanceUnit === idAssistanceUnit && pref.idProfessionalField === professionalField[i].id);
                console.log(_pref)
                if(_pref){


                    let __pref =  state.center_preferences.find((pref)=> pref.idAssistanceUnit === idAssistanceUnit && pref.idProfessionalField === professionalField[i].id)
                    let _deletePreference = await Service('DELETE', `organization_preferences/delete/${__pref.id}`);

                
                }
            }
            
            
        }
        loadPreferences()


    }

    const onDeletePreference = async (idAssistanceUnit, idProfessionalField) => {

        let _pref =  state.center_preferences.find((pref)=> pref.idAssistanceUnit === idAssistanceUnit && pref.idProfessionalField === idProfessionalField)
        let _deletePreference = await Service('DELETE', `organization_preferences/delete/${_pref.id}`);
        loadPreferences()
        
    }

    const loadPreferences = async () => {
        let _organization_preferences = await Service('GET', `organization_preferences/${user_data.id}`)
        let _center = await Service('GET', `center/${user_data.id}`)
        console.log(_center)
        state.centers = _center.data.list
        console.log(_organization_preferences)
        state.organization_preferences = _organization_preferences.data.list;
        state.center_preferences = _organization_preferences.data.list.filter((pref) => pref.idCenter === parseInt(state.editing_center))
        state.loading_preferences = false;
        setState({...state})
    }


  return (
    <div className='container-fluid h-100'>

        <div className='d-flex h-100'>

            <div className='col-12 col-md-3 d-none d-md-block'>
                <div className='register_bg bg-cover' style={{backgroundImage:'url(/assets/nursea5.png)'}}></div>
            </div>

            <Renderif isTrue={!state.show_preferences}>
            
            <div className='col-12 col-md-9 d-flex flex-column p-3 p-md-5  overflow-auto hide-scroll'>
                <div className='container d-flex flex-column px-md-5'>


                    <div className='d-flex fit-content align-items-center cursor-pointer mb-2'>
                        {/* <div className='nu-icon nu-icon--mid'>
                            <IoIosArrowBack size={20} color={darkBlue}></IoIosArrowBack>
                        </div> */}
                        {/* <div className='f-16 bold text-darkblue'>Atras</div> */}
                    </div>



                    <div className='f-28 bold'>Añadir Centro Sanitario</div>

                    <div className='d-flex flex-wrap'>
                        <div className='col-12 col-md pe-0 pe-md-2'>


                            <div className='f-16 bold mt-3 mb-1'>Nombre de centro</div>
                            <input type='text' value={state.name} onChange={(evt)=>{
                                setState({...state, name:evt.currentTarget.value })
                            }} className='nu_input' placeholder='Nombre de centro *'></input>
                               <div className='text-danger f-12'>{state.errors?.name}</div>

                        </div>

                        <div className='col-12 col-md ps-0 ps-md-2'>

                                <div className='f-16 bold mt-3 mb-1'>Tipo de Centro</div>
                    

                                <select 
                                
                                    
                                    value={state.center_selected}
                                    className={`nu-select nu-input--light`} 
                                    onChange={(evt)=>{
                                        state.center_selected = parseInt(evt.currentTarget.value)
                                        setState({...state})
                                    }}>

                                        <option value=''>Seleccionar</option> 
                                            {state.centers.map((item,i)=>{
                                            if(item.id==1) return;
                                        
                                            
                                            return (
                                                <option key={i} value={item.id}>{item.description}</option> 
                                            )
                                        
                                    })}
                                </select>
                        </div>

                      
                    </div>

                    <div className='d-flex'>
                        <div className='col'>
                            <div className='f-16 bold mt-3 mb-1'>Dirección completa</div>
                            <input type='text' value={state.address} onChange={(evt)=>{
                                setState({...state, address:evt.currentTarget.value })
                            }} className='nu_input' placeholder='Dirección completa *'></input>
                               <div className='text-danger f-12'>{state.errors?.address}</div>
                        </div>

                    </div>


                    <div className='d-flex flex-wrap'>

                        <div className='col-12 col-md pe-0 pe-md-2'>
                            <div className='f-16 bold mt-3 mb-1'>Código postal</div>
                            <input type='text' value={state.postalCode} onChange={(evt)=>{
                                setState({...state, postalCode:evt.currentTarget.value })
                            }} className='nu_input' placeholder='Código postal *'></input>
                               <div className='text-danger f-12'>{state.errors?.postalCode}</div>
                          
                        </div>

                        <div className='col-12 col-md ps-0 ps-md-2'>
                            <div className='f-16 bold mt-3 mb-1'>Comunidad autónoma</div>

                            <select value={state.province} onChange={(evt)=>{
                                     
                                     setState({...state, province:evt.currentTarget.value})

                                     

                                 }}  className='nu-select nu-input--light'>
                                 <option value="" disabled>Seleccionar</option>
                                 {
                                     provincias.map((item, i)=>{
                                         return(
                                             <option key={i} value={item.provincia}>{item.provincia}</option>
                                         )
                                     })
                                 }
                          
                                </select>
                                <div className='text-danger f-12'>{state.errors?.province}</div>

                     

                        </div>
                        
                    </div>

                    <div className='d-flex mt-5'>
                        <div className='f-28 bold'>Información del Centro Sanitario</div>
                    </div>

                    <div className='d-flex'>
                        <div className='col'>
                            <div className='f-16 bold mt-3 mb-1'>Parking</div>
                            <input type='text' value={state.parking} onChange={(evt)=>{
                                setState({...state, parking:evt.currentTarget.value })
                            }}  className='nu_input' placeholder='Ej. No hay parking disponible pero es fácil aparcar *'></input>
                               <div className='text-danger f-12'>{state.errors?.parking}</div>

                            <div className='f-16 bold mt-3 mb-1'>Dietas</div>
                            <input type='text' value={state.dietas} onChange={(evt)=>{
                                setState({...state, dietas:evt.currentTarget.value })
                            }} className='nu_input' placeholder='Ej. Se incluye comida o cena en turnos superiores a 8 horas *'></input>
                               <div className='text-danger f-12'>{state.errors?.dietas}</div>

                            <div className='f-16 bold mt-3 mb-1'>Uniforme</div>
                            <input type='text' value={state.uniforme} onChange={(evt)=>{
                                setState({...state, uniforme:evt.currentTarget.value })
                            }} className='nu_input' placeholder='Ej. Traer zapato blanco, se entrega uniforme en recepción *'></input>
                               <div className='text-danger f-12'>{state.errors?.uniforme}</div>
                            
                            <div className='f-16 bold mt-3 mb-1'>Punto de encuentro</div>
                            <input type='text' value={state.location} onChange={(evt)=>{
                                setState({...state, location:evt.currentTarget.value })
                            }} className='nu_input' placeholder='Presentarse 20’ antes del turno en el hall central. Planta 0. Preguntar por... *'></input>
                               <div className='text-danger f-12'>{state.errors?.location}</div>

                            <div className='f-16 bold mt-3 mb-1'>Notas</div>
                            <input type='text' value={state.notes} onChange={(evt)=>{
                                setState({...state, notes:evt.currentTarget.value })
                            }} className='nu_input' placeholder='Ej. Particularidades no contempladas *'></input>
                               <div className='text-danger f-12'>{state.errors?.notes}</div>

                     
                            <div className='f-16 bold mt-3 mb-1'>Quiero recibir solicitudes de acogida</div>


                            <CheckBox checked={state.requests} onChange={(evt)=>{
                                    console.log(evt)
                                    if(evt.value === false){
                                        state.preferences = []
                                    }
                                    state.requests = evt.value
                                    setState({...state})
                               
                            }}   text="Si, quiero recibir solicitudes de acogida"></CheckBox>


                        
                            {/* <Renderif isTrue={!state.requests}> */}
                                <div className='f-16 bold mt-3 mb-1'>¿Quieres añadir un nuevo Centro Sanitario dentro de la misma organización?</div>

                                <CheckBox checked={state.add} onChange={(evt)=>{
                                        
                                        setState({...state, add:evt.value})
                                
                                }}   text="Si, quiero añadir un nuevo Centro Sanitario"></CheckBox>
                            {/* </Renderif> */}

                   

                        </div>
                    </div>
                
                    <div className='mt-5'>
                        <div className='nu_btn nu_btn--overlay' onClick={()=>{
                           onCreateCenter()
                        }}>
                            <div className='nu_btn-text f-18'>Siguiente</div>
                            <div className='nu_btn-overlay'></div>
                        </div>
                    </div>
                    <div className='d-block d-md-none my-5'></div>

                    
                </div>                
            </div>

            </Renderif>

            <Renderif isTrue={state.show_preferences}>
                <div className='col-12 col-md-9 d-flex flex-column p-3 p-md-5  overflow-auto hide-scroll'>

                    <div className='d-flex align-items-center'>

                   

                    

                    <div className='f-14 bold'>
                    Marca las Unidades asistenciales y/o Ámbitos profesionales donde los<br></br> profesionales puedan solicitarte turnos de acogida
                    </div>

                    <div className='col'></div>

                        <div className='nu-icon nu-icon--mid'>
                            <FaClinicMedical size={16} color={darkBlue}></FaClinicMedical>
                        </div>
                        <div className='f-16 bold text-darkblue me-2'>{_center(state.editing_center)?.name}</div>

                        <div className='nu_btn nu_btn--overlay' onClick={()=>{

                            if(state.add){
                                state.show_preferences = false
                                state.editing_center = null
                                state.center_preferences  = []
                                state.organization_preferences = []
                                state.name = ''
                                state.address = ''
                                state.postalCode = ''
                                state.province =''
                                state.description=''
                                state.parking=''
                                state.dietas=''
                                state.uniforme=''
                                state.location=''
                                state.notes=''
                                state.add=false
                                state.requests=false
                                setState({...state})
                            }else{
                                notify({title:'¡Bienvenido!', message:user.name, type:'success'})
                                navigate('/clinic')
                            }
                          
                        }}>
                            <div className='nu_btn-text f-18'>Siguiente</div>
                            <div className='nu_btn-overlay'></div>
                        </div>
                        
                        

                    </div>
                    <Renderif isTrue={_center(state.editing_center)?.idCenterType !== 2}>
                        <div className='container-fluid d-flex flex-wrap mt-4'>
                                
                            {professionalField.map((item, i)=>{
                                if(item.id === 1) return;

                                return (
                                    <div className=' col-4 mt-1'>
                                        <CheckBox key={i} checked={checkProfessionalFieldOther(item.id)} text={item.description} value={item.id} onChange={(evt)=> {

                                            
                                            if(evt.value === true){
                                                onAddPreference(null, item.id)
                                                
                                            }else{
                                                onDeletePreference(null, item.id)
                                                // state.description.Requests = state.description?.Requests?.filter((pref) => pref.idAssistanceUnit !== evt.key)
                                            }

                                        


                                        }} />
                                        
                                        <Renderif isTrue={_centerType(state.editing_center) === 2 && checkAssistanceUnit(item.id)}>
                                            
                                            <div className=' bg-gray-00 p-3 d-flex flex-wrap r-10 mt-2 position-relative overflow-hidden'>

                                                <Renderif isTrue={state.loading_preferences === item.id}>
                                                    <div className='nu-loader'>
                                                        <div className="spinner-border text-light"></div>
                                                    </div>
                                                </Renderif>

                                                <div className='col-4'>
                                                    
                                                    <CheckBox  checked={state.center_preferences.filter((pr) => pr.idAssistanceUnit === item.id).length >= professionalField.length} text={'Recibir solicitudes en todos los ámbitos'} value={-1} onChange={(evt)=> {

                                                        
                                                        
                                                        if(evt.value){
                                                            
                                                            onAddAllProfessionalField(item.id)

                                                        }else{
                                                            
                                                            onDeleteAllProfessionalField(item.id)
                                                        }
                                            

                                                    }} />
                                                </div>
                                                {professionalField.map((_item, j)=>{
                                                    if(_item.id === 1) return;
                                                    return (
                                                        <div className='col-4'>
                                                            <CheckBox key={j} checked={checkProfessionalField(item.id, _item.id)} text={_item.description} value={_item.id} onChange={(evt)=> {
                                                                

                                                                if(evt.value === true){
                                                                    onAddPreference(item.id, _item.id)
                                                                    
                                                                }else{
                                                                    onDeletePreference(item.id, _item.id)
                                                                    // state.description.Requests = state.description?.Requests?.filter((pref) => pref.idAssistanceUnit !== evt.key)
                                                                }
                                                                

        
                                                            }} />
                                                        </div>
                                                    )
                                                })}
                                            </div>


                                        </Renderif>
                                    </div>
                                )

                            })}
                            
                        </div>
                    </Renderif>
                    <Renderif isTrue={_center(state.editing_center)?.idCenterType === 2}>
                        <div className='container-fluid mt-4'>
                                
                            {assistanceUnit.map((item, i)=>{
                                 if(item.id === 1) {return}
                                 if(item.id === 14) {return}
                                 if(item.id === 21) {return}

                                return (
                                    <div className='mt-1'>
                                        <CheckBox key={i} checked={checkAssistanceUnit(item.id)} text={item.description} value={item.id} onChange={(evt)=> {

                                            
                                            if(evt.value === true){
                                                onAddPreference(item.id, null)
                                                
                                            }else{
                                                onDeletePreference(item.id, null)
                                                // state.description.Requests = state.description?.Requests?.filter((pref) => pref.idAssistanceUnit !== evt.key)
                                            }

                                        


                                        }} />
                                        
                                        <Renderif isTrue={_centerType(state.editing_center) === 2 && checkAssistanceUnit(item.id)}>
                                            
                                            <div className=' bg-gray-00 p-3 d-flex flex-wrap r-10 mt-2 position-relative overflow-hidden'>

                                                <Renderif isTrue={state.loading_preferences === item.id}>
                                                    <div className='nu-loader'>
                                                        <div className="spinner-border text-light"></div>
                                                    </div>
                                                </Renderif>

                                                <div className='col-4'>
                                                    
                                                    <CheckBox  checked={state.center_preferences.filter((pr) => pr.idAssistanceUnit === item.id).length >= professionalField.length} text={'Recibir solicitudes en todos los ámbitos'} value={-1} onChange={(evt)=> {

                                                        
                                                        
                                                        if(evt.value){
                                                            
                                                            onAddAllProfessionalField(item.id)

                                                        }else{
                                                            
                                                            onDeleteAllProfessionalField(item.id)
                                                        }
                                            

                                                    }} />
                                                </div>
                                                {professionalField.map((_item, j)=>{
                                                    if(_item.id === 1) return;
                                                    return (
                                                        <div className='col-4'>
                                                            <CheckBox key={j} checked={checkProfessionalField(item.id, _item.id)} text={_item.description} value={_item.id} onChange={(evt)=> {
                                                                

                                                                if(evt.value === true){
                                                                    onAddPreference(item.id, _item.id)
                                                                    
                                                                }else{
                                                                    onDeletePreference(item.id, _item.id)
                                                                    // state.description.Requests = state.description?.Requests?.filter((pref) => pref.idAssistanceUnit !== evt.key)
                                                                }
                                                                

        
                                                            }} />
                                                        </div>
                                                    )
                                                })}
                                            </div>


                                        </Renderif>
                                    </div>
                                )

                            })}
                            
                        </div>
                    </Renderif>
                    <div className='my-5 py-5'></div>
                    </div>
            </Renderif>

        </div>

    </div>
  )
}
